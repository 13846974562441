@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

* {
	outline: none !important;
	outline-width: 0px !important;
	outline-color: transparent !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	font-family:   var(--sk-font-urbanist-family) !important;
}

.editTitle{
	padding: 5px;
	width: 100%;
	font-size: 14px;
}
.editTitleBorder{
	border: 1px solid var(--sk-border-color);
	padding-left: 4px;
}
.openTitle{
	color:var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 400;
}
.datagrid__table__container .name{
	color: var(--smart-konversations-label-color) !important;
	font-family: var(--sk-font-urbanist-family), Bold;
	font-weight: 600;
}

.due__in__days__template__pop__up__input .smart__input__btn-text{
	white-space: nowrap !important;
}

.due__in__days__template__pop__up__input .w-100{
	display: flex;
}

.due__in__days__template__pop__up__input .smart__input__btn{
	flex-grow: 1;
}


/* .main_header {
	z-index: 999 !important;
} */

.pro__icon__wrap {
	display: flex;
	padding: 4px 7px;
	justify-content: center;
	align-items: center;
	gap: 4px;
	border-radius: 200px;
	background: var(--smart-pro-icon-color);
	margin-top: 2px;
	margin-left: 4px;
}

.pro__feature__text {
	color: #FFF;
	font-family: "DM Sans";
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 10px;
	/* 100% */
}

.pro__icon {
	background: var(--iconUrl) no-repeat -686px -396px;
	width: 10px;
	height: 10px;
}



.collapsed__pro__icon {
	background: var(--iconUrl) no-repeat -672px -396px;
	width: 10px;
	height: 10px;
}

.highcharts-tooltip-container {
	z-index: 999999999 !important;
}

.playbook__getting__started__tooltip {
	margin: 0;
	/* background-color: var(--sk-heading-color); */
	font-weight: 400;
	text-align: center;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;

	display: flex;
	flex-direction: column;
	align-items: flex-start;


}

.sidebar__notification .highcharts-tooltip-box:last-child {
	stroke: none !important;
	stroke-width: 0 !important;
}

.sidebar__notification-header__wrap {
	display: flex;
	align-items: center;
}


.getting__started__progress__indicator {
	margin-left: 8px;
	background-color: var(--template-toggle-bg-color);
	color: #fff;
	border-radius: 4px;
	padding: 2px 4px;
	font-size: 12px;
}


.playbook__getting__started__tooltip .text {
	font-family: var(--sk-font-urbanist-family);
	font-style: normal;
	font-size: 12px;
	color: #fff !important;
	display: flex;
	align-items: flex-start;
}


.sidebar__notification .highcharts-tooltip-container .highcharts-tooltip>span {
	min-width: 200px;
	max-width: 350px;
	white-space: normal;
}

.dropdown__selected__text__container {
	display: flex;
	flex-wrap: wrap;
	/* padding-top: 10px; */
	max-height: 120px;
	overflow-y: auto;
	padding: 10px;
}

.radio__button__text-span {
	width: 84% !important;
}

.sidenav__smart__playbooks__welcome__icon-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sidenav__smart__playbooks__welcome__icon {
	background: var(--iconUrl) no-repeat -681px -440px !important;
	width: 24px !important;
	height: 22px !important;
	margin-top: 0px !important;
	min-height: auto !important;
}

.dropdown__selected__text__container::-webkit-scrollbar {
	width: 0;
	/* Remove scrollbar space */
	background: transparent;
	/* Optional: just make scrollbar invisible */
}

.dropdown__selected__text__wrap {
	padding: 5px;
	border-radius: 30px;
	background-color: var(--sk-loyalty-user-churn-color-two);
	margin: 2px 5px;
	display: flex;
}

.dropdown__selected__text {
	font-size: 13px;
	font-weight: 500;
	color: #000;

}

.dropdown__selected__text-cross-symbol {
	font-size: 10px;
	font-weight: 600;
	color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px 2px 0px 5px;
	cursor: pointer;
}

.multi__select__dropdown__scroll__container .skPrimaryBtn {
	height: 2.25rem !important;
}

.custom__column__cell__collaborators-container.pointer-events-none .smart__konversations__call__label {
	pointer-events: all !important;
}

.access_tabel_content.pointer-events-none .label_head {
	pointer-events: all !important;
}

.custom__column__cell__collaborators-container.pointer-events-none {
	opacity: 1 !important;
}

.custom__column__cell__collaborators-container.pointer-events-none .smart__konversations__collaborator__name__wrap {
	pointer-events: 0.5 !important;
}

.welcome__playbooks__modal .smart-modal-header-lable .modal-title {
	text-align: center;
	font-weight: 600;
}

.welcome__playbooks__modal .smart-modal-header-lable {
	width: 100%;
	align-items: center;
	justify-content: center;
}


.welcome__playbooks__modal .modal-content {
	min-width: 607px;
	min-height: 500px;
}

.welcome__smart__playbooks__banner-loader {
	min-width: 607px;
	min-height: 500px;
}

.welcome__smart__playbooks__banner__video {

	display: flex;
	width: 100%;
	margin-top: 10px;
}

.welcome__smart__playbooks__banner__content {
	margin-top: 10px;
}

.welcome__smart__playbooks__banner__text {
	font-size: 14px;
	text-align: center;
	color: #000;
}

.welcome__smart__playbooks__banner__text.bold {
	margin-top: 10px;
	font-weight: 600;
}

.welcome__smart__playbooks__banner__footer .welcome__smart__playbooks__banner__text {
	margin-top: 10px;
}

.welcome__smart__playbooks__banner__video-iframe {
	width: 100%;
}

.welcome__smart__playbooks__banner__footer .smart__ui__btns .justify-content-end {
	justify-content: center !important;
}

.welcome__smart__playbooks__banner__footer .smart__ui__btns {
	margin-top: 10px;
	margin-bottom: 10px;
}

.welcome__smart__playbooks__banner__footer .smart__ui__btns .skPrimaryBtn {
	background-color: var(--template-toggle-bg-color);
	min-width: 4rem !important;
	border: 1px solid var(--template-toggle-bg-color) !important;
}


.outcome_name--container span {
	text-align: center !important;
}


.cursor-pointer {
	cursor: pointer !important;
}


.sk__powered__by__wrap {
	/* display: flex;
	align-items: center;
	justify-content: flex-end; */
	position: absolute;
	right: 40px;
	font-size: 13px;
	letter-spacing: 0.5px;
	color: var(--sk-sub-tab-text-color);
	margin-top: 5px;
	bottom: 15px;
}



.share_to_trofy_v2 {
	background: url('../assets/phoenix/contact_trophy.svg') no-repeat center center;
	width: 18px !important;
	height: 18px !important;
	display: block;
	cursor: pointer;
	background-size: 18px 18px;
	padding: 16px 16px !important;
	margin-top: 10px;
	margin-right: -1rem;
}

.share_to_trofy {
	background: url('../assets/phoenix/contact_filled_trophy.svg') no-repeat center center;
	width: 18px !important;
	height: 18px !important;
	display: block;
	cursor: pointer;
	background-size: 18px 18px;
	padding: 16px 16px !important;
	margin-top: 10px;
	margin-right: -1rem;
}

.show_sk_popup_alertfeed-container-popover-window {
	z-index: 99999999;
}

.hierarchy .tooltip232 {
	position: relative;
}


.app_integration_settings .p_settings_left_p {
	padding: 20px;
}

.hierarchy .tooltip232 .tooltiptext232.tree {
	visibility: visible;
	top: -6px;
	left: -259px !important;
	width: 330px;
	text-align: left;
	padding-bottom: 0;
	padding-left: 12px;
	padding-top: 5px;
	border: none;
	box-shadow: 0px 3px 19px -4px #0000002c !important;
	padding-right: 0px !important;
	background-color: white;
	font-size: 12px !important;
	color: #000000;
	font-weight: 100;
	position: absolute;
	/* position: relative; */
	z-index: 3;
	margin-top: 39px;
	opacity: 1;
	font-family: "Urbanist", sans-serif !important;
	margin-left: 6px;
	border-radius: 0px;
	/* max-height: 75vh; */
	max-height: 350px;
	/* min-height: 20vh; */
	overflow-y: scroll;
}

.hierarchy .tooltip232 .tooltiptext232 {
	visibility: visible;
	/* width: 265px; */
	background-color: white;
	font-size: 12px !important;
	color: #000000;
	text-align: left;
	font-weight: 100;
	text-align: center;
	border-radius: 6px;
	top: -36px;
	left: 30px !important;
	padding: 12px;
	position: absolute;
	z-index: 3;
	margin-top: 39px;
	box-shadow: 0px 3px 4px #0000002c;
	border: 0.5px solid #d0d0d0;
	opacity: 1;
	font-family: "Urbanist", sans-serif !important;
	margin-left: 6px;
	border-radius: 0px;
}

.clinet_logo {
	display: flex;
	align-items: center;
	font-size: 15px;
	color: black;
	white-space: nowrap;
	letter-spacing: 0.01px;
	margin-left: 8px;
}

.clinet_logo-1 {
	display: flex;
	align-items: center;
	/* padding-left: 10px; */
	font-size: 18px;
	color: black;
}


.dropdown-toggle::after {
	display: contents !important;
	width: 0;
	height: 0;
	margin-left: 0.255em;
	vertical-align: 0.255em;
	content: "";
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.left__profile__border {
	top: 11px;
	width: 0px;
	height: 35px;
	border: 0.5px solid #5B5266;
	opacity: 1;
	margin-left: -24px;
	position: absolute;
	display: flex;
	align-items: center;
}


a {
	text-decoration: none !important;
}

a:link {
	text-decoration: none;
}

/* visited link */
a:visited {
	text-decoration: none;
}

/* mouse over link */
a:hover {
	text-decoration: none;
}

/* selected link */
a:active {
	text-decoration: none;
}

.touchpointEditor {
	display: block;
	border: 1px solid #e8e8eb;
	border-radius: 2px;
}

.modal-content .modal-title {
	font-size: 15px !important;
	line-height: 15px !important;
	font-family: Urbanist;
	color: #000000;
}


/* CSS for No Event Mapping No Data Screen */
.noDataForEvent .tab-content .tab-pane {
	height: 100%;
	position: relative;
}

.noDataForEvent .tab-content>.active {
	display: block;
}

.noDataForEvent .filter-section {
	padding: 15px;
	/* border-bottom: 1px solid #efefef; */
	width: 103%;
	position: relative;
	/* left: -16px; */
}

.noDataForEvent .no-data-image {
	border-bottom: none !important;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 27px 0 15px;
	text-align: center;
}

.noDataForEvent .no-data-image img {
	max-width: 41px !important;
	margin: 0 auto !important;
}

.noDataForEvent .no-data-image .no-data-text {
	margin-top: 18px;
	font-size: 14px;
	font-style: italic;
	color: var(--sk-no-data-text-color);
	font-family: "Urbanist", sans-serif;
}

.noDataForEvent h3 {
	line-height: 2.25rem;
}

.noDataForEvent .pt-5,
.py-5 {
	padding-top: 3rem !important;
}

.noDataForEvent .pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}

.noDataForEvent .pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}

.noDataForEvent img {
	vertical-align: middle;
	border-style: none;
}

.noDataForEvent .event__text {
	font-size: 14px;
	font-weight: 500;
	color: #000;
	line-height: 1.2rem;
}

.noDataForEvent .event__SubText {
	font-size: 12px !important;
	font-weight: 400 !important;
	color: #000;
}

.noDataForEvent .nodata__script {
	align-items: center;
}

.noDataForEvent .mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}

.noDataForEvent .event_no-data-li {
	padding-left: 14px;
}

.noDataForEvent .d-flex {
	display: flex !important;
}

.noDataForEvent .nodata__script .nodata__script1 {
	width: 50%;
	margin-top: -18px;
	margin-right: 16px;
}

.noDataForEvent .nodata__script .nodata__script2 {
	border: 1px solid #e5e5e8;
	padding: 12px;
	width: 50%;
	word-break: break-all;
}

.noDataForEvent .nodata__script .nodata__script2 .subtext__color {
	color: #999999 !important;
}


.textContentForNoData {
	font-weight: 500 !important;
	font-style: italic;
	color: var(--sk-no-data-text-color) !important;
	margin-top: 0px !important;
	font-size: 13px !important;
	font-family: var(--sk-font-urbanist-family);
	margin-bottom: 0px;
}

.hFRpKh {
	width: 100%;
}

.settingsAccountManagment {
	padding: 0px 32px;
}


/* Styles imported from Alert.css.......... */
.main_title#referrals-tab {
	font-weight: 500;
	font-size: 16px;
	margin: 10px 12px 10px 12px;
	margin-left: 0px !important;
	font-family: "Urbanist", sans-serif;
}

.main_title#successplay-tab {
	font-weight: 600;
	margin: 15px 12px 11px 12px;
	margin-left: 0px !important;
	font-family: "Urbanist", sans-serif;
	font-size: 18px;
}

.content-wrap {
	/* height: calc(100% - 75px); */
	padding: 0 20px;
	position: relative;
	border-radius: 5px;
	background-color: #fff;
}

.appWrapper .workspace-form-wrpper .form {
	background-color: #fff;
}

.p-3 {
	padding: 1rem !important;
	padding-top: 12px !important;
}

.new_survey_box {
	height: 100%;
}

._list_wrapper {
	margin-top: 118px;
}

.modal-body .side_heading {
	padding-left: 0px;
	color: #212529;
	font-weight: 600;
	font-size: 16px;
}


/* Styles imported from NoDataFound.css.......... */

.no__data__found {
	width: 100%;
	background: #FDFDFD;
	font-family: "Urbanist", sans-serif !important;
	padding: 0px 20px;
	height: 656px;
}

.no__data__found p {
	margin-top: 0;
	margin-bottom: 0px;
	font-weight: 500;
}

.no__data__found a:hover {
	text-decoration: none;
	color: white
}

.no__alert__text {
	text-align: center;
	padding: 40px;
	padding-bottom: 30px;
	border-bottom: 1px solid #e5e5e5;
}

.textContent {
	font-size: 14px !important;
}

.supportText {
	font-size: 12px !important;
}

.textContent,
.supportText {
	font-weight: 400 !important;
	font-style: italic;
	color: var(--sk-no-data-text-color) !important;
	margin-top: 10px !important;
}

.view-link {
	color: #fc7242;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
}

.no__data__text {
	font-size: 13px;
	font-weight: 400 !important;
	font-size: 13px;
	font-style: italic;
	color: var(--sk-no-data-text-color) !important;
}

.succesplays__noData .MuiButton-label {
	text-transform: capitalize;
}

.succesplays__noData div {
	border-bottom: unset !important;
	text-align: center;
}

.main__content {
	margin: 30px 30px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.right__content {
	margin-left: -11px;
	padding-left: 20px;
	margin-top: 20px;
}

.p__size {
	margin-bottom: 8px !important;
	font-weight: 400 !important;
	font-size: 12px !important;
}

.alignItems {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

.add__btn {
	width: auto;
	font-size: 13px !important;
	padding: 5px 25px !important;
	background: #ffb13d !important;
	border: 0 none;
	color: #fff !important;
	font-weight: 500;
	border-radius: unset !important;
}

.learn__more__btn {
	padding: 5px 15px !important;
	font-size: 13px;
	color: #000000;
	font-weight: 400;
	border: 1px solid #e5e5e5;
}

.customerAccounts__notFound {
	background-color: white;
	padding: 0px 30px;
	margin-top: -8px;
	height: 340px;
}

.top__section,
.bottom__section,
.left__section {
	text-align: center;
}

.summary__body {
	padding: 30px 20px;
}

.bottom__section {
	margin-top: 20px;
}

.customerAccounts__notFound_header h3 {
	font-size: 18px;
	font-weight: 500;
	margin: 0;
}

.customerAccounts__notFound_header {
	/* padding: 15px 25px 0px 25px; */
	background: #fff;
}

.seperator {
	width: 100%;
	margin: 1em 8% 0.6em 0;
	padding: 0;
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}



.supportTextAccount360 {
	font-family: inherit !important;
	font-size: 14px !important;
	font-style: italic !important;
	font-weight: 600 !important;
	color: var(--sk-no-data-text-color) !important;
}

.supportTextForDashboardScreen {
	font-family: inherit !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	color: var(--sk-no-data-text-color) !important;
}

.textContentForNoDataContactMapping {
	font-weight: 600 !important;
	font-style: italic;
	color: var(--sk-no-data-text-color) !important;
	margin-top: 10px !important;
	font-size: 14px !important;
}


/* Styles Imported from Calendar.css */

.phoenix__calendar__container {
	padding-left: 1.2rem !important;
	padding-right: 1.2rem !important;
	padding-bottom: 0px;
}

.phoenix__calendar__container .tab-content {
	width: 99%;
	display: flex;
	flex-direction: row;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid var(--sk-border-color);
	border-radius: 12px;
	opacity: 1;
	overflow: hidden;
	margin: 0px;
}

.phoenix__calendar__container .tab-content .tab-pane {
	width: 100%;
}

/* Phoenix additional style end */
.phoenix__calendar__container .nav-tabs .nav-link,
.phoenix__calendar__container .nav-tabs .nav-link.active {
	font-size: 15px !important;
	font-weight: 500 !important;
	color: #A1A1A1 !important;
	letter-spacing: 0.2px;
	margin-right: 40px;
	cursor: pointer;
	padding: 0 0 0.4rem 0 !important;
	line-height: 2.25rem;
	background-color: transparent !important;
	border-bottom: 2px solid transparent !important
}

.phoenix__calendar__container .nav-tabs {
	border-bottom: none !important;
}

.phoenix__calendar__container .nav-tabs .nav-link.active {
	color: #fc7242 !important;
	border-bottom: 3px solid #fc7242 !important;
	background: none;
}

.phoenix__calendar__container .daily-event-dashboard {
	margin: 11px -20px;
}

.phoenix__calendar__container .react-datepicker__current-month,
.phoenix__calendar__container .react-datepicker__navigation {
	/* display:none !important;  */
}

.phoenix__calendar__container .daily-event-header {
	display: flex;
	justify-content: space-between;
	padding: 15px 20px;
	background-color: #FAFAFA;
	border-bottom: 1px solid #E5E5E8;
}

.phoenix__calendar__container .daily-event-header .display-date {
	display: flex;
	align-items: center;
}

.phoenix__calendar__container .daily-event-header .display-date p {
	padding: 0 10px;
	font: 13px Urbanist;
	font-weight: 400;
	color: #5A6169;
}

.phoenix__calendar__container .daily-event-header .react-datepicker-popper,
.weekly-DatePicker-header .daily-event-header .react-datepicker-popper {
	position: absolute;
	will-change: transform;
	top: 0px;
	/* left: -200px !important; */
	transform: translate3d(472px, 50px, 0px);
	height: 260px;
}

.phoenix__calendar__container .react-datepicker {

	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.phoenix__calendar__container .daily-event-header .react-datepicker-popper[data-placement^="bottom"],
.weekly-DatePicker-header .react-datepicker-popper[data-placement^="bottom"] {
	margin-top: 0px;
}

.phoenix__calendar__container .daily-event-header .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.weekly-DatePicker-header .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border: 1px solid rgba(0, 0, 0, 0.05);
}

.phoenix__calendar__container .daily-event-header .react-datepicker__day,
.weekly-DatePicker-header .react-datepicker__day {
	border-radius: 0px;
	color: #5A6169;
	font-weight: 400;
}

.phoenix__calendar__container .daily-event-header .react-datepicker__day:hover,
.weekly-DatePicker-header .react-datepicker__day:hover {
	background: #eceeef;
	cursor: pointer;
	color: #000 !important;
	border-radius: 0px !important;
}

.phoenix__calendar__container .daily-event-header .react-datepicker__day--disabled,
.weekly-DatePicker-header .react-datepicker__day--disabled {
	cursor: default;
	color: #c3c7cc;
}

.phoenix__calendar__container .daily-event-header .react-datepicker__day--keyboard-selected,
.phoenix__calendar__container .daily-event-header .react-datepicker__day--selected,
.weekly-DatePicker-header .react-datepicker__day--selected {
	background-color: #FC7242;
	color: white;
	border-radius: 0px;
}

.phoenix__calendar__container .daily-event-header .react-datepicker__current-month,
.daily-event-header .react-datepicker-year-header,
.weekly-DatePicker-header .react-datepicker-year-header {
	color: #5A6169;
	font-weight: 500;
}

.phoenix__calendar__container .daily-event-header .react-datepicker__day--keyboard-selected:hover,
.weekly-DatePicker-header .react-datepicker__day--keyboard-selected:hover {
	background-color: #eceeef;
	color: black;
}


.phoenix__calendar__container .daily-event-header .react-datepicker,
.weekly-DatePicker-header .react-datepicker {
	border-radius: 0px;
	border: 1px solid rgba(0, 0, 0, 0.05);
}

.phoenix__calendar__container .daily-event-header .react-datepicker__header,
.weekly-DatePicker-header .react-datepicker__header {
	background-color: transparent;
}


.phoenix__calendar__container .weekly-event-header .weekly-display-date {
	display: flex;
	align-items: center;
}

.phoenix__calendar__container .weekly-event-header .weekly-display-date div {
	padding: 0 10px;
	font: 13px Urbanist;
	font-weight: 400;
	color: #5A6169;
}


.phoenix__calendar__container .weekly-event-dashboard {
	margin: 11px -20px;
}

.phoenix__calendar__container .weekly-event-header {
	/* display: flex; */
	justify-content: space-between;
	padding-top: 0px;
	padding-bottom: 0px;
	background-color: #FAFAFA;
	/* height: calc(100vh - 130px); */
	/* min-height: calc(100vh - 197px); */
	border-bottom: 0px;
	/* overflow-y: overlay; */
	/* overflow-x: hidden; */
}

.phoenix__calendar__container .react-datepicker__month-read-view,
.phoenix__calendar__container .react-datepicker__year-read-view {
	visibility: visible !important;
}






/* styles imported from CSMDashboard */



.page-header {
	padding-top: 10px !important;
	padding-bottom: 10px;
}

.page-header1 {
	padding-top: 3px;
}

.page-header .page-title {
	font-size: 16px;
	color: #000;
	font-weight: 500;
	/* font-family: "Urbanist", sans-serif; */
	line-height: 1;
	margin: 0;
	padding: 0;
	float: left;
}

.page-header .mt-2 {
	margin-top: 0rem;
}

.page-header .sk_toggle {
	padding: 0px;
}

.sk_toggle .sk_toggle_left {
	display: inline-block;
	margin-right: 1px;
	border-radius: 20px 0 0 20px;
	padding-left: 5px;
	padding-right: 5px;
	height: 30px;
}


.card-header.border-bottom {
	border-bottom: 0 none !important;
}

.card-header:first-child {
	padding: 17px 10px 10px 20px;
}




.card-header:first-child {
	border-radius: 0.625rem 0.625rem 0 0;
}

.top_performers_widget_csm .card-header {
	padding-bottom: 21px;
}

.card-header.border-bottom {
	border-bottom: 0 none !important;
}

.card-header h6 {
	font-size: 18px;
	font-weight: 500;
	/* font-family: "Urbanist", sans-serif; */
}

.card-header:first-child {
	padding: 15px 5px 0px 25px;
}

#UiLabelTooltip.HWSelectMultiSelectTooltip {
	max-width: 200px;
	/* margin-bottom: 50px; */
	/* margin-top: -50px; */
	/* top: -50px !important; */
}

#UiLabelTooltip.spSummaryTooltip {
	max-width: 400px;
	margin-top: -50px;
	/* top: -50px !important; */
}

#UiLabelTooltip.spSummaryTooltip .tooltip-inner {
	min-width: auto;
}

#UiLabelTooltip.alertSelectTooltip {
	max-width: 400px;
	/* margin-top: -50px; */
	/* top: -50px !important; */
}

#UiLabelTooltip.alertSelectTooltip .tooltip-inner {
	max-width: 400px;
}



.apexcharts-xaxis-label-phase {
	overflow-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 16px;
	/* fallback */
	max-height: 32px;
	/* fallback */
	-webkit-line-clamp: 2;
	/* number of lines to show */
	-webkit-box-orient: vertical;
	/* transform: translate(30px, 10px); */
	text-transform: uppercase;
}



.notifications_list .full_text_para p .red {
	color: #e94b55 !important;
}







/* ----------------------CUSTOM CHECK BOX------------- */
/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	/* cursor: pointer; */
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
	/* position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; */
}

/* Create a custom checkbox */
.checkmark {
	/* position: absolute; */
	/* top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border: 2px solid #E5E5E8;
        border-radius: 2px; */
	position: relative;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #e2dddf;
	border: 2px solid #E5E5E8;
	border-color: #fc7241;
	border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
	background-color: #FFB13D;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 6px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid #FFB13D;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.form-radio {
	line-height: 12px;
	position: relative;
	margin: 0 10px 0 0;
}

.form-radio.radio {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
}

.form-radio .radio:checked+label:before {
	background-position: -63px -186px;
}

.form-radio .radio+label {
	padding-left: 25px;
	position: relative;
	user-select: none;
}

.form-radio .radio+label {
	font-size: 12px;
}

html {
	overflow-y: scroll;
}

.form-radio .radio+label:before {
	content: '';
	position: absolute;
	top: calc(50% - 8px);
	left: 0;
	width: 16px;
	height: 16px;
	background: url(../assets/images/spritesheet.svg) no-repeat -79px -186px;
	cursor: pointer;
}

.scroll1 {
	position: relative;
	overflow-x: hidden;
	width: auto;
	height: 100%;
}

.slimScrollDiv1 {
	height: calc(100vh - 52px) !important;
}

.dropdown-toggle1 {
	/* white-space: nowrap; */
}

.highcharts-credits {
	display: none !important;
}

.targetvalue {
	margin-top: -9% !important;
	position: relative;
	font-size: 11px !important;
	font-weight: 400;
}

.highcharts-container {
	margin: auto;
}

/* .highcharts-grid {
    display: none;
 } */
.mrr {
	font-size: 12px !important;
	font-weight: 400;
}

/* .revenue1{
    margin-top: 13% !important;
} */

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

.account_wrap_select_sf {
	width: 205px;
	margin: 10px 5px;
}


input[type='radio'] {
	-webkit-appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	outline: none;
	border: 1px solid gray;
}

input[type="radio"] {
	cursor: pointer;
}


input[type='radio']:before {
	content: '';
	display: block;
	width: 60%;
	height: 60%;
	margin: 20% auto;
	border-radius: 50%;
}

input[type="radio"]:checked:before {
	background: grey;

}

.form-radio-input .radio .audienceRadio {
	cursor: pointer;
}

input[type="radio"]:checked {
	border-color: grey;
}



.table>tbody>tr>td,
.table>tfoot>tr>td,
.table>thead>tr>td {
	padding: 3px 8px;
}


.w-100 {
	width: 100% !important;
}

input[type="radio"]:checked:before {
	background: var(--secondary-color);
	border: var(--secondary-color)
}

input[type="radio"]:checked {
	border-color: var(--secondary-color);
}

.container {
	max-width: 1170px;
}

input[type='radio'] {
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	border-radius: 50%;
	outline: none;
	border: 1px solid gray;
}

.gearIcon {
	height: 15px;
	margin-top: -38px;
	float: right;
	margin-right: 9px;
	position: relative;
	margin-left: -30px;
	/* margin-left: 85px; */
}

.main_heading_wrapper {
	padding: 15px 25px;
	align-items: center;
	font-size: 16px;
}

.accoutn_name_tooltip:after {
	content: " ";
	position: absolute;
	top: 100%;
	/* At the bottom of the tooltip */
	left: 50%;
	margin-left: -5px;
	border-width: 8px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
	box-shadow: #ccc 0px 0px 0px;
}

.accoutn_name_tooltip,
.defaultSelectTextTooltipText {
	background-color: var(--sk-heading-color);
	padding: 5px 10px;
	bottom: 12%;
	right: 75px;
	font-weight: 400;
	z-index: 999;
	box-shadow: -2px 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px 0px rgba(0, 0, 0, 0.2);
	border-radius: 2px;
	text-align: left;
	font-size: 13px;
	white-space: normal;
	width: auto;
	color: #fff;

}

.portofolioDropdownWrap .accoutn_name_tooltip {
	background-color: var(--sk-heading-color);
	/* padding: 7px; */
	padding: 8px 5px 8px 10px;
	bottom: 12%;
	/* min-width: 164px; */
	right: 75px;
	font-weight: 400;
	z-index: 999;
	box-shadow: 0px 0px 0px 1px #e0e0e0;
	/* box-shadow: -2px 0px 4px 1px rgba(0, 0, 0, 0.1), 0px 2px 3px 0px rgba(0, 0, 0, 0.2); */
	border-radius: 2px;
	text-align: left;
	font-size: 12px;
	white-space: normal;
	/* width:auto; */
	max-width: 300px;
	word-break: break-all;
}

.portofolioDropdownWrap .accoutn_name_tooltip:before,
.utilizationWrap .accoutn_name_tooltip:before,
.oppertunityWrap .accoutn_name_tooltip:before,
.skUtilizationWrap .accoutn_name_tooltip:before {
	transform: rotate(45deg);
	content: "";
	border: 6px solid var(--sk-heading-color);
	position: absolute;
	width: 0px;
	height: 0px;
	left: 50%;
	margin-left: -5px;
	z-index: 1;
	bottom: -5px;
	top: auto;
	right: 0;
	box-shadow: 0.5px 0.5px 0.5px #ccc;

}

.portofolioDropdownWrap .accoutn_name_tooltip:after,
.utilizationWrap .accoutn_name_tooltip:after,
.oppertunityWrap .accoutn_name_tooltip:before,
.skUtilizationWrap .accoutn_name_tooltip:before {
	display: none;
}

.accoutNameOverLay,
.defaultSelectTextTooltip {
	top: -60px !important;
	z-index: 9999;
}



.MuiTooltip-popper {
	z-index: 999999 !important;
}


/* Styles imported from HWtooltip.css */

.sk_error_message_popup_wrap {
	background-color: #ffe4e7;
	border: 1px solid red;
	font-size: 12px;
	padding: 12px;
	width: 100%;
}

.sk_error_message_popup_wrap header h2 {
	font-weight: 600;
	font-size: 12px;
	color: #000000;
	letter-spacing: 0.1px;
	line-height: 1.5;
}

.sk_error_message_popup_wrap header {
	padding: 0px;
	justify-content: space-between;
}

.sk_error_message_popup_wrap .message_ul {
	margin: 0;
	padding: 0;
	margin-left: 15px;
	margin-top: 5px;
}

.sk_error_message_popup_wrap .message_ul .message {
	margin-bottom: 0;
	font-size: 12px;
	color: #000;
	padding-right: 16px;
}

.sk_errorMsg {
	border-bottom: 1px solid red !important;
}

.sk_error_message_popup_wrap .closePopup img {
	width: 9px;
}

.sk_error_message_popup_wrap .closePopup {
	cursor: pointer;
}

.org-accounts-wrapper .react-datepicker {
	z-index: 999;
}

/* Styles imported from ExpansionAnalyser.css */
.expansionAnalyserGraphTooptip ul {
	padding-left: 0px;
	/* display: flex;
	justify-content: space-between; */
	/* align-items: center; */
	margin-bottom: 0px;
}

.expansionAnalyserGraphTooptip ul li {
	list-style: none;
	font-size: 11px;
	font-weight: 400;
}

.expansionAnalyserGraphTooptip ul li.exp_overview_hover_name {
	margin-right: 2px;
}

.expansionAnalyserGraphTooptip {
	min-width: 170px;
	background-color: var(--sk-heading-color);
	padding: 10px;
	border-radius: 4px;
	box-shadow: -2px 0px 10px rgb(0 0 0 / 10%);
	color: var(--primary-color);
}

/* Styles imported from PowerbiReporting.css */



body p {
	margin-bottom: 0px !important;
}

aside.emoji-picker-react .content-wrapper:before {
	/* content: none; */
	content: attr(data-name);
	color: #aaa;
	font-size: 11px;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 37px;
	z-index: 10;
	line-height: 78px;
	max-height: 50px;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;
	text-align: center;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
	outline-color: transparent !important;
	outline-width: 0px !important;
}


.block-ui-message-container {
	top: 10% !important;
}



.bg-green {
	background: #CDF6E0;
}

.bg-orange {
	background: #FFE5DB;
}

.bg-blue {
	background: #E1F2FD;
}

.bg-gray {
	background: #EEE6FA !important;
}

.bg_dark_gray {
	background-color: #707070 !important;
}

.col_black {
	color: black !important;
}

.complete {
	width: 22%;
	height: 68px;
	text-align: center;
	color: black;
	margin: 5px;
	background: #fff;
	font-weight: 800;
	border: 1px solid transparent;
}

.count-and-status {
	width: 22%;
	/* height: 68px; */
	padding-bottom: 5px;
	text-align: center;
	color: black;
	margin: 5px;
	background: #fff;
	font-weight: 800;
	border: 1px solid transparent;
}

.count-and-status-border-color[task-state="open"] {
	border-color: #E1F2FD;
}

.count-and-status-border-color[task-state="notStarted"] {
	border-color: #E1F2FD;
}


.count-and-status-border-color[task-state="complete"] {
	border-color: #CDF6E0;
}

.count-and-status-border-color[task-state="overdue"],
.count-and-status-border-color[task-state="completed"] {
	border-color: #FFE5DB;
}

.count-and-status-border-color[task-state="assigned_to_me"],
.count-and-status-border-color[task-state="Overdue"] {
	border-color: #EEE6FA;
}

.task_title {
	margin-bottom: 0;
	margin-top: 10px;
	color: #999999;
	/* font-weight: 800; */
	margin-left: 20px;
	letter-spacing: 0.28px;
}

.main_title {
	font-size: 16px;
	font-weight: 400;
	color: #000000;
	border: none;
	padding-left: 2px;
	font-family: "Urbanist", sans-serif;
}

.attachment_wrapper {
	background: #E5E5E5;
	width: 97%;
	margin: 12px 15px;
	padding: 8px 10px;
}


.attached_img {
	width: 100px;
	height: 100px;
}

.heading-3 {
	font-size: 8px !important;
	font-weight: 400;
	text-align: center;
	color: #000000 !important;
	font-family: 'Urbanist', sans-serif !important;
}

.attachment_name {
	font-size: 11px !important;
	margin-top: 5px;
	font-weight: 400;
	text-align: center;
	color: #000000 !important;
	font-family: 'Urbanist', sans-serif !important;
}

.btn.btn-outline-tertiary {
	color: #5d5d5d;
	border-color: #5d5d5d;
	margin: 0 auto;
	background: #F1F1F1 0% 0% no-repeat padding-box;
	border: 1px solid #CCCCCC;
	border-radius: 4px;
	opacity: 1;
	font-size: 9px;
	padding: 5px 10px;
}

.upload_box {
	/* border: 1.2px solid #80808091;
	padding: 15px 3px; */
	opacity: 1;
	background: white;
	width: 150px;
	border: 1px solid #ddd;
	padding: 20px 10px;
}

.attach {
	margin: 5px 0px;
}




.tooltip22 .tooltiptext22 {
	visibility: hidden;
	width: 130px;
	background-color: black;
	color: white;
	text-align: left;
	font-weight: 100;
	font-size: 11px;
	text-align: center;
	border-radius: 0px;
	top: 0px;
	left: -37px !important;
	padding: 7px;
	position: absolute;
	z-index: 3;
	margin-top: 21px;
	box-shadow: 0px 3px 4px #0000002C;
	border: 0.5px solid #D0D0D0;
	opacity: 1;
	font-family: 'Urbanist', sans-serif !important;
	margin-left: 6px;
}

.tooltiptext22:before {
	content: "";
	content: "";
	border: 7px solid transparent;
	position: absolute;
	border-left-color: black;
	border-bottom-color: black;
	width: 0px;
	height: 0px;
	left: 56px;
	top: -9px;
}

.tooltiptext22:after {
	content: "";
	border: 13px solid transparent;
	position: absolute;
	border-left-color: transparent !important;
	border-bottom-color: transparent !important;
	width: 0px;
	height: 0px;
	left: 11px;
	top: -24px;
}

.content-wrap .ReferalWidget .pacs .row .ruleUIReview {
	width: 365px !important;
}

.content-wrap .ReferalWidget .pacs .row .MiddlePanelWidget {
	width: calc(100% - 370px) !important;
}

.content-wrap .ReferalWidget .WizardContainer {
	width: 223px;
	margin: 0;
}

.content-wrap .ReferalWidget .pacs .row .ruleUIReview {
	width: 437px !important;
	background: #E5E5E8 !important;
}

.content-wrap .ReferalWidget .pacs .row .ruleUIReview {
	width: 360px !important;
	background: #E5E5E8 !important;
}

.notification_process {
	/* border-bottom: 1px solid red;
	padding-left: 15px; */
	font-size: 14px !important;
	/* font-weight: bold !important; */
	color: #333333 !important;
	font-family: 'Urbanist', sans-serif !important;
	font-weight: 400;
}

.err_message {
	margin-bottom: 0 !important;
	font-weight: 300;
}

.main_heading_wrapper {
	padding: 15px 25px;
	background: #F5F5F5;
}


/* Map Account Popup  */





/* Styles imported from Segments.css */






/* imported from touchpoint pop up.css */

.touchpoint__archive_icon {
	display: block;
	width: 30px;
	height: 30px;
	background: var(--iconUrl);
	background-position: -638px -908px;
	zoom: 0.9;
	cursor: pointer;
	margin-top: 6px;
}


/* styles imported from workspaceutil.css */


.task__view__table-container .MuiDataGrid-sortIcon {
	display: none;
}

.MuiDataGrid-columnHeader .comments__header {
	border-right: none;
}

.task__view__comment__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18%;
	border-left: var(--sk-border-color);
}

.task__view__comment__container-icon {
	position: absolute;
	width: 2.188rem;
	height: 2.188rem;
	background: var(--iconUrl);
	background-position: -36.40rem -17.438rem;
	zoom: 0.75;
}

.no_account_container {
	display: flex;
	justify-content: center;
}

.task__view__comment__count__container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18%;
	/* margin-top: -0.313rem; */
	border-left: var(--sk-border-color);
}

.single_playbook_deatils__container .task__view__comment__count__container {
	width: 100%;
}

.task__view__comment__count__container-icon {
	position: absolute;
	width: 2.188rem;
	height: 2.188rem;
	background: var(--iconUrl);
	background-position: -32.9rem -17.375rem;
	zoom: 0.8;
}

.single_playbook_deatils__container .task__view__comment__count__container-icon {
	margin-left: -8px;
}

.task__view__comment__count__text {
	background-color: #7C7C7C;
	margin-top: 13px;
	margin-left: 17px;
	width: 19px;
	height: 18px;
	/* text-align: center; */
	font-size: 10px;
	border-radius: 50%;
	color: #ffffff;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
}

.task__view__table .title__container {
	max-width: 100%;
	overflow: hidden;
	/* margin-top: 5px; */
}

.playbook_milestone_gridtable_conatiner .title__container {
	max-width: 90%;
}

.grid_non_editable_row.grid_date_picker {
	opacity: 0.6;
	pointer-events: none !important;
}

.grid_non_editable_row.grid_date_picker.taskDueDate {
	pointer-events: auto !important
}


.grid_date_picker{
	padding: 0px !important;
}
.opacity {
	opacity: 0.6;
}

.grid_non_editable_row_normal.grid_date_picker {
	pointer-events: none !important;
}

.custom__column__header-container {
	width: 100%;
	overflow: visible;
	text-align: center;
	justify-content: center;
	align-items: center;
	position: relative;
}

.custom__column__header-container-left .custom__column__header-name {
	text-align: left !important;
}


.custom__column__header-sort-icon-container {
	position: absolute;
	height: 20px;
	width: 100%;
	top: -10px;
	display: flex;
	z-index: 9999999999999;
	/* background-color: red; */
	justify-content: center;
	align-items: center;
	text-align: center;
}

/* .custom__column__header-sort-icon{
  height: 20px;
  width: 20px;
  background-color: blue;
} */


.MuiDataGrid-sortIcon {
	display: none;
}

.custom__column__header-sort-icon {
	background: var(--iconUrl);
	background-position: -210px -755px;
	width: 2rem;
	height: 2rem;
	zoom: 0.7;
	cursor: pointer;
}

.custom__column__header-container:hover .custom__column__header-sort-icon-hover {
	background: var(--iconUrl);
	background-position: -210px -755px;
	width: 2rem;
	height: 2rem;
	zoom: 0.7;
	cursor: pointer;
}

.custom__column__header-sort-icon-rotate {
	transform: rotate(180deg);
}

.custom__column__cell__collaborators-container {
	display: flex;
	width: 100%;
	height: 100%;
	direction: row;
	justify-content: flex-start;
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color) !important;
	/* margin-left: -30px; */
}

.datagrid__table__column-collaborator .custom__column__cell__collaborators-container {
	/* padding: 10px; */
	display: flex;
	width: 100%;
	height: 100%;
	direction: row;
	justify-content: flex-start;
	align-items: center;
}

.custom__column__cell__collaborators-add {
	visibility: hidden;
	display: flex;
	height: 20px;
	width: 20px;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	font-size: 13px;
	font-weight: bolder;
	background-color: #5F1FA6;
	border-radius: 50%;
	cursor: pointer;
	scale: 0.8;
	margin-left: 0px;
	font-weight: 500;
}

.custom__column__cell__date-container {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	text-align: center;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-left: 13px;
}

.custom__column__cell__date-container__date__wrap{
	width: calc(100% - 24px);
	display: flex;
	align-items: center;
	justify-content: center;
}


.custom__column__cell__date-clear-wrap-container{
	width: 13px;
	height: 13px;
}

.custom__column__cell__date-clear-wrap-container_background:hover {
	background-color: #24046812;
	border-radius: 3px;
}

.custom__column__cell__date-container:hover {
	cursor: pointer;
}

.custom__column__cell__date-not-editable-container.custom__column__cell__date-container {
	cursor: auto;
}

.datagrid__table__container .react-datepicker {
	border-color: #5F1FA6;
}

.datagrid__table__container .react-datepicker__current-month {
	color: #3A3A69;
}

.datagrid__table__container .react-datepicker__day-names {
	background-color: #FFFFFF;

}

.datagrid__table__container .react-datepicker__day-name {
	color: #000000;
}

.datagrid__table__container .react-datepicker__day {
	color: #3A3A69;
	;
}

.datagrid__table__container .react-datepicker__day--selected {
	background-color: #7327CC !important;
	color: #ffffff !important;
}


.custom__column__cell__collaborators-container:hover .custom__column__cell__collaborators-add {
	visibility: visible;
}

.smart__konversations__collaborators__container--wrap-width {
	width: calc(100% - 25px);
}

.playbook_container_template_container .datagrid__table__container .smart__konversations__collaborators__container--wrap-width {
	width: calc(100% - 5px);
}

.custom__column__cell__collaborators-show {
	width: calc(100% - 10px);
	/* margin-left: 5px; */
	text-align: left;
	/* width: 100%; */
}

.account_segment_summary--container .custom__column__cell__collaborators-show {
	width: 100%;
}

.custom__column__cell__collaborators-show .smart__konversations__collaborator__name__wrap-name {
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom__column__cell__collaborators-show span:hover {
	cursor: pointer;
}

.custom__column__cell__collaborators-container .smart__konversations__collaborators__container-wrap {
	display: inline-flex;
	align-items: center;
	width: 100%;
}

.smart__konversations__collaborators_name {
	margin-left: 5px;
	max-width: 65%;
}

.playbook_summary_listing_grid_table__conatiner .smart__konversations__collaborators_name {
	margin-left: 0px;
}

.access_setting__cell.access_tabel_content .singleText {
	text-align: left !important;
}

.smart__konversations__collaborators__container--wrap {
	display: flex;
	align-items: center;
}

.datagrid__table__container .avatar {
	border-radius: 50%;
}

.datagrid__table__column-task-name {
	border-right: none !important;
}

.tilte__container {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.custom_tilte__container {
	display: flex;
	flex-direction: row;
}

.openTitle {
	cursor: pointer;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-items: center;
}

.openIcon {
	background: var(--iconUrl);
	background-position: -122px -338px;
	width: 30px;
	height: 30px;
	zoom: 0.6;
	margin-top: 10px;
	cursor: pointer;
}

.progress__update__icon--completed {
	background: var(--iconUrl);
	background-position: -657px -694px;
	width: 30px;
	height: 30px;
	zoom: 0.7;
	margin-top: 5px;
	/* margin-left: 10px; */
	cursor: pointer;
}

.account_segment_summary--container .progress__update__icon--completed {
	background-position: -498px -55px;
}

.account_segment_summary--container {
	position: relative;
}

.account_segment_summary--container .key_segment_with--title .progress__update__icon--completed {
	visibility: hidden;
}

.account_segment_summary--container .gritable_sub_title_text {
	color: var(--sk-smart-placeholder-color);
	font-size: 11.5px;
	font-family: var(--sk-font-urbanist-family), Medium;
	font-weight: 400;
}

.progress__update__icon {
	/* background: var(--iconUrl);
	background-position: -616px -694px;
	width: 30px;
	height: 30px;
	zoom: 0.7;
	margin-top: 5px;
	cursor: pointer; */

	background: var(--iconUrl) no-repeat -616px -692px !important;
	min-width: 25px !important;
	width: 27px;
	height: 30px !important;
	display: block !important;
	zoom: 0.7;
	margin-bottom: 0px;
	margin-right: 0px;
	margin-left: 0px;
	margin-top: 0px;
}

.custom_tilte__container-open-div {
	display: flex;
}

/* header changes */

.workspace__header__container {
	margin-right: 20px;
	padding: 0px 33px 0px 24px;
	padding-top: 10px;
	display: flex;
	justify-content: space-between;
}

.grid_title_three_dot_menu {
	margin-left: -20px;
}

.playbook_summary_main__container .workspace__header__container {
	display: flex;
	justify-content: space-between;
	/* font-family: var(--sk-font-urbanist-family); */
}

.touchpint_type--container .workspace__title__header,
.playbook_summary_details--container .workspace__title__header {
	font-family: var(--sk-font-urbanist-family);
}

.virtical_bar {
	border: 0.5px solid var(--primary-color);
	margin: 10px;
}

.center__align__header .sort_title {
	width: 100% !important;
}

.productRow_lable.defaultCursor2 {
	text-align: left !important;
	justify-content: flex-start !important;
}

.pointer-events-none custom__column__cell__collaborators-container .smart__konversations__collaborators_name {
	pointer-events: none;
}

.touchpint_type--container .virtical_bar {
	margin: 5px;
}

.touchpint_type--container {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.touchpint_type--container .smart__input__btn-container {
	height: 30px;
}

.touchpint_type--container .workspace__title__header {
	white-space: nowrap;
}

.touchpint_type--container .smart__sk__search__container input[type=text]:focus {
	background-color: var(--sk-dashboard-bg-img--colour-3);
}

.gritable_sub_title_text {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	padding-top: 0px;
	font-weight: 400;
	padding-left: 5px;
}

.gritable_sub_title_text-container .editTitle {
	padding-bottom: 0px;
	padding-top: 0px;
}

.gritable_sub_title_text-container.title__container {
	display: block;
	width: 100%;
}

.no-product-icon {
	display: flex;
	width: 34px;
	height: 32px;
	background: var(--iconUrl);
	background-position: -477px -688px;
	min-width: 30px;
}

.hs-accImpected-modal .logoWrapper {
	display: flex;
}



.AdduserBtn img {
	margin-bottom: 6px;
}

.disabled-row {
	cursor: not-allowed;
}

.addUserForm .OptionContainer {
	width: 100%;
}

.addUserForm .phoneInput {
	width: 100%;
}


.main-title:hover,
.main-title:focus,
.main-title:active {
	color: #4e4e4e;
}

.main-title {
	font-size: 14px !important;
	color: #000;
	font-weight: 400;
	text-align: left !important;
	padding: 0 5px;
}

.main-heading-wrapper {
	padding: 15px 25px;
	background-color: #F5F5F5;
	margin-right: -2px;
}



.right-section {
	height: 48px;
	display: flex;
	align-items: center;

	justify-content: space-evenly;
	padding: 0px;
	width: 88px;


}





.right-section ul li,
.action-clicked ul li,
#finacialdashboard_Sticky_table .action-clicked {
	padding: 0 10px;
}

.left-section {
	display: flex;
	align-items: center;
	padding: 10px 0px;
	border-bottom: 1px solid #EFEFEF;
	width: 100%;
	height: 55px;
	cursor: pointer;
}

.tooltip_content_wrapper .headerRnewalFin {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid #EFEFEF;
	padding: 10px 0px;
}

.tooltip_content_wrapper .headerRnewalFin h2 {
	margin: 0;
	font-size: 18px !important;
	font-weight: 500;
	color: #000000;
	padding: 5px 0;
	line-height: 22px;
	letter-spacing: 0px;
}

.tooltiptext22Fin .tooltip_content_wrapper .body {
	padding-top: 0px !important;
	height: 320px;
	overflow-y: auto;
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul {
	display: flex;
	justify-content: space-between;
	padding: 8px 20px 8px 0;
	border-bottom: 1px solid #EFEFEF;
	align-items: center;
}

.accountRenewalsPopover .tooltiptext22Fin .tooltip_content_wrapper .body {
	height: 285px;
}

.tooltiptext22Fin .tooltip_content_wrapper #newals ul .account_name {
	/* min-width: 100px !important; */
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul .account_name {
	font-family: Urbanist;
	font-size: 13px;
	font-weight: 600;
	line-height: 19px;
	background-color: #fff;
	padding: 0px;
	border-radius: 3px;
	text-transform: uppercase;
	max-width: 10.5em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #3C3C3C;
	height: auto;
	min-width: 130px;
	display: flex;
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul .account_rate {
	width: 115px !important;
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul .account_rate {
	width: 100px;
	text-align: center;
	white-space: nowrap;
	list-style: none;
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul.account_rate h3 {
	font-size: 13px !important;
	font-weight: 500;
	margin: 0;
}

.tooltiptext22Fin .tooltip_content_wrapper .body ul .account_rate h3 p {
	font-size: 13px !important;
	font-weight: 300 !important;
	color: #fc7242;
	line-height: 1.5;
	text-align: center;
	margin-top: 5px !important;
	cursor: pointer;
}






.left-section {
	display: flex;
	align-items: center;
	padding: 10px 0px;
	border-bottom: 1px solid #EFEFEF;
	width: 100%;
	cursor: pointer;
}

.show_list_items_abs_click {
	position: relative;
}


/* styles imported from surveydetails.css */

.sk-tooltip.text-left.left.export-body h2 {
	color: #000;
	font-size: 13px;
	font-weight: 400;
	margin: 0;
	letter-spacing: 0.2px;
	padding-right: 15px;
	padding-top: 10px;
}

.tooltipleft .sk-tooltip.text-left.left.export-body:after {
	top: 6% !important;
	right: -11px !important;
	left: unset !important;
	transform: rotate(40deg) !important;
	filter: drop-shadow(2px -2px 2px rgba(0, 0, 0, 0.1)) !important;
	border-width: 14px 14px !important;
}

.custom-card-successplayT .UiToggleBtn.spDashboadStatus {
	left: 12px;

}

.custom-card-successplayT .cardToggleBtn .custom-toggle .custom-control-label::after {
	left: 40px !important;
}


.export-details li .label-options::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #fff;
	position: absolute;
	left: 0;
	margin-left: -19px;
	/* top: 46%; */
	transform: rotate(-90deg);
}

.export-details li.list_items .label-options::before,
.export-details li.list_items .label-options::after {
	top: 40%;
}

.export-details li.format_list .label-options {
	min-height: 30px;
}

.export-details li.format_list .label-options::before,
.export-details li.format_list .label-options::after {
	top: 40%;
}

.export-details li .label-options .option-wrapping {
	display: flex;
	margin: 5px 15px;
}

.analyticsPopver .export-details .form-check-label {
	top: -2px !important;
	left: 5px;
}

.export-details li .label-options .option-wrapping span {
	font-size: 12px;
	color: #59686B;
	font-weight: 400;
	letter-spacing: 0.2px;
	padding-left: 5px;
}

.export-details .footer {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #E5E5E8;
	padding: 15px 0px 10px 0px;
}

.export-details .footer button.save:hover {
	color: #ffffff;
}

.export-details .footer button.cancel:hover {
	color: #ffb13d;
}

.export-details .footer button {
	cursor: pointer;
	height: 31px;
	padding: 6px 34px;
	display: block;
	appearance: none;
	font-size: 13px;
	font-weight: 500;
	margin: 0;
	line-height: 1.6;
	border-bottom: 1px solid #ffb13d !important;
}

.export-details .footer .cancel {
	border: 1px solid #ffb13d;
	color: #ffb13d;
	background-color: #fff;
}

.export-details .footer .save {
	border: 1px solid #ffb13d;
	background-color: #ffb13d;
	color: #ffffff;
}

.export-details li label {
	/* white-space: nowrap; */
	margin: 0;
	font-size: 13px;
	font-weight: 500;
	color: #000000;
	min-width: 150px;
	width: 200px;
	text-align: end !important;
	position: relative;
	right: 20px;
	/* top: 20px; */
}

.export-details li .label-options {
	/* margin-left: 25px; */
	border-left: 2px solid #E5E5E8;
	/* padding-left: 15px; */
	position: relative;
	/* background-image: url('../../assets/images/icons/arrow_right.svg'); */
}

.export-details li div label {
	text-align: left !important;
}

.export-details li .label-options::before {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: #E5E5E8;
	position: absolute;
	left: 0;
	margin-left: -21px;
	/* top: 46%; */
	transform: rotate(-90deg);
}

.export-details li {
	display: flex;
	align-items: center !important;
	align-items: baseline;
	margin-bottom: 25px;
	margin-top: 15px;
}

.export-details li:last-child {
	margin-bottom: 0px !important;
}

.analyticsPopver1 .export-details .format_list .formate_label {
	left: -2rem !important;
	top: 0px !important;
}

.analyticsPopver1 .export-details li.format_list .label-options::before,
.analyticsPopver1 .export-details li.format_list .label-options::after {
	top: 22%;
}

.analyticsPopver1 .export-details li .label-options .option-wrapping {
	display: flex;
	margin: 10px 15px;
}

.export-details .setOpacity {
	opacity: 0.6;
}

.export-details .resetOpacity {
	opacity: 1;
}

.export-details {
	margin: 0;
	padding: 0;
	flex-direction: column;
	flex-wrap: wrap;
	width: 100%;
	list-style: none;
}

.surveyAnalyticsPopver {
	top: 99px !important;
	left: -25px !important;
}

.surveyAnalyticsPopver .popover-body {
	padding: .5rem 0.25rem !important;
}

.modal-open {
	-moz-appearance: menuimage;
}

.modal-open::-webkit-scrollbar {
	width: 0 !important;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: hidden !important;
}

.mini-toolbar .mini-search input[type=text]:active+input[type=submit],
.mini-toolbar .mini-search input[type=search]:active+input[type=submit] {
	/* background-position: -672px 0px !important; */
}

.mini-toolbar .mini-search input[type=text]:focus+input[type=submit],
.mini-toolbar .mini-search input[type=search]:focus+input[type=submit] {
	pointer-events: all;
	/* background-position: -672px 0px !important; */
	filter: brightness(0.5);
}

input[type=text]:focus~input[type='submit'] {
	box-shadow: none !important;
}

.form-check-input {
	margin-top: 0.313rem;
	margin-left: -1.25rem;
}


.sk-modal .automated-action-form li p {
	font-size: 13px;
	font-weight: 400 !important;
	color: #000000;
	/* padding-left:0px */
	width: 350px;
}


.ck-editor__main p {
	font-weight: 400;
	color: black;
}

.react-datepicker__month-read-view--down-arrow {
	right: 1px;
	top: 3px;
}

.react-datepicker__year-read-view--down-arrow {
	top: 3px !important;
}

.react-datepicker__month-read-view--selected-month {
	margin-right: 17px;
}


.userManagement .platform_settings_wrapper {
	background-color: #fff;
}

.includeExcludeUser_tooltip .checkedContactTouchpoint {
	margin-top: 8px;
}

.userManagement .contactsettingIcon {
	margin-right: 10px;
	display: flex;
	cursor: pointer;
	align-items: center;
}




.success-play-form-row div span,
.success-play-instance-form-row div span {
	font-size: 13px;
	font-family: "Urbanist";
	font-weight: normal;
	line-height: 20px;
	color: #5A6169;
	white-space: nowrap;
}

.success-play-form-row div input[type=text],
.success-play-form-row div textarea,
.success-play-instance-form-row div textarea,
.success-play-instance-form-row div input[type=text] {
	/* width: 570px !important; */
	height: 36.56px;
	border: 1px solid #e5e5e8;
	font-size: 12px;
	color: #59686B;
	font-weight: 400;
	width: 100%;
}



.successPlayInput {
	padding: 10px;
	outline: none;
}

.success-play-form-row div:last-child {
	margin: 20px 50px 0 0;
}

.success-play-form-row .set-teaxtarea-field,
.success-play-instance-form-row .set-teaxtarea-field {
	width: 85%;
	margin-right: 0 !important;
}

.successPlaytextarea {
	height: 36.56px;
	border: 1px solid #e5e5e8;
	font-size: 12px;
	color: #59686B;
	font-weight: 400;
}



.row.main_row {
	/* overflow-y: scroll; */
	/* overflow-x: hidden; */
}

.successPlaytextareaWrap {
	width: 85%;
	margin-right: 0 !important;
}

.successPlaytextarea {
	min-height: 80px;
}

.sucessplay_formcheck {
	padding-left: 1.25rem;
	display: inline-block !important;
}

.sucessplay_formcheck label {
	font-size: 12px !important;
	font-weight: 300 !important;
	color: #59686B !important;
}

.activityListData_col1 {
	width: 41.5%;
	padding-left: 25px !important;
	display: flex;
	align-items: center;
	height: 65px;
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	padding: 12px 0;
}

.activityListData_row-wrapper .activity_custom_input {
	padding: 5px 5px;
	background-color: #FDFDFD;
	border: 1px solid #E5E5E8;
	margin-left: 30px;
	border-radius: 0px;
	font-size: 13px !important;
	resize: none;
}

.activityListData_col2 {
	vertical-align: baseline;
	width: 20%;
	/* text-align: center; */
	padding: 17px 18px 17px 22px;
	height: 65px;
	font-size: 12px;
	font-weight: 400;
	color: #000000;
}

.activityListData_col2 .css-i0syzg-menu,
.activityListData_col4 .css-i0syzg-menu,
.durationSelect .css-i0syzg-menu,
.successplayEventBasedSelector1 .css-i0syzg-menu,
.successplayInheritedfromTemplate .css-i0syzg-menu {
	margin-top: 0px !important;
}

.activityListData_col3 {
	vertical-align: baseline;
	width: 13%;
	padding: 0 0;
	position: relative;
	/* text-align: center; */
	height: 65px;
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	padding-left: 12px;
}

.activityListData_col3 .incepection {
	font-size: 11px !important;
	font-weight: 400;
	color: #59686B;
	white-space: nowrap;
	/* position: relative; */
	/* top: 7px; */
	position: absolute;
	top: 60px;
	left: -11px;
	margin-bottom: 0px !important;
}

.activityListData_col4 {
	vertical-align: baseline;
	width: 20%;
	/* text-align: center; */
	padding: 17px 15px;
	height: 65px;
	font-size: 12px;
	font-weight: 400;
	color: #000000;
}

.activityListData_row-wrapper .css-1bw8x3j-control,
.successplayInheritedfromTemplate .css-1bw8x3j-control {
	border-color: #E5E5E8 !important;
	height: 32px;
}

.activityListData_row-wrapper .css-1tkwtuo-control:hover,
.successplayInheritedfromTemplate .css-1tkwtuo-control:hover {
	border: 1px solid #E5E5E8 !important;
}

.activityListData_row-wrapper .css-1tkwtuo-control,
.successplayInheritedfromTemplate .css-1tkwtuo-control {
	height: 32px !important;
}

.activityListData_row-wrapper .section-delete-icon {
	margin-right: 3px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.activityListData_col5 {
	/* width: 68%; */
	text-align: center;
	display: flex;
	justify-content: center;
	height: 65px;
	font-size: 12px;
	font-weight: 400;
	color: #000000;
	padding: 21px 0 0 7px;
}

.activityListData_row-wrapper {
	border-bottom: 1px solid #F5F5F5;
	min-height: 80px;
	max-height: 200px;
}

.successplayInheritedfromTemplate .css-15refpj-singleValue {
	color: #59686B !important;
	max-width: 140px !important;
}

.createSegmentWrapper .left-wrapper .assgnee-section .account-log-info h1 {
	font-size: 14px !important;
	line-height: 48px;
	font-weight: 500;
}

.renewalSubHeading {
	padding-bottom: 10px;
	margin-top: -20px;
	color: #000000;
	margin: 0;
	font-size: 13px;
	font-weight: 300;
	font-family: "Urbanist", sans-serif;
	line-height: 2.25rem;
}

.createNewSuccessPlayWrapper .account-log-info ul {
	margin: 0;
	padding: 20px 0;
	list-style: none;
	display: flex;
	justify-content: space-around;
	text-align: center;
	background-color: #F5F5F5;
	border: 1px solid #EFEFEF;
	line-height: 1.5;
}

.createNewSuccessPlayWrapper .account-log-info ul li h3 {
	font-size: 16px !important;
	color: #000000 !important;
	font-weight: 500 !important;
	margin: 0;
	line-height: 2.25rem;
}

.avtiveRenewal {
	background: #e6d1fe 0% 0% no-repeat padding-box;
	color: #000000;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 0.2px;
	line-height: 2;
	width: max-content;
	padding: 0 10px;
	margin: auto;
	border-radius: 4px;
	/* margin-top: 10px; */
	width: 90%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}







.review-success-play-form-footer {
	padding: 25px 10px;
	border-top: 1px solid #EFEFEF;
}



.customeCategorySuccessplayBtn {
	border: 1px solid #E5E5E8;
	font-size: 13px;
	font-family: "Urbanist";
	font-weight: normal;
	line-height: 20px;
	display: inline-block;
	position: relative;
	margin: 5px 10px;
	background-color: #E5E5E8;
	border-radius: 4px;
	width: 124px;
	height: 31px;
	overflow: hidden !important;
}

.customeCategorySuccessplayBtn input[type=text] {
	width: 100% !important;
	height: 100% !important;
	background: #fff !important;
	border: none !important;
	padding: 3px 10px !important;
	padding-right: 25px !important;
	outline-offset: -2px !important;
}

.createNewSuccessPlayWrapper .redTextColor {
	color: #FC7242 !important;
}

.createNewSuccessPlayWrapper .custom-control-input:not(:disabled):active~.custom-control-label::before,
.createNewSuccessPlayWrapper .custom-control-input:focus:not(:checked)~.custom-control-label::before {
	border-color: #e1e5eb;
}

.createNewSuccessPlayWrapper .borderBottomRed {
	border-bottom: 1px solid red !important;
}

.createNewSuccessPlayWrapper .borderRed {
	background: #ff00001a !important;
	color: red !important;
	border-color: red !important;
}

.successplayEventBasedSelector .css-1c6lidv-container {
	height: 30px !important;
}

.successplayEventBasedTimeSelector .css-1c6lidv-container {
	height: 30px !important;
}

.successPlayTemplateName {
	padding: 5px 15px !important;
	background: #fff !important;
	border: 1px solid #F9D192 !important;
	border-radius: 15px !important;
	font-size: 13px !important;
	font-family: "Urbanist" !important;
	font-weight: normal;
	line-height: 20px !important;
	width: 95px !important;
	height: 30px !important;
}


.createNewSuccessPlayWrapper .account-play-btn:hover,
.review-success-play-form-footer .btn-inverse:hover {
	text-decoration: none !important;
}

.sucessplay_last_circle {
	margin-right: 10px;
}

.user-management-table tbody tr.collapse.show {
	display: table-row !important;
}

.headerModalclassSPT {
	background-color: #efefef;
	border-bottom: 2px solid #e5e5e5 !important;
	width: 540px;
	padding: 15px 0px 10px 15px !important;
	font-size: 15px !important;
	font-weight: 500;
	font-family: "Urbanist";
	color: #000000;
	margin: 0;
	padding: 0;
	line-height: 1em;
	letter-spacing: 0.5px;
	border-radius: 0px !important;
}

.popUpWidthSPT {
	width: 540px !important;
	margin: 15% auto !important;
}

.SuccessPlayCreationPopup {
	transform: translate(0, -25%) !important;
}

.SPTDuplicateModal .appIntPopup {
	border: 1px solid var(--confirm-btn-color) !important;
	height: 27px;
	margin-left: 5px;
	margin-right: auto;
	margin-bottom: 12px;
	font-size: 13px;
	background: #fff;
	text-align: center;
	color: var(--confirm-btn-color);
	cursor: pointer;
	min-width: 80px !important;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}


.SPTDuplicateModal .appIntPopup1 {
	height: 28px;
	margin-left: 5px;
	margin-bottom: 12px;
	font-size: 13px;
	background: #fff;
	text-align: center;
	border: 1px solid #fc7242;
	color: #fc7242;
	padding: 7px 0px;
	cursor: pointer;
	width: 88px !important;
	font-weight: 600;
}

.SPTDuplicateModal .duplicate-form {
	padding: 5px 0px 0px;
	margin-bottom: -5px;
	border-bottom: 2px solid #efefef
}

.SPTDuplicateModal .duplicate-form li {
	display: flex;
	align-items: center;
	padding: 5px 0;
}

.SPTDuplicateModal .duplicate-form li label {
	margin: 0;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	width: 155px;
	text-align: left;
	white-space: nowrap;
}

.SPTDuplicateModal .duplicate-form li p {
	font-size: 13px;
	font-weight: 300 !important;
	color: #000000;
	/* padding-left:0px */
	width: 350px;
}

.popUpWidthSPT .modal-content {
	border-radius: 4px !important;
	box-shadow: none;
	border: 1px solid #dfe1e3;
	/* right: 60px; */
}

.successPlaytextarea::placeholder {
	color: #59686B;
	opacity: 1;
	/* Firefox */
}

.thankyou_wrapper {
	background: white;
	padding: 15px;
	min-width: 392px;
	padding: 15px;
}

.sk_logo {
	width: 100%;
	background-color: white;
	padding: 7px;
	margin-top: 11px;
	margin-bottom: 11px;
}

.padding-8 {
	padding: 8px;
}

.WizardContainer {
	width: 22.7%;
	border-right: 2px solid#EFEFEF !important;
}

.w-77 {
	width: 77%;
}

.h-80 {
	height: 75.9999%;
}

.m-13 {
	margin-right: 13px;
}

.SuccessPlayFont {
	font-size: 12px !important;
	max-height: 66px !important;
	min-height: 30px !important;
}

.b-bottom {
	border-bottom: 0px !important;
}

.customerJourney .tabs li a {
	font-size: 15px;
	font-family: "Urbanist", sans-serif;
	font-weight: 500;
	padding: 18px;
	line-height: 1.5;
	cursor: pointer;
}

.rateing_wrap1 {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: relative;
	color: white;
	align-self: center;
}

.rateing_wrap1 p {
	font-size: 20px !important;
	align-items: center;
	font-weight: 500;
	padding: 10px;
}

.one {
	background-color: #d48c88;
}

.two {
	background-color: #dc9a86;
}

.three {
	background-color: #e3a884;
}

.four {
	background-color: #ebb682;
}

.five {
	background-color: #f2c480;
}

.six {
	background-color: #d5c08a;
}

.seven {
	background-color: #b7bc93;
}

.eight {
	background-color: #9ab89d;
}

.nine {
	background-color: #7cb4a6;
}

.ten {
	background-color: #5fb0b0;
}

.fontWeight400 {
	font-weight: 400 !important;
}



.ml5 {
	margin-left: 10px;
}

.createNewSuccessPlayWrapper .add-success-play-form-footer {
	border-top: 1px solid #EFEFEF;
	justify-content: space-between !important;
	display: flex !important;
	width: 100%;
	padding: 10px;
	bottom: 0 !important;
}

.createNewSuccessPlayWrapper .left-wrapper .contents {
	padding: 20px;
	padding-bottom: 0;
	max-height: calc(70vh - 120px);
	min-height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
}



.conditionItem_wrap #exclude__text {
	color: var(--smart-konversations-label-color) !important;
	font-family: var(--sk-font-urbanist-family), Bold;
	font-weight: 500;
	cursor: pointer;
}



.conditionItem_wrap {
	margin: 10px;
}

.MuiIconButton-root.MuiIconButton-root {
	background-color: transparent !important;
	transition: none !important;
	-webkit-transition: none !important
}

#touchpointDashboard__container--listview {
	margin-top: 10px;
}

.playbook__cell__title--conatiner .title__container {
	width: 100%;
}

.createSegmentWrapper .account-log-info ul li h3 {
	font-size: 14px !important;
	line-height: 2.25rem !important;
	font-weight: 500 !important;
}

.smart__task__side_popup-form--container .smart__side__popup__form__details__container__row-footer .skPrimaryBtn {
	height: 2.15rem;
}

.smart__task__side_popup-form--container .smart__side__popup__form__details__container__row-footer .sk__checkbox__btn-container {
	height: 2.15rem;
}

.smart__playbook_task__side_popup-form--container .smart__side__popup__form__details__container__row-footer .skPrimaryBtn {
	height: 2.2rem;
}

.smart__playbook_task__side_popup-form--container .smart__side__popup__form__details__container__row-footer .sk__checkbox__btn-container {
	height: 2.25rem;
}

.smart__react__select_medium {
	background: var(--iconUrl);
	background-position: -394px -691px;
	min-width: 1.5rem;
	height: 1.375rem;
	scale: 1;
	margin-left: -5px;
}

.smart__react__select_high {
	background: var(--iconUrl);
	background-position: -362px -691px;
	min-width: 1.5rem;
	height: 1.375rem;
	scale: 1;
	margin-left: -4.5px;
}

.smart__react__select_low {
	background: var(--iconUrl);
	background-position: -330px -691px;
	min-width: 1.5rem;
	height: 1.375rem;
	scale: 1;
	margin-left: -4px;
}

.smart__date__placeholder__class--container .input__btn__calendar__icon__container {
	margin-right: 15px;
}

.renewal_details--container .datagrid__table.MuiDataGrid-root {
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.slect_lable {
	font-size: 13px;
	font-weight: 400;
	margin-left: 0.625rem;
	letter-spacing: 0.24px;
	min-width: 25%;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
}

.customerPortalRoles_popup--container {
	align-items: center;
	margin-top: 10px;

}

.smart__date__picker_disabled.smart__date__picker__with__input__field__container {
	background-color: var(--desable-color-input);
	opacity: 0.5;
	pointer-events: none;
}

.configure_outcome_dashboard--container {
	background-color: var(--primary-color);
	/* padding: 10px; */
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 13px;
	font-weight: 400;
}

.configure_outcome_note {
	padding: 10px;
	padding-right: 0px;
}

.configure_outcome_table--container {
	/* margin-top: 10px; */
}

.outcome_name--container .contact__edit__icon {
	position: relative;
	scale: 0.95;
}

.outcome_name--container .contact__delete__icon {
	position: relative;
	scale: 0.95;
}

.outcome_name--container {
	justify-content: space-between;
	height: 100%;
	align-items: center;
	padding-left: 10px;
}

.data__grid__table__header__name .contact__edit__icon {
	position: relative;
	zoom: 0.65;
	background-position: -398px -955px;
}

.sortWrap .phonix_info,
.data__grid__table__header__name.custom_houcome_header--container .phonix_info {
	margin-bottom: 2px;
	margin-left: 2px;
	margin-right: 2px;
}

.data__grid__table__header__name.custom_houcome_header--container .phonix_info {
	width: 15px;
}

.configure_outcome_note {
	display: flex;
	justify-content: space-between;
}

.add_outcome_plus_icon {
	background: var(--iconUrl);
	background-position: -496px -630px;
	width: 24px;
	height: 24px;
	scale: 0.85;
	display: block;
}

.configure_outcome_note .skPrimaryBtn {
	border: 1.5px solid var(--secondary-color);
	display: flex;
	align-items: center;
	min-width: 8rem;
	padding: 5px 0px;
	width: 150px;
	letter-spacing: 0.3px;
	height: auto;
}

.grid_edit_input--conatiner .sk__date__filter__arrow__icon__container-cross-arrow {
	position: relative;
	display: block;
	top: 10px;
	right: -20px;
}

.sk__date__filter__arrow__icon__container-save-icon {
	background: var(--iconUrl);
	background-position: -350px -956px;
	width: 30px;
	height: 30px;
	zoom: 0.8;
	cursor: pointer;
	margin-left: 30px;
	margin-top: 10px;
}

.indexNumber {
	position: relative;
}

.datagrid__table__container .indexNumber .grid__table__left-shadow-component {
	position: absolute;
	left: 0px;
}

.datagrid__table__container .outcomeName.MuiDataGrid-cell {
	padding: 0px;
}

.configure_outcomes--popup.smart-modal-popup {
	max-width: 85% !important;
}

.configure_outcomes--popup .configure_outcome_table--container {
	/* border-left: 1.5px solid #2304681A ; */
}

.outcome_name--actions {
	width: 50px;
	display: flex;
}

.configure_outcomes--popup .configure_outcome_note {
	padding-left: 0px;
}

.configure_outcomes--popup .configure_outcome_table--container .MuiDataGrid-columnHeader:first-child {
	border-left: 1.5px solid #2304681A;
}

.configure_outcome_table--container .MuiDataGrid-columnHeader:last-child {
	/* border-left: 1.5px solid #2304681A ; */
	border-right: none !important;
}

.configure_outcomes--popup .configure_outcome_table--container .MuiDataGrid-cell:first-child {
	border-left: 1.5px solid #2304681A;
}

.configure_outcomes--popup .configure_outcome_table--container .MuiDataGrid-cell:last-child {
	border-right: 1.5px solid #2304681A !important;

}

.configure_outcome_table--container .datagrid__table__container .MuiDataGrid-columnHeaders {
	border-top: 1.5px solid #2304681A !important;
	border-bottom: 1.5px solid #2304681A !important;
}

/* .configure_outcomes--popup .configure_outcome_table--container .datagrid__table__container .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerRenderZone{
	border-right: 1.5px solid #2304681A !important;
} */

.configure_outcomes--popup .configure_outcome_table--container .datagrid__table__container .MuiDataGrid-columnHeaders {
	border-top: 1.5px solid #2304681A !important;
	border-bottom: 1.5px solid #2304681A !important;
	border-right: 1.5px solid #2304681A !important;
}

.configure_outcome_table--container .datagrid__table__container .MuiDataGrid-virtualScroller {
	overflow-y: auto !important;
}

.popAlertMessage {
	position: absolute;
	width: 100%;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;

}

.popAlertMessage.playbooUnsubscribeMsg {
	margin-left: -240px;
}

.popAlertMessage .popAlertMessage_wrapper {
	background-color: var(--primary-color);
	width: auto;
	padding: 8px;
	box-shadow: 0px 9px 30px var(--sk-graph-popper);
	border-radius: 11px;
	font-weight: 500;
	font-size: 13px;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	border: 1px solid var(--sk-heading-color);
}

.popAlertMessage .closePopup {
	margin-left: 10px;
}

.popAlertMessage .closePopup img {
	width: 10px;
	height: 10px;
	cursor: pointer;
}



.event_flow_setup_btn,
a.event_flow_setup_btn {
	background-image: var(--iconUrl);
	background-position: -383px -59px;
	height: 24px;
	width: 24px;
}

.event-summary-details-icon .done {
	background-image: var(--iconUrl);
	background-position: -257.5px -283px;
	height: 24px;
	width: 24px;
}


.setting__right__tab .configure_outcome_table--container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.configure_outcome_table--container .skLoaderComponent {
	height: 62vh;
	position: absolute;
	display: flex;
	width: 100%;
}

.custom__multi__select__with__drop__down .selection--container {
	padding: 7px 7px 0px 7px;
}

.custom__multi__select__with__drop__down .smart__react__select__container {
	border-radius: 5px;
}

.custom__multi__select__with__drop__down .smart__react__select__control {
	height: 35px;
}

.custom__multi__select__with__drop__down .smart__react__select__control.smart__react__select__control--is-focused.smart__react__select__control--menu-is-open {
	height: 35px;
}

.custom__multi__select__with__drop__down .filter-dropdown-search-box_contaciner {
	padding: 7px 7px 0px 7px;
}

.custom__multi__select__with__drop__down .filter-dropdown-search-box {
	border-radius: 5px;
}

.multiSeclectDropdown_overlay .custom__multi__select__with__drop__down .filter-dropdown-search-box-options {
	margin: 7px 7px 7px 7px;
	border-radius: 5px;
}

.multiSelectDropdown.custom__multi__select__with__drop__down .filter-dropdown-search-box-options-container {
	margin-right: 0px;
}

.custom__multi__select__with__drop__down .globalfilter_appy_btn_container {
	margin-bottom: 7px;
	margin-right: 7px;
}

.theme__based__info__icon {
	background: var(--iconUrl) no-repeat -545px -627px;
	width: 22px;
	height: 22px;
	margin-left: 6px;
	display: inline-block;
	zoom: 0.7;
}

.theme__based__no__data__icon {
	background-image: var(--iconUrl);
	background-position: -86px -686px;
	background-repeat: no-repeat;
	width: 33px;
	height: 33px;
	zoom: 1.1;
}

.smartpage {
	flex-wrap: wrap;
	width: 100%;
	padding-left: 2rem !important;
	padding-right: 1rem !important;
	/* padding-top: 1rem !important; */
}

.smartpage .title-header h3{
	font-size: 18px;
	font-weight: 600;
}

.smartpage .si-tabs .si-item {
	padding: 0px 18px;
	margin-bottom: 7px;
}

.smartpage .si-tabs .si-item:first-child {
	padding-left: 0px;
}

.smartpage .si-tabs .si-item .si-link {
	padding: 5px 0px;
	font-size: 14px;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	letter-spacing: 0.3px;
}
.smartpage .si-tabs .si-item.sk-intelligence-main-tab{
	background-color: #FFFFFF99;
	padding: 0px;
	margin-right: 20px;
	margin-bottom: 20px;
	border-radius: 2px;
	box-shadow: 4px 4px 10px #0000000f;
}
.smartpage .si-tabs .si-item.sk-intelligence-main-tab .si-link{
	padding: 10px;
	border-radius: 3px;
	color: #000;
	font-size: 14px;
	display: flex;
	align-items: center;
}
.smartpage .si-tabs .si-item.sk-intelligence-main-tab .si-link img{
	margin-right: 10px;
}
.smartpage .si-tabs .si-item.sk-intelligence-main-tab.active-main-tab{
	background-color: #BE87FF33;
}

.smartpage .si-tabs .si-item .si-link.activeTab{
	border-bottom: 2px solid var(--secondary-color) !important;
	color: var(--secondary-color) !important;
}
.smartpage .si-tabs .si-item .si-link.activeBlueTab{
	border-top: 2px solid #7327CC !important;
	color: #000 !important;

}

.smart__konversations__dashboard .smart__konversations__collaborators__container-wrap {
	display: inline-flex;
	align-items: center;
}

.smart__konversations__collaborators__container-collaborator {
cursor: default !important;
}

.smart__konversations__collaborators__container-collaborator-wrap .show_list_items_abs_click {
overflow: auto;
max-height: 200px;
}

.smart__konversations__collaborator__cell {
width: 140px !important;
min-width: 140px !important;
max-width: 140px !important;
}

.smart__konversations__collaborators__container-collaborator-wrap.spTaskActions {
left: -20px !important;
}

.spTaskActions.skActions.smart__konversations__collaborators__container-collaborator-wrap .show_list_items_abs_click .show_list_items_abs_menus ul li a {
cursor: default;
}

.touchpoint__intelligence .smart__konversations__collaborators__container-wrap {
justify-content: center;
}

.smart__konversations__collaborators__container {
/* align-items: flex-start; */
display: flex;
margin: auto;
width: 100%;
align-items: center;
justify-content: left;
margin-left: 10px;
}

.smart__konversations__collaborators__container:hover {
cursor: pointer;
}

.smart__konversations__collaborator__name__wrap {
border-radius: 50%;
background-color: var(--smart-konversations-label-wrap-color);
min-width: 1.688rem;
height: 1.688rem;
display: flex;
align-items: center;
justify-content: center;
}

.playbook_summary_listing_grid_table__conatiner .smart__konversations__collaborator__name__wrap {
margin-right: 7px;
}

.smart__konversations__collaborator__name__wrap-name {
color: white;
text-transform: uppercase;
}

.touchpoint__intelligence .smart__konversations__collaborator__name__wrap {
width: 1.808rem;
height: 1.808rem;
/* width: 2rem;
height: 2rem; */
zoom: 0.8;
}

.smart__konversations__collaborators__container-wrap span {
font-family: var(--sk-font-urbanist-family), Bold !important;
font-weight: 400;
font-size: 13px;
align-items: center;
display: flex;
margin-left: 3px;
}

.smart__konversation__collaborator__profile__image {
width: 1.688rem;
height: 1.688rem;
border-radius: 50%;
}



.smart__konversations__collaborator__cell .smart__konversations__collaborators__container--wrap {
margin-left: 10px !important;
margin-right: 5px !important;
}

.sortWrap {
	/* display: flex; */
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-family: var(--sk-font-urbanist-family), Medium;
	font-size: 0.80rem;
	justify-content: center;
	/* font-weight: 600; */
	font-weight: 500 !important;
	letter-spacing: 0.3px;
}
.skConfirmModalWrap.popUpWidthSPT.playbook_template_confirmation_popup_dialog_class {
	width: 450px !important;
}

.hs-accImpected-popup .smart_modal_container {
	min-width: 350px;
}

.creator_column_red_text {
	color: red !important;
	opacity: 0.85;
}

.reducing_width_to_90_percent {
	width: 90%;
	margin-left: 10%;
}

.org__name {
	font-weight: 400;
	font-family: var(--sk-font-sans);
	color: #FAFAFA;
	font-size: 15.5px;
	letter-spacing: 0.9px;
}

.tab__name {
	font-weight: 500;
	font-family: var(--sk-font-sans);
	color: #FAFAFA;
	font-size: 15.5px;
}
.HWSelectMultiSelectTooltip .tooltip-inner {
	z-index: 2;
	position: relative;
}

.smart__data__grid__sort .smart__konversations__call__intelligence__sort__icon__container-icon {
	width: 2.25rem !important;
}

.smart__data__grid__sort .popover-arrow {
	display: none;
}
.smart__data__grid__sort.smart__konversations__call__intelligence__sort__icon__wrap_popper{
	top: -2.9rem !important;
	max-height: 30px;
	overflow: hidden;
	max-width: 30px;
}

.smart__data__grid__sort.smart__konversations__call__intelligence__sort__icon__wrap_popper.firstCol.smart__conact__grid__sort{
	top: -2.9rem !important;
	margin-bottom: auto !important;
	margin-left: auto !important;
}

.task__list_view__table-container .datagrid__table__container {
	margin-top: 20px;
}
.task__list_view__table-container .noDataForEvent{
	margin-top: 20px;
}

.smart__account__360__view__graph__detail__wrap {
	height: 100%;
	width: 100%;
	display: flex;
	background-color: white;
	flex-direction: column;
	border-radius: 4px;
}

.smart__account__360__view__graph__detail__wrap .highcharts-container {
	border-radius: 4px;
}

.smart__account__360__view__wrap-header {
	height: 15%;
	width: 100%;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
}

.smart__account__360__view__graph__detail__wrap-content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 4px;
}

.smart__account__360__view__wrap-header-text {
	font-size: 0.875rem;
	color: var(--sk-heading-color);
	letter-spacing: 0.36px;
	font-weight: 500;
	width: 60%;
	height: 100%;
	display: flex;
	align-items: center;
}

.smart__account__260__no__data__screen__wrap {
	width: 100%;
	background: white;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 4px;
}

.smart__account__260__no__data__screen {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.smart__account__260__no__data__screen .no-activity {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.smart__account__260__no__data__screen .no-data-text {
	margin-top: 5px !important;
	font-size: 12px !important;
}
.smart__konversation__call__intelligence{
	color: var(--secondary-color);
	font-weight: 600;
}
.smart__konversation__call__category{
	color: var(--secondary-color);
	font-weight: 600;
}

.expandable__list__container {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;
	border-radius: 12px;
	border-radius: 4px;
	border-bottom: 1px solid #69697605;
	position: relative;
}

.datagrid__table .access_tabel_content {
	justify-content: flex-start;
	padding: 0px;
	color: var(--smart-insights-and-konversations-intelligence-heading-color) ;
}

.expandable__list__container .MuiDataGrid-columnHeaders {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.expandable__list__container .datagrid__table__container .MuiDataGrid-virtualScroller {
	box-shadow: 0px 3px 10px var(--sk-sub-tab-border-color);
}

.expandable__list__title__container {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	background-color: var(--primary-color);
	border: 1px solid var(--expand-tabele-border-color);
	border-bottom: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	height: 44px;
}

.expandable__list__title__container-icon {
	margin-left: 0.625rem;
	margin-top: 0.438rem;
	width: 4%;
}

.expandable__list__title__icon {
	padding-left: 10px;
	background-image: var(--iconUrl);
	background-position: -383px -59px;
	min-width: 26px;
	min-height: 26px;
	left: 0;
	top: 0;
	rotate: -90deg;
	cursor: pointer;
	zoom: 0.75;
}

.expandable__list__title__icon-rotate {
	padding-left: 10px;
	background-image: var(--iconUrl);
	background-position: -383px -59px;
	min-width: 26px;
	height: 26px;
	zoom: 0.75;
	left: 0;
	top: 0;
	cursor: pointer;
}

.expandable__list__title__text {
	width: 90%;
	margin-left: 10px;
	/* margin-top: 0.625rem; */
}

.expandable__list__title__text p {
	font-size: 15px !important;
	font-family: var(--sk-font-urbanist-family);
	color: var(--sk-heading-color);
	font-weight: 600;
}

.task__view__table .editTitle{
	padding: 0px;
	height: 100%;
	padding-left: 5px;
	padding-top: 2px;
}
.editTitle{
	padding: 5px;
	width: 100%;
	font-size: 14px;
}
.editTitleBorder{
	border: 1px solid var(--sk-border-color);
	padding-left: 4px;
}
.openTitle{
	color:var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 400;
}

.SPTDuplicateModal .integration_messagesForAppIntegration {
	color: #000000;
	font-size: 15px !important;
	font-weight: 400 !important;
	padding: 7px 0px 5px 0px;
	line-height: 21px;
	display: flex;
	margin: auto;
  text-align: center;
}
.alertPoptouchPointModal {
	background: rgba(0, 0, 0, 0.5) !important;
	opacity: 2.7 !important;
}

.popUpWidthSPT .modal-content {
	border-radius: 4px !important;
	box-shadow: none;
	border: 1px solid #dfe1e3;
	/* right: 60px; */
}
.acctount_listing_utilization--container.smart-modal-popup .modal-content {
	width: 100%;
}
.sk_cutomer_account_listing--container .datagrid__table__container {
	margin-top: 15px;
}

.smart__konversations__call__label {
	color: var(--smart-konversations-label-color) !important;
	font-family: var(--sk-font-urbanist-family), Bold;
	font-weight: 600;
	cursor: default;
	margin-left: 3px;
}

.name {
	color: var(--sk-smart-datepicker-border-color) !important;
	font-family: var(--sk-font-urbanist-family), Bold !important;
	font-weight: 600 !important;
}

.account_segment_summary--container .Mui-selected .MuiDataGrid-cell:first-child {
	padding-right: 10px !important;
}

.sk_smart__back__btn__container-icon {
	position: absolute;
	background: var(--iconUrl);
	background-position: -16px -274px;
	width: 42px;
	height: 42px;
	cursor: pointer;
	zoom: 0.7;
}

.subtask_icon{
	width: 14px;
	height: 14px;
	cursor: pointer;
	background-image: var(--iconUrl);
	background-position: -676px -66px !important;
	zoom: 0.9;
	margin-left: 0px;
}

.subtask_icon.subtask_icon-selected {
	background-position: -652px -66px !important;
}

.subtask_icon_disabled {
	width: 14px;
	height: 14px;
	background-image: var(--iconUrl);
	zoom: 0.9;
	margin-left: 0px;
	background-position: -624px -66px !important;
}

.smart__contact__three__dot__menu.spTaskActions {
	left: -120px !important;
	/* top: -127px !important; */
}
.smart__contact__three__dot__menu.spTaskActions.smart__categories__three__dot__menu{
	left:-50px !important;
}

.smart__contact__three__dot__menu .three__dot__menu__name {
	margin-left: 1.875rem;
	font-weight: 400;
	font-size: 15.3px;
	letter-spacing: 0.42px;
	font-family: var(--sk-font-urbanist-family), Bold;
}
.sk_smart_three_dots_menu{
	font-weight: 400;
	font-size: 15.3px;
	letter-spacing: 0.42px;
	font-family: var(--sk-font-urbanist-family), Bold;
}
.sk_smart_three_dots_menu .show_list_items_abs_menus {
	/* height: 4.688rem; */
	min-width: 7.313rem;
	/* min-width: 8rem !important; */
	overflow-y: hidden;
	border-radius: 0px 0px 6px 6px !important;
}
.sk_smart_three_dots_menu .three__dot__menu__name {
	margin-left: 1.875rem;
	font-weight: 400;
	font-size: 15.3px;
	letter-spacing: 0.42px;
	font-family: var(--sk-font-urbanist-family), Bold;
}

.smart__contact__three__dot__menu .show_list_items_abs_click .show_list_items_abs_menus {
	/* height: 4.688rem; */
	min-width: 7.313rem;
	/* min-width: 8rem !important; */
	overflow-y: hidden;
	border-radius: 0px 0px 6px 6px !important;
}
.sk_smart_three_dots_menu .show_list_items_abs_click .show_list_items_abs_menus ul li {
	padding: 0.438rem 0.563rem;
}

.smart__contact__three__dot__menu  .show_list_items_abs_click .show_list_items_abs_menus ul li {
	padding: 0.438rem 0.563rem;
}

.task_notification_on_icon{
	background: var(--iconUrl);
	background-position: -634px -5px;
	height: 30px;
	width: 30px;
	min-width: 30px;
	cursor: pointer;
}
.show_list_items_abs_menus .task_notification_on_icon,
.show_list_items_abs_menus .task_notification_off_icon{
	zoom:0.8;
}
.task_notification_off_icon {
	background: var(--iconUrl);
	background-position: -597px -5px;
	height: 30px;
	width: 30px;
	min-width: 30px;
	cursor: pointer;
}

.smart__contact__three__dot__menu .show_list_items_abs_click .show_list_items_abs_menus ul li {
	padding: 0.438rem 0.563rem !important;
}

.custom__column__cell_datepicker_overlay {
	z-index: 9999;
}

.a36_view_more_popup--conatiner{
	min-width: 43rem;
	min-height: 200px;	
}

.smart__konversations__container .sortWrap {
	display: flex;
}
.playbook_container_template_container .sortWrap {
	display: flex;
}

.playbook__analytics__header .sk_toggle--container .active_toggle {
	background-color: var(--template-toggle-bg-color) !important;
	color: var(--primary-color) !important;
	border: 1.5px solid var(--template-toggle-bg-color) !important;
}

.contact__edit__icon {
	position: absolute;
	background: var(--iconUrl);
	background-position: -394px -956px;
	width: 30px;
	height: 30px;
	zoom: 0.8;
	cursor: pointer;
}

.datagrid__table__container .MuiDataGrid-virtualScroller {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	overflow-y: scroll !important;
	overflow-x: auto;
}

.playbookContainer {
	display: flex;
	flex-direction: column;
	border-radius: 0;
	max-height: 686px;
	width: 812px;
	top: 28px;
}

.sk_cutomer_account_listing--container .noDataForEvent {
	margin-top: 15px;
}

.smart__konversations__call__intelligence__sort__icon__container-icon-rotate {

	transform: rotate(180deg) !important;

}
.expansionOppertunitySorticon .smart__konversations__call__intelligence__sort__icon__container-icon-rotate{
	margin-right: -2px;

}

.smart__konversations__call__intelligence__sort__icon__container-icon-rotate-prev {
	transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}

.account_list_custom_churn_reason_component {
	color: var(--secondary-color);
	font-weight: 600;
}

.account_list_custom_churn_reason_component .smart__konversations__collaborators_name {
	max-width: 100%;
}

.custom_datepicker_time_container {
	height: 44px; 
	width: 237px; 
	margin-left: -120px;
	margin-top: -5px;
	margin-bottom: 3px;
	background-color: var(--global-filter-Atributes-bg); 
	border: 1px solid var(--sk-border-color);
	border-top: 0px;
	border-radius: 0.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom_datePicker_time_wrap {
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom_datepicker_time_container .custom_time_container .smart__input__btn-container .smart__input__btn {
	padding: 0 8px;
}

.custom_datepicker_time_label {
	color: var(--sk-datepicker-month-color);
	font-size: 14px;
} 

.custom_datepicker_time_container .custom_time_container {
	height: 100%;
	display: flex;
	width: 120px;
}

.custom_datepicker_time_container .custom_time_container .smart__input__btn {
	padding: 7px;
}

.custom_datepicker_time_container .custom_time_container .smart__input__btn_up_arrow, .custom_datepicker_time_container .custom_time_container .smart__input__btn_arrow {
	zoom: 0.8;
}

/* .custom_datepicker_time_container .custom_time_container .smart__input__btn_up_arrow {
	background-position: -511px -575px;
} */

.custom_datepicker_time_container .custom_time_container input {
	font-size: 12px;
	font-weight: 500;
}

.custom_datepicker_time_zone_wrap {
	margin-left: 2px;
	font-size: 11px;
}

.skActions {
	z-index: 9999;
}