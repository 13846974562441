.smart__input__btn {
    width: 100%;
    border-radius: 4px;
    padding: 1rem;
    border: 1px solid var(--sk-border-color);
    font-family: var(--sk-font-urbanist-family);
    font-size: 0.904rem;
    color: var(--sk-black-text-color);
}

.smart__input__btn .sk__input__error_msg {
    background-color: #ffe4e7 !important;
    font-weight: 400 !important;
    border: 1px solid red !important;
    color: #000;
}
.sk__input__error_msg {
	background-color: #ffe4e7 !important;
	font-weight: 400 !important;
	border-bottom: red 1px solid !important;
	color: #000;
}

.smart__input__btn .sk__input__error_msg::placeholder {
    color: #000 !important;
}

.smart__input__btn_icon_container {
	padding-right: 10px;
}

.smart__input__btn .error__border__bottom {
    border-bottom: red 1px solid !important;
}
.error__border__bottom {
	border-bottom: red 1px solid !important;
}

.smart__input__btn:focus{
	border: 1px solid var(--sk-border-color)
}
.smart__input__btn_arrow{
	display: block;
	width: 20px;
	height: 20px;
	background: var(--iconUrl);
	background-position: -518px -573px;
	cursor: pointer;
}
.smart__input__btn_up_arrow{
	display: block;
	width: 20px;
	height: 20px;
	background: var(--iconUrl);
	background-position: -510px -575px;
	transform: rotate(180deg);
	cursor: pointer;
}

.smart__input__btn-container{
	display: flex;
	position: relative;
	align-items: center;
	height: 40px;
}
.playbook_create_edit_template__container .smart__input__btn-container {
	height: 43px;
}

.smart__input__btn-text{
	display: flex;
	position: relative;
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-family: var(--sk-font-urbanist-family), Medium;
}


.smart__input__btn-percentage-icon{
	position: absolute;
	right: 18px;
}

.search__icon__container {
	cursor: pointer;
	position: relative;
	/* margin-right: -15px;
	right: 15px; */
	width: 26px;
	height: 29px;
	border-radius: 0 50px 50px 0;
	padding-top: 5px;
}

.smart__input___search__icon{
	width: 25px;
	height: 25px;
	background-image: var(--iconUrl);
	cursor: pointer;
	overflow: hidden;
	position: absolute;
	background-position: -673px -958px;
	display: flex;
	zoom: 0.8;
}

.smart__input__btn-container .smart__input__btn{
	padding: 9px 12px;
}

.input__btn__calendar__icon__container {
	width: 25px;
	height: 25px;
	position: relative;
}
.input__btn__calendar__icon__container:hover {
cursor: pointer;
}

.input__btn__calendar__icon__container .calendar__icon {
	background: var(--iconUrl);
	width: 25px;
	height: 25px;
	position: absolute;
	background-position: -401px -158px;
	zoom: 0.9;
	margin-top: 2px;
}
/* Chrome, Safari, Edge, Opera */
input.smart__input__btn::-webkit-outer-spin-button,
input.smart__input__btn::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.smart__input__btn[type=number] {
  -moz-appearance: textfield;
}