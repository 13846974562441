.joyride__secondary__btn{
	padding: 6px 8px;
	font-family: var(--sk-font-urbanist-family);
	background-color: var(--primary-color);
	min-width: 6rem;
	border-radius: 3rem;
	color: var(--secondary-color);
	font-size: 13px;
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	border: 1px solid var(--secondary-color);
	letter-spacing: 1.5px;
	margin-right: -12px;
}

.joyride__primary__btn{
	padding: 8px;
	font-family: var(--sk-font-urbanist-family);
	background-color: var(--secondary-color);
	min-width: 6rem;
	border-radius: 2rem;
	color: var(--primary-color);
	font-size: 13px;
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	border: 1px solid var(--secondary-color);
	letter-spacing: 1.5px;
	display: flex;
  justify-content: center;
	align-items: center;
}

.joyride__progress{
	font-size: 11.5px;
    margin-left: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.joyride__first__step{
	font-size: 25px;
  font-weight: 500;
}

.joyride__first__step__line{
	font-size: 14px;
  font-weight: 500;
	margin-left: 20px;
}

.react-joyride__tooltip h4{
	color: var(--secondary-color);
	font-weight: 600;
  font-size: 20px;
} 

.react-joyride__tooltip{
	font-size: 14px !important;
	/* width: 300px !important; */
	padding: 5px !important;
}

#react-joyride-step-2 .react-joyride__tooltip{
	width: 355px !important;
}

#react-joyride-step-7 .react-joyride__tooltip{
	width: 355px !important;
}

#react-joyride-step-6 .react-joyride__tooltip{
	width: 355px !important;
}

#react-joyride-step-5 .react-joyride__tooltip{
	width: 355px !important;
}

.react-joyride__spotlight{
	max-height: 120px !important;
}


#react-joyride-step-1 .__floater__open{
	/* top: -110px !important; */
}

.joyride__content{
	margin: -10px;
}