.smart__comments__container {
	width: 100%;
	/* height: auto; */
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 22px;
	padding-bottom: 22px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	max-height: 100%;
}


.smart__comments__container__top__form {
	/* height: 10vh; */
	display: flex;
	width: 100%;
	padding-left: 1.375rem;
	padding-right: 1.375rem;
}

.smart__comments__profile__icon__container {
	width: 10%;
}

.smart__comment__form__editable__wrap {
	display: flex;
	width: 90%;
	flex-direction: column;
}

.smart__comment__form {
	margin-bottom: 1rem;
	opacity: 1;
	transition: none;
	width: 100%;
	display: flex;
	min-height: 43px;
	max-height: 300px;
	border: 1px solid var(--sk-border-color);
	border-radius: 4px;
}

.smart__comments__profile__icon {
	width: 35px;
	height: 35px;
	border: 1px solid #dddddd2e;
	border-radius: 50%;
	margin-right: 20px;
	margin-top: 5px;
}

.smart__comments__input__container {
	min-height: 100%;
	padding: 0.4375rem 0.75rem;
	font-size: 14.5px;
	overflow-y: hidden;
	line-height: 1.5;
	color: #696976;
	font-family: var(--sk-font-urbanist-family);
	letter-spacing: 0.42px;
	background-color: #fff;
	font-weight: 300;
	will-change: border-color, box-shadow;
	border-radius: 0.25rem;
	box-shadow: none;
	transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
	border-radius: 6px !important;
	background-color: #80808005 !important;
	width: 85%;
}

.smart__comments__input__container__input {
	/* border: 1px solid var(--sk-border-color);
	border-radius: 4px; */
	overflow-wrap: break-word;
	white-space: break-spaces;
	/* white-space: pre; */
	padding: 10px;
	font-family: var(--sk-font-urbanist-family) !important;
	min-height: 50px;
	overflow-y: scroll !important;
	border: 0px;
}
.smart__comments__input__container__input::-webkit-scrollbar {
	width: 0px;
	background: transparent; /* make scrollbar transparent */
}



/* .smart__comments__input__container__input::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.smart__comments__input__container__input::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
	border: 4px solid var();
}

.smart__comments__input__container__input::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

.smart__comments__input__container__input::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 6px;
} */

.smart__comment__emoji__container {
	width: 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
}

.smart__comment__emoji__container-icon {
	cursor: pointer;
}

.smart__comments__input__container__suggestions {
	z-index: 2;
	margin-top: 30px !important;
}

.smart__comments__input__container__suggestions__list {
	background-color: #fff;
	overflow: auto;
}

.smart__comments__input__container__suggestions__list::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.smart__comments__input__container__suggestions__list::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
	border: 4px solid var();
}

.smart__comments__input__container__suggestions__list::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

.smart__comments__input__container__suggestions__list::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 6px;
}

/* Comment Listing styles start */
.smart__commments__container__bottom__list {
	height: 60vh;
	width: 100%;
	overflow-y: auto;
	margin-bottom: 7.5rem;
	display: flex;
	flex-direction: column;
}

.smart__commments__container__bottom__list::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.smart__commments__container__bottom__list::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
	border: 4px solid var();
}

.smart__commments__container__bottom__list::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

.smart__commments__container__bottom__list::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 6px;
}

.smart__comments__list__comment__box__wrap {
	display: flex;
	width: 100%;
	margin-bottom: 0.625rem;
	padding-left: 1.375rem;
	padding-right: 1.375rem;
}

.replied--comment .smart__comments__list__comment__box__wrap{
	padding-right: 0px;
}

.smart__comments__edit__comment__box__wrap {
	display: flex;
	width: 100%;
	margin-bottom: 2rem;
	/* padding-right: 0.625rem; */
	max-height: 100%;
}

.smart__comments__list__comment__box-profile-icon {
	width: 10%;
}

.smart__comments__list__comment__container {
	width: 90%;
	display: flex;
	flex-direction: column;
}

.smart__commments__container__bottom__list .smart__profile__icon {
	width: 35px;
	height: 35px;
	border: 1px solid #dddddd2e;
	border-radius: 50%;
	margin-right: 20px;
	margin-top: 5px;
}

.smart__comments__list__comment__box {
	width: 100%;
	background-color: var(--sia-chatbox-textbox-background-color);
	padding: 0.5rem;
	border-radius: 4px;
}

.smart__comments__container__footer {
	display: flex;
	margin-top: 0.3rem;
}

.smart__comments__container__footer-smiley {
	display: flex;
	border: none;
	background: white;
}


.smart__comments__container__footer .sk-smart-uiBtn_wrap {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	/* margin-right: 0.625rem; */
}

.smart__comments__container__footer .pl-10 {
	margin-left: 0.600rem;
}

.smart__comments__list__comment__box-header {
	display: flex;
	width: 92%;
	justify-content: space-between;
}

.smart__comments__list__comment__box-header-label {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 0.904rem;
	font-weight: 500;
	font-family: var(--sk-font-urbanist-family), SemiBold;
	text-align: left;
	display: flex;
	align-items: center;
}

.smart__comments__list__comment__box-commented-text {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 0.813rem;
	font-weight: 400;
	font-family: var(--sk-font-urbanist-family), Medium;
	width: 102%;
	justify-content: left;
	text-align: left;
	word-break: break-word;
	max-height: 100px;
	overflow: auto;
	padding-right: 15px;
}

.smart__comments__list__comment__box-commented-text .user-mention-highlight {
	color: var(--smart-konversations-label-color) !important;
	font-family: var(--sk-font-urbanist-family), Bold !important;
	font-weight: 600 !important;
}

.smart__comments__list__settings__icon__container {
	position: relative;
}

.smart__comments__list__settings__icon {
	position: absolute;
	/* background: var(--iconUrl);
	background-position: -476px -448px; */
	width: 35px;
	height: 20px;
	transform: rotate(90deg);
	cursor: pointer;
}

.smart__comments__list__footer-time {
	color: var(--sk-comment-time-color);
	font-family: var(--sk-font-urbanist-family), SemiBold;
	font-size: 0.723rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 5px;
}


.smart__comments__list__comment__box__footer {
	display: flex;
	align-items: center;
}

/* Comment Listing styles end */

/* Edit Delete Popper Style starts */
.smart__comments__settings__popper {
	width: 100px;
}

.comment__list__settings_container {
	display: flex;
	flex-direction: column;
}

.comment__list__settings {
	padding: 8px 6px 5px 9px;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 15px;
	overflow: hidden;
	font-family: var(--sk-font-urbanist-family);
	cursor: pointer;
}

/* Edit Delete Popper Style ends */

 #desc_area_popup::placeholder {
	color: #696976;
	letter-spacing: 0.24px;
	/* font-weight: 500; */
}

.smart__comments__input__container__highlighter {
	max-height: 10rem;
	/* overflow-wrap: anywhere !important; */
}

.smart__comments__container__footer .skSecondaryBtn{
	margin-right: 10px !important;
}

.smart__comments__container .smart__comments__profile__icon{
    margin-right: 0px;
} 

.smart__comments__container .smart__react__select__profile__icon__wrap{
	margin-left: 0px;
}

.smart-reply-text{
	margin: 0px 10px;
	cursor: pointer;
}

.smart_reply_editor .smart__comments__container__top__form{
   padding: 5px 0px
}

.replied--comment{
	padding: 0px 20px 0px 73px;
}

.load--more-comment{
	width: fit-content;
	padding: 15px;
	cursor: pointer;
}

.load--more-comment--text{
	padding: 6px;
	background-color: var(--sk-border-color);
	border-radius: 5px;
	color: grey;
}

.comment-mention-send{
	background-color: var(--sk-dashboard-bg-img-2);
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 0px 3px 3px 0px;
		margin-left: 15px;
}

.comment-mention-send-active{
	background-color: var(--sk-dashboard-bg-img--colour-3);
	height: 100%;
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	border-radius: 0px 3px 3px 0px;
	margin-left: 15px;
	cursor: pointer;
}

.smart_reply_editor .smart__emoji__picker__wrap-inside{
	margin-left: 0px;
}