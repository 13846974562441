.SKInput.errorMsg {
	background-color: #ffe4e7 !important;
	font-weight: 400 !important;
	border: 1px solid red !important;
	color: #000;
}

.SKInput.errorMsg::placeholder {
	color: #59686B !important;
}
.SKInput.errorMsg::placeholder {
	color: #59686B!important;
}

.smartmodal {
	padding: 10px;
}

.smartmodal p {
	padding: 0px !important;
}


.skinputControl {
	height: auto;
	padding: 0.4375rem 0.75rem;
	font-size: 0.8125rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #e1e5eb;
	font-weight: 300;
	will-change: border-color, box-shadow;
	border-radius: 0.25rem;
	box-shadow: none;
	transition: box-shadow 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), border 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}



.HWSelectMultiSelectTooltip#UiLabelTooltip.tooltip {
	z-index: 9999999999 !important;
}

.HWSelectMultiSelectTooltip#UiLabelTooltip.tooltip.heat-map-side-text-tooltip {
	left: -6px !important;
}



.HWSelectMultiSelectTooltip#UiLabelTooltip.tooltip.tooltiptouchpoint {
	margin-top: 0px !important;
}


#UiLabelTooltip.successPlanLableTooltip .tooltip-inner {
	max-width: 400px;
}

#UiLabelTooltip .tooltip-inner li {
	text-align: left;
}



.user-segment-details .selectOptions>div:nth-of-type(1) {
	border-bottom: transparent 1px solid;
}




.SKItemList .list-group-item {
	font-size: 13px !important;
	font-weight: 300;
	border-bottom: 1px solid #dee2e6b5;
	cursor: default;
}

.SKItemList .list-group-item h3 {
	font-size: 13px !important;
	font-weight: 300;
	text-align: left;
}

.SKItemList .text-orange {
	color: #fc7242 !important;
	font-weight: 500 !important;
	cursor: pointer !important;
}

.sk-modal .btnDisabled {
	opacity: 0.5;
	cursor: default;
}

.sk-dash {
	display: inline-block;
}

.sk-btnDisabled {
	opacity: 0.5 !important;
	cursor: default !important;
}

.btn-disabled {
	opacity: 0.5;
	cursor: default !important;
}

.filterPopMsg {
	margin-top: 0px;
	/* z-index: 10004; */
	position: absolute !important;
	top: -48px !important;
}

.portofolioDropdownWrap .skSelectTooltip {
	top: -15px !important;
}

.sk-disable-btn {
	opacity: 0.5;
	cursor: default !important;
}

.sk-radio-btn {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
}

.sk-radio-inline {
	display: flex;
}

.sk-radio-inline .sk-radio-btn:nth-child(1) {
	margin-right: 1.2rem;
}

.sk-radio-btn input[type="radio"] {
	width: 13px;
	height: 13px;
	border-radius: 50%;
	outline: none;
	border: 1px solid gray;
	margin-right: 8px;
}

.ok__btn__label {
	background: var(--confirm-btn-color) !important;
	color: #fff !important;
}

.single__okay__button__container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.single__okay__button__container .ok__btn__label {
	margin-bottom: 5px !important;
}

.skConfirmModalWrap.popUpWidthSPT {
	width: 360px !important;
	margin: 15% auto !important;
}


.skConfirmModalWrap .modal-content .modal-header {
	padding: 12px 22px 12px 20px !important;
	margin-bottom: 0px !important;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	line-height: 15px;
}

.skConfirmModalWrap .close_btn_pop {
	margin: 0;
}

.customerAccountDetailsLabel .set-permission,
.assignedAccountLists .set-permission {
	text-decoration: underline !important;
	color: var(--secondary-color) !important;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
}

.sk-radio-btn input[type="radio"]:before {
	width: 13px;
	height: 13px;
	content: "";
	display: block;
	margin: 20% auto;
	border-radius: 50%;
}

.popUpWidthSPT {
	width: 540px !important;
	margin: 15% auto !important;
}



.sk-radio-btn input[type="radio"]:checked {
	border: 1px solid gray;
}

.sk-radio-btn input[type="radio"]:checked:before {
	content: "";
	display: block;
	width: 7px;
	height: 7px;
	margin: 11% auto;
	border-radius: 50%;
	background: gray !important;
	border: gray !important;
	margin-top: 2px;
}

.sk-radio-btn label {
	margin-bottom: 0;
	padding: 0;
	display: inline-block;
	font-weight: 400 !important;
	font-size: 13px !important;
	color: #5a6169 !important;
	cursor: pointer;
}

.sk-seprator {
	/* border-bottom: 1px solid #e1e5eb !important; */
	/*  margin-top: 2.5rem;
  margin-bottom: 2rem; */
	margin-top: 0;
	margin-bottom: 0;
}

.skForm .sk-form-item {
	margin-top: 1.562rem;
	align-items: flex-start;
}

.skForm .sk-form-item.vcenter {
	align-items: center;
}

.skForm .sk-form-item.vcenter .firstCol span {
	align-items: center;
	padding-top: 0;
}

.skForm .sk-form-item .firstCol span {
	padding-top: 10px;
	display: inline-block;
}

.skForm .sk-form-item:first-child {
	margin-top: 0;
}

.skForm .UiToggleBtn.sk_toggle_wrap {
	align-items: center;
}

/* .sk-radio-btn .form-check input {
    padding: 0;
    margin: 0 0 0 -20px;
} */

.sk-sticky-table .th {
	font-weight: 500;
	letter-spacing: 0.3px;
	color: black;
	background: #f7f7f7;
	vertical-align: middle;
}

.sk-sticky-table .initial-cell {
	padding-left: 17px !important;
}

.sk-sticky-table .th,
.sk-sticky-table .td {
	padding: 0.75rem;
	text-align: left;
}

.sk-sticky-table .td {
	border-bottom: 1px solid #efefef !important;
	font-size: 13px;
	font-weight: 300;
	color: black;
}

.sk-sticky-table .text-align {
	text-align: center !important;
}

.sk-sticky-table .td .acm_actions {
	text-align: center;
}

.sk-sticky-table .acm_actions .sk_btn {
	border: none !important;
	margin: 5px 8px !important;
	display: block;
	padding: 1px;
}

.sk-sticky-table .sk_btn img {
	width: 13px;
	height: 15px;
}

.smartmodal .confirm__icon {
	width: 6.25rem;
	height: 2.25rem;
	background-image: var(--iconUrl);
	cursor: pointer;
	overflow: hidden;
	background-position: -528px -749px;
	display: flex;
}

.sk-sticky-table .UiToggleBtn.sk_toggle_wrap {
	justify-content: center;
}

.sk-sticky-table .th .sortarrow {
	width: 13px;
	margin-left: 5px;

	height: 12px;
	opacity: 1 !important;
}

.hideSelectChk>div:nth-child(2)>div>div:nth-of-type(1) .check-box-wrap {
	display: none !important;
}

/* SK Table start */

.sk-table {}

/* SK Table end */

/* autosearch*/

ul.suggestions {
	max-height: 200px;
	width: 211px !important;
	overflow: auto;
	position: absolute;
	z-index: 9;
	border-right: 1px solid #e5e5e8;
	border-bottom: 1px solid #e5e5e8;
	border-left: 1px solid #e5e5e8;
}

.suggestions li {
	background-color: #fff;
	padding: 4px 8px !important;
	cursor: default;
	font-size: 12px;
	font-weight: 400;
	line-height: 17px;
}

.suggestions li:hover {
	background-color: #e5e5e8 !important;
}

.filterInput {
	border: 1px solid #e5e5e8 !important;
	padding: 0px 8px;
	min-height: 30px;
}

.activeLabel {
	background: #f9d192;
	color: #000000;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 0.2px;
	line-height: 1.4rem;
	width: max-content;
	padding: 2px 10px;
	margin: auto;
	border-radius: 4px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.userTablePopup {
	max-width: 90% !important;
	margin-left: 7.5% !important;
}

.userTableModal.sk-modal .sk-pop-wrapper {
	padding: 0;
	min-height: 300px;
	display: flex;
	align-items: center;
}

.userTableModal.sk-modal .noDataForEvent .filter-section {
	border-bottom: 0;
}

.userTableModal.sk-modal .modal-footer {
	margin: 0;
}

.userTableModal .iconBtn {
	max-width: 14px;
	max-height: 14px;
	color: #fff;
	border-radius: 2px;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
}

.userTableModal .actionWrape {
	display: flex;
	justify-content: center;
}

.userTableModal .active,
.plusBtn {
	background-color: #ffb13d;
}

.userTableModal .inactive,
.minusBtn {
	background-color: #c6c6c6;
}

.userTableModal.modal-header {
	padding-left: 8px;
}

.userTableModal .sk-disabled-row .td {
	color: #828384;
}

.userTableModal .w5 {
	width: 5%;
}

.userTableModal .w10 {
	width: 10%;
}

.userTableModal .w15 {
	width: 15%;
}

.userTableModal .w20 {
	width: 20%;
}

.sk-btn-gray {
	position: relative;
	overflow: hidden;
	color: #000000 !important;
	background-color: #fdfdfd;
	border: 1px solid #e5e5e8;
	border-radius: 5px;
	font-size: 11px !important;
	display: inline-block;
	cursor: pointer;
	padding: 4px 15px 4px 15px;
	font-weight: 400 !important;
}

.sk-primary-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 3px;
	height: 34px;
	font-size: 11px;
	font-weight: 400;
	color: #000000;
	background-color: #fdfdfd;
	border: 1px solid #e5e5e8;
	margin-right: 20px;
	min-width: 140px;
}

.sk-btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	/* border-radius: 3px; */
	height: 34px;
	font-size: 13px;
	font-weight: 400;
	color: #000000;
	background-color: #fdfdfd;
	border: 1px solid #e5e5e8;
	margin-right: 20px;
	min-width: 105px;

}

.skBtnWrap {
	justify-content: space-between;
}

.noDataTxt {
	text-align: center;
	padding: 25px 0 45px 0;
	width: 100%;
	line-height: 24px
}

.noDataTxt div {
	position: absolute;
	width: 100%;
	text-align: center;
}

.orangeBtn {
	cursor: pointer;
	background: #ffb13d;
	color: #fff;
	border: none;
}

.sk-hidden-btn {
	cursor: default;
	border-radius: 3px;
	height: 34px;
	font-size: 11px;
	font-weight: 400;
	color: #000000;
	background-color: transparent;
	border: 0;
	margin-right: 20px;
	min-width: 140px;
}

.userManagement .header__headerIcon .platform-btn-wrap button:last-child {
	margin-right: 0;
}

.sk-primary-btn img {
	margin-right: 4px;
}

.excludeIcon {
	font-size: 20px;
	margin-right: 4px;
}

.sk-hidden-btn {}

.userTablePopup .assignCSMModal .settingsStickyTable .th {
	background: #fafafa !important;
	/* border-top: 1px solid #dee2e6; */
	border-bottom: 0;
}

.userTablePopup .assignCSMModal .userCount {
	text-align: left;
	color: #000;
	font-size: 13px !important;
	font-weight: 400;
	/* white-space: nowrap */
	width: auto
}

.userTableModalNew .filter__section .vr-line {
	width: 2px;
	background-color: #707070 !important;
	height: 25px;
	margin-top: 0;
	margin-right: 5px;
	margin-left: 7px;
}

.userTableModalNew .filter__section .filter-tag {
	background: #f8f8f8 !important;
}

.userTablePopup .assign,
.userTablePopup .unAssign {
	cursor: pointer;
}

.userTablePopup p.assign__heading {
	margin-bottom: 1.2rem !important;
	padding: 0 15px;
}

.userTablePopup .userTable {
	/* margin-top:1rem; */
	min-height: 15.625rem;
}

.userTablePopup .skCheckInput:checked+label:after {
	/* left: 13px !important; */
}

.userTablePopup .userTable .settingsStickyTable .td {
	padding: 0.5rem 0.75rem !important;
}

.userTablePopup .userHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px;
	border-bottom: 1px solid #efefef !important;
	padding-bottom: 1rem;
}

.userTablePopup .search {
	display: flex;
	margin-right: 1.2rem;
	position: relative;
}

.userTableModalNew .search {
	display: flex;
	margin-right: 0.5rem !important;
	position: relative;
}

.userTableModalNew .cust-dropsown {
	margin-right: 1.2rem;
	margin-top: -3px;
}

.noDataDash {
	font-weight: 400;
	font-size: 13px !important;
	color: #000;
	/* text-align: center; */
}

.noDataWrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}

.noDataWrapper .noDataForEvent .filter-section {
	border-bottom: none;
}

.userTablePopup .searchIcon {
	cursor: default;
	position: absolute;
	margin-right: -15px;
	right: 15px;
	width: 26px;
	height: 29px;
	border-radius: 0 50px 50px 0;
	padding-top: 5px;
}

.userTablePopup .searchIcon img {
	cursor: pointer;
}

.userTablePopup .sk_icon {
	display: inline-block;
	border: 1px solid #c0c0c0;
	position: absolute;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0 none;
	background-position: -672px 0px !important;
	z-index: 2;
	text-indent: -100px;
	width: 32px;
	height: 32px;
	overflow: hidden;
	transform: scale(0.5);
	pointer-events: none;
	cursor: pointer;
	outline: 0 none;
	right: -35px;
	top: -2px;
}

.userTablePopup .searchWrap {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.userTablePopup .searchWrap.ST-content-wrap {
	padding-top: 0px;
}

.userTablePopup .searchWrap input {
	border-radius: 50%;
	border-radius: 50px 50px 50px 50px !important;
	padding-right: 30px;
	padding-left: 12px;
}

.userTablePopup .settingsStickyTable {
	max-height: 250px;
}

.userTablePopup .minusIcon,
.userTablePopup .plusIcon {
	width: 14px;
}

.userTablePopup .userTable .actionRight {
	text-align: center !important;
}

.userTablePopup .userTable th.w5 {
	width: 5%;
}

.userTablePopup .userTable th.w20 {
	width: 20%;
}

.userTablePopup .userTable th.w10 {
	width: 10%;
}

/*and make the suggestion reappear when user focus on search field*/
/* .skAutoSearch input[type="search"]:focus + .suggestions li {
  height: 63px;
} */

/* .skAutoSearch .byline {
  text-align: center;
  font-size: 18px;
}
.skAutoSearch .byline a {
  text-decoration: none;
  color: #1f5350;
} */

.skTabNav.border-bottom-1 {
	/* border-bottom: 1px solid #eeeeee !important; */
}

.skTabNav .edit-parameters-btn {
	line-height: 3;
	text-align: center;
	text-decoration: none;
	margin-top: -5px;
}

.skTabNav .add__UserBtn {
	cursor: pointer;
	border-radius: 3px;
	height: 34px;
	font-size: 11px;
	font-weight: 400;
	color: #000000 !important;
	background-color: #fdfdfd;
	border: 1px solid #e5e5e8;
	margin-right: 9px;
	min-width: 140px;
}

.skTabNav .sk_btn {
	margin-left: 10px;
	display: inline-flex;
}

.inlineError {
	color: red;
	font-weight: 400 !important;
	font-size: 12px !important;
	position: absolute;
	width: 50%;
	display: block;
}

.disabledGrey,
.disabledGrey b {
	color: #e3e2e2 !important;
}

.disabledGrey .td span {
	color: #e3e2e2 !important;
}

.infoUnavailable {
	/* min-height: 75px; */
	color: #59686b !important;
	font-weight: 500;
}

.alertIconImg {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #c0c0c0;
	border-radius: 50px;
	margin-right: 30px;
}

.alertIconImg img.avatar_img {
	width: 30px !important;
	height: 30px !important;
	margin: 0 auto;
	border-radius: 0;
}

.alertIconImg.contListAltImg,
.roundCircleWrap .alertIconImg {
	width: 46px;
	height: 46px;
	margin-right: 0;
}

.alertIconImg.contListAltImg img.avatar_img {
	width: 22px !important;
	height: 22px !important;
}

/* // ticket dashboard */
._created_by .alertIconImg {
	width: 30px;
	height: 30px;
	margin-right: 0;
}

.keyContacts_card_wrapper .alertIconImg {
	width: 37px;
	height: 30px;
	margin-right: 0;
	margin-top: 6px;
}

._created_by .alertIconImg img.avatar_img,
.keyContacts_card_wrapper .alertIconImg img.avatar_img {
	width: 19px !important;
	height: 19px !important;
	margin-right: 0;
	margin-left: 0;
}

.tick-logs .alertIconImg {
	width: 22px;
	height: 22px;
	margin-right: 4px;
}

.tick-logs .alertIconImg img.avatar_img {
	width: 15px !important;
	height: 15px !important;
	margin-right: 0;
	margin-left: 0;
}

/* .roundCircleWrap .alertIconImg{
  width: 22px;
  height: 22px;
  margin-right: 4px;
} */

/* .roundCircleWrap .alertIconImg img.avatar_img{
  width: 15px!important;
  height: 15px!important;
  margin-right: 0;
  margin-left: 0;
} */

/* .sk_icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(../assets/images/icons/icons.svg);
}

.sk_icon_Icon-ionic-ios-close-circle-outline {
  width: 34px;
  height: 34px;
  background-position: -240px -192px;
}

.sk_btn i {
  zoom: 0.4;
  margin-right: 10px;
  position: relative;
  top: 3px;
  top: 6px;
} */

.skTabNav .nav-tabs .nav-item .nav-link.active {
	color: #fc7242 !important;

	font-family: "Urbanist", sans-serif;
	background-color: #fff;
	border-left: none !important;
	border-top: none !important;
	font-weight: 500 !important;
	border-right: none !important;
	border-color: #ddd !important;
	border-bottom: 2px solid #fc7242 !important;
	transition: none;
}

.skTabNav .sk-tab-list .nav-item .nav-link {
	padding: 18px;
	border: none !important;
	font-size: 15px !important;
	margin-right: 8px;
}

.skTabNav .sk-tab-list .nav-item .nav-link {
	padding: 18px;
	border: none !important;
}

.skTabNav .sk-tab-list .nav-tabs .nav-item .nav-link {
	color: #b2b2b2 !important;
	font-size: 15px;
	font-family: "Urbanist", sans-serif;
	background-color: #fff;
	font-weight: 500;
	/* border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  border-color: #ddd !important; */
	transition: none;
}

.skTabNav .sk-tab-list .nav-link {
	padding: 15px 0 10px 0 !important;
}

.skTabNav .sk-tab-list .nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0.375rem;
	border-top-right-radius: 0.375rem;
}

.skTabNav .sk-tab-list .nav-tabs .nav-item {
	margin-bottom: -1px;
}

.skTabNav ul {
	padding-left: 25px;
}

.skTabNav .sk-tab-list li {
	padding-right: 40px;
}

.healthScoreNav .sk-tab-list li {
	padding-right: 20px;
}

.skTabNav .edit-parameters-btn {
	line-height: 3;
	text-align: center;
	text-decoration: none;
	margin-top: -5px;
}

.skTabNav .header-icon-margin {
	margin-top: 7px;
}

.skTabNav .add__UserBtn a {
	text-decoration: none;
}

.skTabNav .show_edit_actions {
	position: relative;
	top: 5px;
	text-decoration: none !important;
}

.sk-search .sk_icon {
	position: relative;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 0 none;
	background-position: -672px 0px !important;
	z-index: 2;
	text-indent: -100px;
	width: 32px;
	height: 32px;
	overflow: hidden;
	transform: scale(0.42);
	pointer-events: none;
	cursor: pointer;
	outline: 0 none;
	left: 5px;
	top: 0px;
}

.sk-search .searchWrap {
	display: flex;
}

.sk-search .sk-closeBtn {
	position: absolute;
	top: 35%;
	z-index: 999;
	cursor: pointer;
}

.sk-smart_white_closeBtn {
	width: 30px;
	height: 25px;
	background-image: var(--iconUrl);
	background-position: -170px -960px;
	cursor: pointer;
	display: inline-block;
	right: 79px;
	top: 15px;
	position: absolute;
	z-index: 999;
}

.sk-search .searchWrap input.searchInput {
	background-color: #fff !important;
	width: 12.5rem !important;
	padding: 0 30px 0 15px;
	border: 1px solid #e5e5e8 !important;
	color: #000 !important;
}

.sk-search .onBlur {
	/* text-indent: -200px; */
}

.sk-list-slider {
	position: relative;
}

.sk-list-slider ul#platformTab {
	min-width: auto;
	list-style: none;
	padding-top: 0;
	margin-top: 0 !important;
	/* max-height: 43px;
  overflow: hidden; */
}

.sk-list-slider .sk-slider {
	margin: 0 auto;
	display: flex;
}

.sk-list-slider ul li:last-child {
	margin-right: 0;
}

.sk-list-slider .sk-slider .navigation {
	position: absolute;
	/*  z-index: 999; */
	width: 100%;
	left: 0;
	right: 0;
}

.sk-list-slider .sk-slider .arrowNavs {
	margin-top: 11px;
}

.sk-list-slider .sk-slider .arrowNavs .leftArrow,
.sk-list-slider .sk-slider .arrowNavs .rightArrow {
	cursor: pointer;
}

.sk-list-slider .sk-slider .arrowNavs {
	display: flex;
	justify-content: space-between;
}

.sk-list-slider .sk-slider .arrowNavs .leftArrow,
.sk-list-slider .sk-slider .arrowNavs .rightArrow {
	cursor: pointer;
}

.sk-list-slider .sk-slider .arrowNavs .leftArrow img,
.sk-list-slider .sk-slider .arrowNavs .rightArrow img {
	height: 55%;
}

.sk-list-slider ul#platformTab {
	min-width: auto;
	list-style: none;
	padding-top: 0;
	margin-top: 0 !important;
}

.sk-list-slider #platformTab {
	border-bottom: 0 !important;
}

.sk-list-slider ul#platformTab li {
	display: inline;
}

.sk-list-slider #platformTab li {
	padding-right: 20px;
	padding-bottom: 12px;
	padding-top: 5px;
}

.orangeLink {
	color: #fc7242;
	cursor: pointer;
}

.sk-list-slider #platformTab .platform-nav-link.active {
	background-color: #828384 !important;
	color: #fff !important;
	border-radius: 0;
	border-bottom: 0 !important;
	border: 1px solid #828384 !important;
}

.sk-list-slider #platformTab .platform-nav-link {
	border-color: #828384 !important;
	color: #828384 !important;
	border-radius: 0;
	border-bottom: 0 !important;
	border: 1px solid #828384 !important;
	padding: 0.3rem 1.7rem !important;
	font-weight: 400 !important;
	font-size: 15px !important;
}



.SKAddContactDialog .inlineError {
	left: 152px;
}

.skTabs {
	display: flex;
	margin-left: 0;
	padding-left: 0;
}

.skTabItem {
	margin-right: 12px;
	color: #000;
	list-style: none;
	font-weight: 500;
	text-align: center;
	padding-bottom: 4px;
	cursor: pointer;
	font-size: 15px;
	min-width: 80px;
}

.skTabItem:hover {
	/* border-bottom: 2px solid #FC7242 !important; */
}

.skTabItem.activeTab {
	color: var(--secondary-color) !important;
	font-family: "Urbanist", sans-serif !important;
	background-color: #fff;
	border-left: none !important;
	border-top: none !important;
	font-weight: 500 !important;
	border-right: none !important;
	border-color: #ddd !important;
	border-bottom: 2px solid var(--secondary-color) !important;
	transition: none;
}

.roleToggleOptions {
	width: 180px !important;
	margin-top: 4px;
	margin-bottom: 6px;
}

.react-virtualized-menu-placeholder {
	margin-top: 12px;
	color: #9a9a9a;
	text-align: center;
}

.react-virtualized-list-wrapper li {
	list-style: none;
}

.skLink {
	color: var(--secondary-color) !important;
	cursor: pointer !important;
}



.skLink:hover {
	text-decoration: underline !important;
}

.noSKLink {
	cursor: default;
}

.noSKLink:hover {
	text-decoration: none;
}

.skPointer {
	cursor: pointer;
}

.sklogoClass {
	max-width: 70px;
	height: 40px;
	border-radius: 0px;
	/* border: 2px solid #e7e8ea; */
}

.sk-table {
	border-left: none !important;
	border-right: none !important;
	border: none !important;
}

.sk-table .sk-td,
.sk-table .sk-th {
	border-right: none !important;
	padding: 0.75rem;
}

.sk-table .sk-td {
	border-top: none !important;
	border-bottom: 1px solid #dee2e6 !important;
	text-align: left;
}

.sk-table .sk-td,
.sk-table .sk-td p {
	vertical-align: middle;
	font-size: 13px;
	color: #000;
}

.sk-table .sk-th {
	font-size: 14px;
	color: var(--sk-heading-color);
	font-weight: 400;
	border-top: 0;
	vertical-align: middle;
	/* background-color: var(--sk-select-color); */
}

.skMore {
	margin: 0px;
	font-size: 14px;
	color: #070401;
	font-weight: 500 !important;
	padding: 8px !important;
}


.action-item {
	cursor: pointer;
	font-weight: 400;
	padding: 10px 13px;
	border-left: 0 none;
	border-right: 0 none;
	color: #000 !important;
	font-weight: 500;
	font-family: "Urbanist", sans-serif;
	font-size: 12px;
	border-bottom: 1px solid #E5E5E8;
	transition: background-color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06), color 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.action-item:hover {
	background-color: #fafafb;
}



.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li {
	font-family: var(--sk-font-urbanist-family);
	white-space: nowrap;
	font-weight: 300;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid #efefef;
	text-align: left;
	width: 100%;
}

.skActions.churnedActionOverlay {
	left: -4px !important;
}

.skActions.taskPopper {
	left: -30px !important;
}

.skActions .show_list_items_abs_click .show_list_items_abs_menus ul {
	margin: 0 !important;
	padding: 0 !important;
	width: 100%;
	display: flex;
	position: relative;
	z-index: 2;
	flex-direction: column;
	list-style: none;
	justify-content: center;
}

/* .skActions .show_list_items_abs_click .show_list_items_abs_menus li {
  padding: 8px 12px !important;
 
} */

.skActions .show_list_items_abs_click .show_list_items_abs_menus {
	background-color: var(--primary-color);
	font-size: 12px;
	text-decoration: none;
	font-family: "Urbanist", sans-serif !important;
	margin: 0;
	padding: 0;
	/* min-width: 144px; */
	min-width: 90px;
	z-index: 999;
	box-shadow: -2px 0px 4px 1px rgba(0, 0, 0, 0.1),
		0px 2px 3px 0px rgba(0, 0, 0, 0.2);
	text-align: left;
	margin-top: 5px;
	max-height: 300px;
	overflow: auto;
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 13px 13px;
}

.smart__contact__three__dot__menu .show_list_items_abs_click .show_list_items_abs_menus {
	min-width: 7.313rem;
	overflow-y: hidden;
	border-radius: 0px 0px 6px 6px !important;
}

.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a {
	/* padding: 8px 0px; */
	font-size: 12px;
	color: #000;
	text-decoration: none;
	font-family: var(--sk-font-urbanist-family);
	font-weight: 400;
	cursor: pointer;
 	display: flex;
 	align-items: center;
}
.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a .icon{
	position:  relative !important ;
}

/* .skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a img{
  width: 13px;
  height: 13px;
  margin-right: 12px;
} */
.accCreateMenu.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a,
.childThreedot.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a {
	padding: 8px 12px;
	display: block;
}

.skActions .show_list_items_abs_click .show_list_items_abs_menus ul li {
	padding: 8px 12px;
}

.skActions .show_list_items_abs_click .show_list_items_abs_menus::before {
	top: -5px;
	content: "";
	/* left: 140px; */
	bottom: -3px;
	position: absolute;
	width: 0;
	height: 0;
	right: 6%;
	border-color: #fff #fff transparent transparent;
	border-style: solid;
	border-width: 6px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-filter: drop-shadow(1px -1px 1px rgba(0, 0, 0, .1));
	filter: drop-shadow(1px -1px 1px rgba(0, 0, 0, .1));
	display: none;
}

.ratingWrap span {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.ratingWrap span input {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 35px;
	height: 35px;
	z-index: 999;
}

.ratingWrap {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ratingWrap input[type="radio"]:checked {
	border-color: transparent;
}

.ratingWrap input[type="radio"]:checked:before {
	background: none;
	border-color: transparent;
}

h2.sk-heading {
	font-size: 18px;
	font-weight: 500;
	font-family: "Urbanist";
	line-height: 1.5rem;
	letter-spacing: normal;
	color: #000000;
}

.sk-mediaItem .documentTypeIcon {
	max-height: 300px;
	width: 100%;
	margin-bottom: 10px;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
}

.sk-mediaItem .documentTypeIcon .pg-viewer-wrapper {
	overflow-y: unset;
}

.pdf-canvas {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.sk-mediaItem {
	border-bottom: 2px solid #e5e5e8 !important;
}

.multi__select__dropdown__scroll__container {
	/* max-height: 100% !important;
	overflow-y: hidden !important; */
	overflow: hidden;
}

.multi__select__dropdown__scroll__container .filter-dropdown-search-box-options-container {
	max-height: 100% !important;
}

.multiSelectDropdown {
	/* background-color: #fff;
  font-size: 12px;
  text-decoration: none;
  font-family: var(--sk-font-urbanist-family) !important;
  margin: 0;
  padding-top: 10px;
  min-width: 200px;
  max-width: 200px;
  border-radius: 8px;
  z-index: 999;
  box-shadow: 0px 3px 6px #00000029;
  text-align: left;
  border: 1px solid var(--sk-smart-datepicker-border-color);
  border-radius: 0px 0px 13px 13px; */
	/* overflow: auto;
	min-height: 100px; */


	/* height: 320px;
    overflow-y: auto;
    overflow-x: auto; */
	/* background-color: #fff;
    font-size: 12px;
    text-decoration: none;
    font-family: var(--sk-font-urbanist-family) !important;
    margin: 0;
    padding-top: 0px;
    min-width: 200px;
    max-width: 200px;
    border-radius: 8px;
    z-index: 999;
    box-shadow: 0px 3px 6px #00000029;
    text-align: left;
    border: 1px solid var(--sk-smart-datepicker-border-color);
    border-radius: 0px 0px 13px 13px; */
	/* border: 1px solid var(--sk-smart-datepicker-border-color);
		border-radius: 0px 0px 13px 13px;
		box-shadow: 0px 3px 6px #00000029;
		background-color: #fff; */
		overflow: hidden;
		min-height: 50px;
}

.multiSeclectDropdown_overlay_footer {
	position: relative;
	bottom: 0px;
	background: #3f3939;
	z-index: 9999999;
	height: 20px;
}

.contact_multiSeclectDropdown_overlay.multiSeclectDropdown_overlay {
	left: 6% !important;
}

.multiSelectDropdown ul li {
	padding: 6px 5px 0px;
	display: block;
	font-size: 15px;
	color: #4e4e4e;
	text-decoration: none;
	font-family: "Urbanist", sans-serif !important;
	font-weight: 400;
	cursor: pointer;
	border-bottom: 1px solid var(--sk-border-color);
}

.smartCheckBox__label {
	color: black;
	margin-left: 5px;
	font-family: var(--sk-font-urbanist-family);
	font-size: 15px;
	font-weight: 400;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
}

.multiSelectDropdown .filter-attribute-dropdown-option {
	font-size: 15px !important;
}

.multiSelectDropdown .filter-dropdown-search-box-options-container {
	margin-right: -5px;
}

.multiSelectDropdown .filter-dropdown-search-box-options-container {
	max-height: 210px !important;
	overflow-y: auto;
}
.sk_accountList_addUser_pref_popup .multiSelectDropdown .filter-dropdown-search-box-options-container{
	/* max-height: 150px !important; */
}

/* @media screen and (max-width: 995px), 
       screen and (max-height: 700px) {
	
 
} */
.sk_accountList_addUser_pref_popup-conatiner.multiSeclectDropdown_overlay.gridColUpdate.add__info__tooltip {
	/* top: 0 !important; */
}
.multiSelectDropdown .smart__react__select__parent__account__icon {
	background: var(--iconUrl);
	background-position: -557px -1069px;
	width: 1.5rem;
	height: 1.375rem;
	left: 9px;
	top: 2px;
	position: absolute;
	scale: 0.82;
}

.multiSelectDropdown .smart__react__select__other__account__div {
	width: 17px;
	height: 1.375rem;
	position: relative;
}

.global__filter__account__icon__wrapper {
	width: 17px;
	height: 1.375rem;
	position: relative;
}

.global__filter__parent__account__icon {
	background: var(--iconUrl);
	background-position: -557px -1069px;
	width: 1.5rem;
	height: 1.375rem;
	position: absolute;
	scale: 0.7;
	right: 0px;
}

.global__filter__account__icon__text {
	width: calc(100% - 17px);
}

.sk_suggestion {
	background-color: #FFFFFF;
	color: #000;
	padding: 5px;
	/* border: 1px solid #E5E5E8; */
	z-index: 99999;
	margin-left: 7.5rem;
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 13px 13px;
	margin-top: 0.938rem;
	width: 45%;
}

.sk_suggestion p {
	font-weight: 300;
	font-size: 14px !important;
}

.footer-container {
	padding: 10px 10px;
	cursor: pointer;
	color: #2B78E4;
}

.suggestion-footer {
	display: inline-flex;
	float: right;
}

.smart__konversations__call__intelligence__sort__icon__wrap_popper {
	top: 0px !important;
	z-index: 9;
	/* dont change Z index position.It will affetcts in Datgrid table*/
}

.firstCol.smart__konversations__call__intelligence__sort__icon__wrap_popper {
	z-index: 99;
}

.expansionOppertunitySorticon.smart__konversations__call__intelligence__sort__icon__wrap_popper {
	top: -8px !important
}

#UiLabelTooltip.smart__contact__popup-info-message .tooltip-inner {
	text-align: left;
}

#UiLabelTooltip.smart__assignment__rule__popup-info-message .tooltip-inner {
	text-align: left;
}

#UiLabelTooltip.tooltip:before.smart__assignment__rule__popup-info-message {
	right: 20px !important;
}


/* Phoenix search icon changes */
.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:focus {
	width: 12.5rem !important;
	padding: 0 30px 0 15px;
	height: 1.875rem;
	border: 1px solid #E5E5E8 !important;
	cursor: text;
	color: white !important;
	border-radius: 4px !important;
	font-family: var(--sk-font-urbanist-family);
	font-size: 12px !important;
}

.smart__sk__search__container .mini-toolbar {
	display: inline-flex;
	border-radius: 0px;
}

.smart__sk__search__container .mini-toolbar .mini-toolbar-left {
	display: flex;
	align-items: center;
	width: 50%;
}

.smart__sk__search__container .mini-toolbar.version-2 .mini-toolbar-left {
	display: flex;
	align-items: center;
	width: 100%;
}

.smart__sk__search__container .mini-toolbar.version-2 .mini-toolbar-left .mini-search {
	position: relative;
	border-radius: 25px;
	padding: 0px 15px;
	height: 30px
}


.smart__sk__search__container .mini-toolbar.version-2 .mini-toolbar-left .mini-search input[type=text],
.mini-toolbar.version-2 .mini-toolbar-left .mini-search input[type=search] {
	color: white !important;
	border-radius: 4px !important;
	padding: 0 30px 0 15px;
}

.smart__sk__search__container .mini-toolbar .mini-search {
	position: relative;
}

.mini-toolbar .mini-search input[type=text]:active+input[type=submit],
.mini-toolbar .mini-search input[type=search]:active+input[type=submit] {
	/* background-position: -672px 0px !important; */
}

.mini-toolbar .mini-search input[type=text]:focus+input[type=submit],
.mini-toolbar .mini-search input[type=search]:focus+input[type=submit] {
	pointer-events: all;
	/* background-position: -672px 0px !important; */
	filter: brightness(0.5);
}

.smart__sk__search__container .mini-toolbar .mini-search input[type=text],
.smart__sk__search__container .mini-toolbar .mini-search input[type=search] {
	position: absolute;
	background-color: transparent;
	border: 0 none;
	appearance: none;
	-webkit-appearance: none;
	z-index: 1;
	right: -1px;
	width: 12.5rem;
	height: 100%;
	transition: width ease-in-out 0.3s;
	color: white !important;
	border-radius: 4px !important;
	outline: 0 none;
	/* border: 1px solid transparent; */
	border-right: 0 none;
	/* color: transparent; */
	cursor: pointer;
	height: 2rem;
	margin-top: -2px;
}

.smart__sk__search__container .mini-toolbar .mini-search .sk_icon {
	position: relative;
	background-color: transparent;
	appearance: none;
	border: 0 none;
	background-position: -672px 0px !important;
	z-index: 2;
	text-indent: -100px;
	width: 32px;
	height: 32px;
	overflow: hidden;
	pointer-events: none;
	cursor: pointer;
	outline: 0 none;
	left: 15px;
	top: -2px;
	scale: 2;
	/* filter: brightness(1) invert(1); */

}


.smart__sk__search__container .mini-toolbar .mini-toolbar-right {
	display: flex;
	align-items: center;
	width: 50%;
	color: white !important;
	border-radius: 4px !important;
	border-left: 2px solid #fff;
	cursor: pointer;
}





.smart__sk__search__container .mini-toolbar .mini-toolbar-right:hover {
	background-color: transparent;
}

.smart__sk__search__container .mini-toolbar .mini-search:hover input[type=submit] {
	border-radius: 0px !important;
	background-position: -672px 0px !important;
}

.smart__sk__search__container .mini-toolbar .mini-toolbar-left:hover {
	background-color: transparent;
	border-radius: 0px !important;
}


.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:focus,
.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:valid .smart__sk__search__container .mini-toolbar .mini-search input[type=search]:focus,
.smart__sk__search__container .mini-toolbar .mini-search input[type=search]:valid {
	color: white !important;
	border-radius: 4px !important;
	background-color: transparent !important;
	width: 12.5rem !important;
	padding: 0 30px 0 15px;
	border: 1px solid #E5E5E8;
	/* color: #000 !important; */
	cursor: text;
}



.confirmModal.fade.modal.show {
	z-index: 999999;
}


.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:focus::placeholder,
.smart__sk__search__container .mini-toolbar .mini-search input[type=search]:focus::placeholder {
	text-indent: 0px !important;
}

.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:active,
.smart__sk__search__container .mini-toolbar .mini-search input[type=search]:active {
	background-color: transparent;
	width: 12.5rem !important;
	padding: 0 30px 0 15px;
	border: 1px solid #E5E5E8;
	color: white !important;
	border-radius: 4px !important;
	/* color: #000; */
	cursor: text;
}

input[type=text]:focus~input[type='submit'] {
	box-shadow: none !important;
}

.smart__sk__search__container .mini-toolbar .mini-search input[type=text]:active::placeholder,
.smart__sk__search__container .mini-toolbar .mini-search input[type=search]:active::placeholder {
	text-indent: -110px !important;
}

.smart__sk__search__container .mini-toolbar ::placeholder {
	color: #828384 !important
}

.smart__sk__search__container .mini-toolbar .mini-search ::placeholder {
	text-indent: 999px;
}


/* Three dot icon changes */

.three__dot__small__icon__container {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;
	height: 100%;
}

.three__dot__small__icon {
	position: absolute;
	background: var(--iconUrl);
	background-position: -447px -952px;
	width: 20px;
	height: 35px;
	zoom: 0.85;
}


.playbook__summary__date__tooltip#UiLabelTooltip.tooltip:before {
top: 4% !important;
}

.smart__account__listing-product-tooltip.aCsummaryTooltip#UiLabelTooltip.HWSelectMultiSelectTooltip{
	/* width: fit-content !important; */
	max-width: 500px !important;
}

.smart__account__listing-product-tooltip.aCsummaryTooltip#UiLabelTooltip .tooltip-inner {
	/* min-width: 114px !important;
	width: fit-content; */
	max-width: 500px !important;
}

.smart__contact__three__dot__menu .skActions .show_list_items_abs_click .show_list_items_abs_menus ul li a{
	padding: 0px !important;
	display: inline-flex;
} 

.contact__add__new__user-icon-wrap {
	display: inline-flex;
}

.contact__add__new__user-icon-wrap  .show_sk_popup{
	display: inherit;
}

.smart__contact__three__dot__menu  .show_sk_popup{
	padding: 0px !important;
}


.sk-alert-grid-table-sort.smart__konversations__call__intelligence__sort__icon__wrap_popper {
	z-index: 9999999 !important;
}
