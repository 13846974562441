/* details styles starts ============================================== */
.smart__side__popup__form__details__container {
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: 22px;
	-ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
	max-height:calc(100% - 150px) ;
}

.smart__side__popup__form__details__container::-webkit-scrollbar{
	display: none;
}

.smart__side__popup__form__details__container.sk__touchpoint__form.edit {
	max-height:calc(100% - 150px)  !important;
}


.smart__side__popup__form__details__container .smart__input__btn::placeholder {
	color: var(--sk-smart-placeholder-color);
	font-size: 0.904rem;
}

.smart__side__popup__form__details__container::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.smart__side__popup__form__details__container::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
}

.smart__side__popup__form__details__container::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

.smart__side__popup__form__details__container::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 6px;
}

.smart__side__popup__form__details__container__row {
	display: flex;
	width: 100%;
	/* margin-top: 0.625rem; */
	/* height: 40px; */
	margin-bottom: 0.75rem;
	padding-left: 0.75rem;
	padding-right: 1.5rem;
}

.smart__side__popup__form__details__container__row-footer {
	bottom: 0;
	margin-top: 0.625rem;
	display: flex;
	background-color: var(--side-pop-up-footer-color);
	width: 100%;
	justify-content: flex-end;
	height: 70px;
	position: absolute;
	z-index: 99999;
}

.smart__side__popup__form__details__container__row-footer .skSecondaryBtn {
	background-color: #F2F2F2;
	margin-right: 1.1rem;
	height: 2.25rem;
	width: 6rem;
	border-radius: 0.25rem;
	color: #E37D72;
	font-size: 14.5px;
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	border: 1px solid var(--sk-smart-primary-btn-bg);
	letter-spacing: 0.9px;
}

.smart__side__popup__form__details__container__row-footer .skPrimaryBtn {
	/* height: 2.25rem;
	width: 6.22rem; */
	border-radius: 0.25rem;
	font-size: 14.5px;
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	border: 1px solid var(--sk-smart-primary-btn-bg);
	letter-spacing: 0.9px;


}

.smart__side__popup__form__details__container__row-footer .sk-smart-uiBtn_wrap {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-right: 24px;
}

.smart__side__popup__form__details__container__row-footer .pl-10 {
	padding-left: 1.875rem;
}

.smart__side__popup__form__details__container__row__label {
	letter-spacing: 0.24px;
	min-width: 25%;
	max-width: 25%;
	display: flex;
	align-items: center;
	font-family: var(--sk-font-urbanist-family), Medium;
	font-size: 14px;
	font-weight: 400;
	margin-left: 0.625rem;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	align-items: baseline;
	margin-top: 10px;
}

.smart__side__popup__form__details__container__row__content {
	/* width: 82%; */
	width: 100%;
}

.smart__side__popup__form__details__container__row__content .smart__input__btn {
	height: 40px;
}

.smart__task__popup-form .smart__side__popup__form__details__container__row__content .smart__input__btn {
	height: 41px;
}

.smart__task__popup-form .smart__side__popup__form__details__container__row__content .smart__date__picker__with__input__field__container .smart__input__btn {
	height: 34px !important;
}

.smart__contact__popup-form .smart__side__popup__form__details__container__row__content .smart__input__btn{
	height: 40px;
}

.smart__side__popup__form__details__container__row__content .smart__date__picker__with__input__field__container {
	height: 45px;
}
.smart__side__popup__form__details__container__row__content .smart__date__picker__with__input__field__container.pointer-events-none{
background-color: var(--desable-color-input);
}
.smart__date__picker__with__input:disabled{
	background-color: var(--desable-color-input);
}
.smart__description__field:disabled{
	background-color: var(--desable-color-input);
}

/* .smart__side__popup__form__details__container__row__content .smart__phone__select{
	height: 100%;
} */

.smart__side__popup__form__details__container__row__content .smart__phone__select .react-tel-input .form-control {
	width: 110px !important;
	height: 40px !important;
	text-align: center !important;
	padding-left: 50%;
	padding-right: 0;
}


.smart__form__error__msg {
	font-weight: 300;
	color: red;
	font-family: var(--sk-font-urbanist-family);
}

/* details styles ends ============================================== */

/* comment style starts ============================================== */
.smart__side__popup__comments__container {
	height: 83vh;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
}

/* comment style ends ============================================== */



.smart__side__popup__form__container {
	height: 100%;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-direction: flex-start;
}

.smart__side__popup__form__container .smartCheckBox__label{
	margin-left: 0px;
}



.task__view__table-container {
	padding-bottom: 20px;
}

.task__view__table-container::-webkit-scrollbar {
	display: none;
}

.task__view__table {
	display: flex;
	width: 100%;
	flex-direction: column;
	opacity: 1;
	position: relative;
	padding-bottom: 20px;
}

.expandable__list__container {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;
	border-radius: 12px;
	border-radius: 4px;
	border-bottom: 1px solid #69697605;
	position: relative;
}

.datagrid__table .access_tabel_content {
	justify-content: flex-start;
	padding: 0px;
	color: var(--smart-insights-and-konversations-intelligence-heading-color) ;
}

.expandable__list__container .MuiDataGrid-columnHeaders {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.expandable__list__container .datagrid__table__container .MuiDataGrid-virtualScroller {
	box-shadow: 0px 3px 10px var(--sk-sub-tab-border-color);
}

.expandable__list__title__container {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	background-color: var(--primary-color);
	border: 1px solid var(--expand-tabele-border-color);
	border-bottom: none;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	height: 44px;
}

.expandable__list__title__container-icon {
	margin-left: 0.625rem;
	margin-top: 0.438rem;
	width: 4%;
}

.expandable__list__title__icon {
	padding-left: 10px;
	background-image: var(--iconUrl);
	background-position: -383px -59px;
	min-width: 26px;
	min-height: 26px;
	left: 0;
	top: 0;
	rotate: -90deg;
	cursor: pointer;
	zoom: 0.75;
}

.expandable__list__title__icon-rotate {
	padding-left: 10px;
	background-image: var(--iconUrl);
	background-position: -383px -59px;
	min-width: 26px;
	height: 26px;
	zoom: 0.75;
	left: 0;
	top: 0;
	cursor: pointer;
}



.expandable__list__title__text {
	width: 90%;
	margin-left: 10px;
	/* margin-top: 0.625rem; */
}

.expandable__list__title__text p {
	font-size: 15px !important;
	font-family: var(--sk-font-urbanist-family);
	color: var(--sk-heading-color);
	font-weight: 600;
}

.task__view__container {
	padding: "0rem 0.625rem";
}

.custom__column__cell__outcome {
	border-radius: 18px;
	color: #FFFFFF;
	cursor: pointer;
	padding: 3px 8px;
  border-radius: 18px;
	max-width: 100%;
}

.datagrid__table .MuiDataGrid-cell--editing .smart__input__btn {
	border-radius: 20px;
	/* color: var(--sk-share-and-konversations-label-color); */
	font-weight: 300;
	border: 1px solid var(--sk-sub-tab-border-color);
	/* margin-left: 10px; */
	height: 30px;
}

.playbook_summary_listing_grid_table__conatiner .MuiDataGrid-cell--editing .smart__input__btn  {
	margin-left: 0px;
}

.task__view__table-container .product__cell__container {
	vertical-align: middle !important;
	font-size: 13px !important;
	color: #000 !important;
	font-family: var(--sk-font-urbanist-family), Medium !important;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
}

.task__view__table-container .task__name {
	border-right: none !important;
}

.task__view__table-container .MuiDataGrid-pinnedColumns--right {
	border-left: none !important;
}

.task__view__table-container .add__column__cell {
	border-left: 1.5px solid var(--tabele-border-color);
}

.task__view__table-container .add__column__header {
	/* border-left: 1.5px solid var(--tabele-border-color); */
	border-right: 1.5px solid var(--tabele-border-color);
}

.w-70 {
	width: 70%;
}

.mr5 {
	margin-right: 5px;
}
.smart__data__grid__sort .smart__konversations__call__intelligence__sort__icon__container-icon {
	margin-top: 0px !important;
}


.smart__task__list__view-new {
	display: flex;
	position: relative;
	width: 100%;
	border-radius: 4px;
	overflow-x: hidden;
	overflow-y: hidden;
}


 .task__created__by__cell {
	width: 100%;
	height: 100%;
	display: flex;

}

.task__created__by-profile-image {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
 
.task__created__by-profile-image  .contact-image-uploaded {
	width: 1.688rem;
	height: 1.688rem;
	border-radius: 50%;
}

.task__created__by-profile-logo-wrap {
	width: 20%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
 .task__created__by-profile-logo {
	border-radius: 50%;
	background-color: var(--smart-konversations-label-color);
	min-width: 1.688rem;
	height: 1.688rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

 .task__created__by-profile-white-text {
	width: 100%;
	height: 100%;
	display: flex;
	color: white;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
}

.task__created__by-profile-text-wrap {
	width: 80%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 10px;
}

.task__created__by-name {
  font-size: 13.5px;
  letter-spacing: 0.3px;
  color: var(--smart-insights-and-konversations-intelligence-heading-color);
}
.smart__task__popup-form--container.smart__side__popup__form__details__container{
  max-height: calc(100% - 120px);
}
.smart__side__popup__form__details__container .smart__side__popup__form__details__container__row__content .smart__date__picker__with__input__field__container{
  height: 40px;
}
.smart__side__popup__form__details__container .smart__react__select__control{
min-height: 40px;
}
.smart__side__popup__form__details__container .smart__react__select__value-container{
  max-height: 70px;
}
.smart__side__popup__form__details__container .input__btn__calendar__icon__container .calendar__icon{
  background-position: -404px -158px;
}
.smart__task__status:hover .sk__checkbox__btn-label {
  color: var(--primary-color);
}
.smart__task__status:hover {
  /* border-radius: 0.25rem;
  font-size: 14.5px;
  font-weight: 600;
  font-family: var(--sk-font-urbanist-family);
  border: 1px solid var(--sk-smart-primary-btn-bg);
  letter-spacing: 0.2px; */
  background-color: var(--sk-smart-primary-btn-bg);
  /* padding: 0px 5px; */
}
.smart__task__completed__status .sk__checkbox__btn-label {
  color: var(--primary-color);
}
.smart__task__completed__status{
	border-radius: 0.25rem;
  font-size: 14.5px;
  font-weight: 600;
  font-family: var(--sk-font-urbanist-family);
  border: 1px solid var(--sk-smart-primary-btn-bg);
  letter-spacing: 0.2px;
  background-color: var(--sk-smart-primary-btn-bg);
  padding: 0px 10px;
}

.smart__task__completed__status.sk__checkbox__btn-container .sk__checkbox__btn-checkmark{
  margin-right: 0px;
}
.smart__task__status.sk__checkbox__btn-container .sk__checkbox__btn-checkmark{
  margin-right: 0px;
}
.smart__task__status.sk__checkbox__btn-container .sk__checkbox__btn-checkmark-unchecked{
  background: var(--iconUrl);
  background-position: -474px -1043px;
	border: none;
}
.smart__task__completed__status.sk__checkbox__btn-container .sk__checkbox__btn-checkmark-checked{
  background: var(--iconUrl);
  background-position: -474px -1045px;
}
.smart__side__popup__form__details__container.smart__task__popup-form--container{
	padding-top: 10px;
}
.smart__task__status,.smart__task__completed__status{
	margin-left: 24px;
}
.task_category--container {
	text-align: center;
}
.task_category--container .smart__konversations__collaborators_name{
	max-width: 100%;
	margin-left: 0px;
}
.task_category--container .custom__column__cell__collaborators-show{
	width: 100%;
}
.task_priority--conatiner.MuiDataGrid-cell.datagrid__table__column-collaborators.td{
	padding: 0px;
}
.task_priority--conatiner .custom__column__cell__collaborators-container.HIGH{
	background-color: #f24e33;
	color: var(--primary-color) !important;
}
.task_priority--conatiner .custom__column__cell__collaborators-container.MEDIUM{
	background-color: #fec86e;
	color: var(--primary-color) !important;
}
.task_priority--conatiner .smart__konversations__collaborators_name span.MEDIUM,
.task_priority--conatiner .smart__konversations__collaborators_name span.HIGH{
	font-weight: 600;
}
.task_priority--conatiner .smart__konversations__collaborators_name{
	max-width: 100%;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
}
.task_priority--conatiner .smart__konversations__collaborators_name span{
	text-transform: uppercase !important;
	/* font-weight: 600;; */
}
.task_priority--conatiner.MuiDataGrid-cell.datagrid__table__column-collaborators.td {
	text-align: center;
}
.email_taskType__markAsComplete .sk_grapTooltipPoper_overlay_arrow::before{
right: 49%;
}
.email_taskType__markAsComplete .progress__update__icon{
	cursor: auto;
}
.task_notification_on_icon{
	background: var(--iconUrl);
	background-position: -634px -5px;
	height: 30px;
	width: 30px;
	min-width: 30px;
	cursor: pointer;
}
.show_list_items_abs_menus .task_notification_on_icon,
.show_list_items_abs_menus .task_notification_off_icon{
	zoom:0.8;
}
.task_notification_off_icon {
	background: var(--iconUrl);
	background-position: -597px -5px;
	height: 30px;
	width: 30px;
	min-width: 30px;
	cursor: pointer;
}

.sk__confetti{
	position: absolute;
    width: 0px;
    left: 50%;
    top: 50%;
}

.datagrid__table__column-functions .smart__konversations__collaborators_name{
  max-width: 85% !important;
}

.datagrid__table__column-functions .emptyData .w-100 {
	margin-left: 5px;
}

.taskDueDate .red {
	color: red;
}


.taskDueDate .black {
	color: #000;
}


.taskDueDate .amber {
	color: #fec86e;
}

.dueDate_row {
	width: 100%;
    padding-right: 25px;
}

.smart__side__popup__form__details__container {
	max-height:calc(100% - 120px) !important;
}

.smart__assignment__popup.edit .smart__side__popup__form__details__container {
	max-height:calc(100% - 120px) !important;
}

.smart__side__popup__form__details__container.smart__task__popup-form.edit {
	max-height:calc(100% - 150px) !important;
}
.smart__side__popup__form__details__container.smart__task__popup-form.summary {
	max-height:calc(100% - 150px) !important;
}

.task__list_view__table-container .datagrid__table__container {
	margin-top: 20px;
}
.task__list_view__table-container .noDataForEvent{
	margin-top: 20px;
}
.subtask_icon{
	width: 14px;
	height: 14px;
	cursor: pointer;
	background-image: var(--iconUrl);
	background-position: -676px -66px !important;
	zoom: 0.9;
	margin-left: 0px;
}

.subtask_icon.subtask_icon-selected {
	background-position: -652px -66px !important;
}

.subtask_icon_disabled {
	width: 14px;
	height: 14px;
	background-image: var(--iconUrl);
	zoom: 0.9;
	margin-left: 0px;
	background-position: -624px -66px !important;
}

.setting__dashboard .organisation__dashboard .smart__side__popup__form__details__container {
	max-height: calc(100% - 30px) !important;
}

.customCollaboratorDropDown .filter-dropdown-search-box-options-container .smart__react__select__menu-list {
	max-height: 125px;
}

.customAssigneeDropDown .filter-dropdown-search-box-options-container .smart__react__select__menu-list {
	max-height: 175px;
}