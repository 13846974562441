.filter__title{
	cursor: pointer;
}

.filter__title:hover{
	text-decoration: underline;
}

.disable__getting__started__modal{
	min-width: 450px !important;
	width: 450px !important;
}

.smart__contact__three__dot__menu.playbook__summary__three__dot__menu.skActions.spTaskActions {
	left: -130px !important;
}


.template_task_icon {
	display: block;
	min-width: 27px;
	height: 30px;
	background: var(--iconUrl);
	background-position: -128px -280px;
	zoom: 0.8;
	/* margin-right: 3px;
	margin-left: 3px; */
}
.playbook__activity__icons__container{
	min-width: 70px;
	max-width: 70px;
	display: flex;
	align-items: center;
}


.template_critical_task_icon {
	display: block;
	min-width: 30px;
	height: 30px;
	background: var(--iconUrl);
	background-position: -128px -280px;
	zoom: 0.8;
}

.playbook__taskType-icon {
	/* min-width: 25px; */
}

.template_task_icon {
	/* min-width: 25px; */
}

.playbook__task__name {
	width: calc(100% - 60px);
	height: 100%;
	display: flex;
}

.template_touchpoint_icon {
	background: var(--iconUrl) no-repeat  -121px -761px;
	/* width: 15px; */
	height: 20px;
	display: block;
	margin-top: -1px;
	/* margin-left: 3px; */
	min-width: 25px;
	/* min-width: 30px;
	height: 30px;
	background: var(--iconUrl);
	background-position: -122px -755px;
	margin-right: 2px; */
}

.template_email_icon {
	display: block;
	min-width: 25px;
	width: 25px;
	height: 30px;
	background: var(--iconUrl);
	background-position: -478px -154px;
	zoom: 0.8;
	/* margin-left: 4px;
	margin-right: 4px; */
}
.template_delayed_icon {
	background: var(--iconUrl) no-repeat -226px -1108px;
	min-width: 25px;
	height: 22px;
	display: block;
	zoom: 0.85;
	margin-right: 0px;
}
.template_completed_icon {
	display: block;
	min-width: 25px;
	height: 30px;
	background: var(--iconUrl);
	background-position: 472px 35px;
	zoom: 0.8;
}

.template_video_icon {
	background: var(--iconUrl) no-repeat -713px -1101px !important;
	width: 25px;
	height: 30px;
	display: block  !important;
	zoom: 0.9;
	/* margin: 0 1.5px; */
}

.subtask-table-container {
  overflow: hidden;
  transition: max-height 0.8s ease-in-out, opacity 0.8s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.subtask-table-container.expanded {
  max-height: 500px; /* Adjust based on your content's height */
  opacity: 1;
}