.customerSupportPopover .popover-body {
    overflow-y: hidden;
    overflow-x: hidden;
    height: 93%;
    padding: 0px 15px;
}

.customerSupportPopover{
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    /* top: 8px !important; */
    min-width: 547px !important;
    left: 4px;
    height: 93.3%;
}
.customerSupportPopover .sk_Popover_heading img{
    width: 11px;
    height: 11px;
}
.customerSupportPopover.sk_Popover  .sk_Popover_heading{
    background: #f5f5f5;
    padding: 13px 20px;
}

.email-notification-setting--icon { 
	background-image: var(--iconUrl);
	margin-right: -10px;
    background-position: -323px -1107px;
    height: 19px;
    width: 35px;
    margin-left: -6px;
    scale: 0.6;
}

.customerSupportPopover .popover{
    max-width: 547px !important;
    left: -7px !important;
    top: 66px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.customerSupportPopover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .5rem 0 0.8rem !important;
    /* margin: 0 .5rem 0 0.9rem !important; */

}


/* For Mobile */
@media (max-width: 1280px) {
    .customerSupportPopover .arrow {
        margin: 0 .5rem 0 0.65rem !important;
    }
}

/* For Tablets */
@media  (min-width: 540px) and (max-width: 780px) {
    .customerSupportPopover .arrow {
        margin: 0 .5rem 0 0.9rem !important;
    }
}

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
    border-bottom-color: #fff;
    /* top: -22px; */
}

/* .bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::after,  .bs-popover-bottom.arrow::before {
    border-width: 0.5rem 1rem 1.5rem 1.3rem;
    top: -20px;
    margin-left: 6rem;
} */
.customerSupportpopover-header {
    font-weight: 300 !important;
    background-color: #f5f5f5 !important;
    padding: 13px 20px !important;
    border-bottom: 1px solid #e5e5e8 !important;
    align-items: center;
    position: relative;
    z-index: 9999999;
    vertical-align: middle;
    font-size: 16px !important;
    font-family: "Urbanist", sans-serif;
}
.customerSupportpopover-header .mini-toolbar {
    background-color: #828384;
    display: inline-flex;
    border-radius: 20px;
}

.customerSupportpopover-header .mini-toolbar.version-2 .mini-toolbar-left {
    display: flex;
    align-items: center;
    width: 100%;
}

.customerSupportpopover-header.mini-toolbar.version-2 .mini-toolbar-left {
    position: relative;
    border-radius: 25px;
    padding: 0px 15px;
}
.customerSupportpopover-header .mini-toolbar .mini-search {
    height: 31px;
}

.customerSupportpopover-header .mini-toolbar.version-2 .mini-toolbar-left .mini-search input[type=text], .mini-toolbar.version-2 .mini-toolbar-left .mini-search input[type=search] {
    border-radius: 20px;
}

.customerSupportpopover-header .mini-toolbar .mini-search input[type=text], .mini-toolbar .mini-search input[type=search] {
    position: absolute;
    background-color: transparent;
    border: 0 none;
    appearance: none;
    -webkit-appearance: none;
    z-index: 1;
    right: -1px;
    width: 102%;
    height: 100%;
    transition: width ease-in-out 0.3s;
    border-radius: 20px 0 0 20px;
    outline: 0 none;
    border: 1px solid transparent;
    border-right: 0 none;
    color: transparent;
    cursor: pointer;
}
.customerSupportPopover .arrow::after{
    margin-left:100px !important;
}
.customerSupportPopover .arrow::before{
    margin-left:100px !important;
}
.customerSupport.notification_popup_content {
    position: relative;
    z-index: 999999;
    overflow: auto;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.customerSupportPopover .touchpoint_header_wrapper {
    padding-left: 0px;
    font-family: "Urbanist";
    
}

.customerSupportPopover .touchpoint_header_wrapper .touchpoint_header.head {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-bottom: 1px solid #e5e5e8;
    align-items: center;
    position: relative;
    z-index: 9999999;
}

.customerSupportPopover .customerSupportpopover-header h5.headerClassForSupport {
    margin-top: 5px;
    list-style: none;
    display: flex;
    width: 100%;
    font-size: 16px !important;
    color: #000000;
    letter-spacing: 0.23px;
    font-weight: 500;
    margin-left: -5px;
}

.customerSupportPopover .touchpoint_header_wrapper .touchpoint_header {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e8;
    padding: 20px;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.23px;
    font-weight: 500;
}
.customerSupportPopover .tab-btns {
    /* background-color: #ffffff; */
    padding: 15px 14px 0px 15px;
    background-color: #ffffff;
    /* padding: 10px 0px 0px 0px; */
    list-style: none;
    border-bottom: 1px solid #e5e5e5;
    margin-left: -11px;
    margin-right: -11px;
}

.customerSupportPopover .custom-tab-btn {
    border: none;
    background: transparent;
    padding-right: 18px;
    font-size: 14px;
    font-weight: 500;
    color: #b2b2b2;
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid transparent;
    /* padding-left: 15px; */
    padding-bottom: 10px;
    /* margin-left: 10px; */
}

.customerSupportPopover .w3-red, .w3-hover-red:hover {
    color: #fc7242 !important;
    background-color: transparent !important;
    border: none;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 2px solid #fc7242 !important;
}

.customerSupportPopover .main-content .main_header .navbar-nav .nav-item .badge {
    background-color: #fc7242;
    top: -20.5%;
}
.customerSupportPopover .main-navbar .navbar .notifications .badge {
    position: absolute;
    padding: 0.25rem 0.375rem;
    font-size: 0.5rem;
    left: 50%;
    top: 50%;
}
.customerSupportPopover .badge_2 {
    background-color: transparent !important;
    color: #fc7242;
    font-size: 11px !important;
}
.customerSupportPopover .badge-danger {
    color: #fff;
    background-color: #c4183c;
}
.customerSupportPopover .badge-pill {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 10rem;
}
.customerSupportPopover .badge {
    padding: 0.375rem 0.5rem;
    font-size: 75%;
    font-weight: 500;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; */
    font-family: "Urbanist";
    color: #fff;
    border-radius: 0.375rem;
}
.customerSupportPopover .badge-danger {
    color: #fff;
    background-color: #dc3545;
}
.customcustomerSupportPopovererSupport .badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem;
}
.customerSupportPopover .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}
.customerSupportPopover .touchpoint_header_wrapper {
    padding-left: 13px;
    margin-bottom: 0px;
}

.subjectClassSupport{
    text-align: left !important;
    min-width: 140px !important;
    color: #000000;
    font-size: 14px !important;
    font-weight: 400 !important;
    outline: none !important;
    padding: 20px 30px 10px 0px;
    font-family: "Urbanist";
    /* margin-right:1.5rem; */
    white-space: nowrap;
}

.subjectInputClassSupport{
    font-family: "Urbanist";
    padding: 7px 7px;
    font-size: 12px;
    font-weight: 400;
    width: 500px;
    margin: 0;
    box-sizing: border-box;
    outline: 0 none;
    border: 1px solid #e5e5e8;
    background: #fdfdfd;
    color: #59686b !important;
}

.subjectSupportClass{
    margin: 0; 
    padding: 5px 6px 0 0;
    /* display: flex; */
    width: 100%;
    /* align-items: center; */
}
.supportDetailsText{
    font-family: "Urbanist";
    color: #000000;
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
    padding: 13px 0 14px 0;
    letter-spacing: 0.01px;
    margin: 0px !important;
}
.supportDetailsTextArea{
    background-color: #ffffff !important;
    height: 180px !important;
    margin-bottom: 10px;
    width: 99%;
    padding: 10px;
    font-family: "Urbanist";
    padding: 10px;
    /* padding: 7px 7px; */
    font-size: 12px;
    font-weight: 400;
    width: 99%;
    margin: 0;
    box-sizing: border-box;
    outline: 0 none;
    border: 1px solid #e5e5e8;
    background: #fdfdfd;
    color: #59686b;
    resize: none;
}

/* .customerSupportPopover .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
    border-width: 1.1rem 0.9rem 0.8rem !important;
    top: -22px !important;
    left: -40px !important;
    border-bottom-color: #E5E5E8 !important;
}

.customerSupportPopover .bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
    border-bottom-color: #F5F5F5 !important;
    border-width: 1.11rem 0.9rem 0.8rem !important;
    top: -22px !important;
    left: -40px !important;
} */

.overflowClassSupport{
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 0px 15px;
}
.overflowClassSupport .notification_content_head .css-1pahdxg-control{
  
  border: 1px solid #e5e5e8;
  box-shadow: none;
  border-radius: 0px;
}
.all_tickets{
    font-family: "Urbanist";
}

.all_tickets .past_ticket_wrapper {
    margin: 0;
    padding: 0 10px;
    list-style: none;
}

.all_tickets .past_ticket_wrapper li {
    padding: 10px 10px 5px 10px;
    width: 100%;
}

.sk_ap_boxes {
    display: flex;
    margin: 0px 0 0 0;
    padding: 0;
    width: 100%;
    justify-content: space-between;
    position: relative;
    transform-origin: center;
}
.all_tickets .past_ticket_wrapper li {
    padding: 10px 10px 5px 10px;
    width: 100%;
}
.sk_ap_boxes > li {
    margin: 0 10px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    min-width: 100px;
    flex-shrink: 1;
    position: relative;
    min-width: auto;
    width: 100%;
}

.all_tickets .past_ticket_wrapper li .sk_ap_boxes li a {
    padding: 15px 20px;
}
.sk_ap_boxes > li > a {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 10px 5px;
    min-width: 100%;
    min-height: 100%;
    background-color: #f7f7f7;
    position: relative;
    text-decoration: none;
}
.info-box-wrapper .box-title {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
}

.info-box-wrapper .info-boxes {
    padding-top: 10px;
}

.info-box-wrapper .info-boxes figure {
    margin-bottom: 5px;
}
figure {
    margin: 0 0 1rem;
}

.info-box-wrapper .info-boxes .counts {
    /* font-size: 24px;
    font-weight: 500;
    color: #000000;
    padding-left: 15px;
    position: relative;
    top: -4px;
    font-family: "Urbanist", sans-serif; */
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    padding-left: 1rem;
    position: absolute;
    top: 2.7rem;
    font-family: "Urbanist", sans-serif;
}

.all_tickets .past_ticket_wrapper li {
    padding: 10px 10px 5px 10px;
    width: 100%;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap {
    width: 100%;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content_head {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px;
    background: #f4f4f4;
}

.all_tickets .past_ticket_wrapper {
    margin: 0 0 0 -7px;
    padding: 0;
    list-style: none;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content {
    width: 100%;
    list-style: none;
    padding-left: 0px;
    cursor: pointer;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content .notification_content_row {
    border-bottom: 1px solid #e5e5e8;
    padding: 5px 10px 15px;
}

.all_tickets .past_ticket_wrapper li .notification_content .notification_content_row {
    padding: 10px !important;
}
  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li {
    display: flex;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content .notification_content_row {
    border-bottom: 1px solid #e5e5e8;
    padding: 5px 10px 15px;
}

.all_tickets .past_ticket_wrapper li .notification_content .notification_content_row {
    padding: 10px !important;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li figure {
    padding-right: 10px;
    margin-bottom: 0px;
    padding-top: 10px;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li figure :last-child {
    border-bottom: 0px;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li section {
    width: 90%;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li section header {
    display: flex;
    justify-content: space-between;
}

.notification_labels {
    display: inline-flex;
    align-items: baseline;
    margin-left: -20px;
    padding: 0;
    align-items: center;
}

.all_tickets .past_ticket_wrapper li .notification_content .notification_content_row .notification_labels li {
    padding: 0px !important;
}
  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li {
    display: flex;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li section header h3 {
    display: inline-flex;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0px;
}

.touchpoint_tools {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

 .dateCS {
    font-size: 13px;
    font-weight: 300;
    color: #59686b;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap .notification_content li section .message {
    font-size: 13px;
    font-weight: 400;
    color: #3c3c3c;
    margin-bottom: 0px;
}

  .all_tickets .past_ticket_wrapper li .notification_content_wrap {
    width: 100%;
}
.all_tickets .past_ticket_wrapper li .notification_content .notification_content_row  .notification_labels li {
    display: flex;
    margin: 0 2px;
    padding: 0;
}

.all_tickets .past_ticket_wrapper li .notification_content .notification_content_row .notification_labels li a {
    /* text-transform: capitalize; */
    font-family: Urbanist;
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 0px 5px;
    line-height: 10px;
    background-color: #e2e2e2;
    padding: 5px 6px;
    border-radius: 3px;
    /* text-transform: uppercase; */
    max-width: 10.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000;
}

.all_tickets .past_ticket_wrapper li .unread_section {
    background-color: #fafafa;
}
.pb-0, .py-0 {
    padding-bottom: 0!important;
}

.all_tickets .past_ticket_wrapper li .unread_section section header ul li h3 {
    font-weight: 500 !important;
}

.notification_popup_wrap .notification_popup .notification_content_wrap .notification_content li {
    display: flex;
}

.ticket-information ul {
    /* padding: 0px;
    margin: 0px; */
    font-family: "Urbanist";
}

.notification_popup_wrap .notification_popup .new-touchpoint, .notification_popup_wrap .notification_popup .existing-touchpoint {
    display: none;
}

.new-touchpoint, .existing-touchpoint {
    padding-left: 0px;
    padding: 0 !important;
}

.popover-content {
    max-width: 310px;
    height: 250px;
    overflow-y:scroll;
}

.smallLeftArrow{
      padding: 15px 0px 20px 5px !important;
}

.hrClassForCS {
    border-bottom: 2px solid #E5E5E5;
    padding: 0px;
    height: 2px;
    margin: -3px 5px -3px;
}

.notification_content_head .accountname_report{
    width: 500px;
}
.notification_content_head  .css-yk16xz-control{
    border-radius: 0px !important;
    border: 1px solid #e5e5e8;

}



.customerSupportPopover .bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
    border-bottom-color: #F5F5F5 !important;
    border-width: 0.1rem 1rem 1rem !important;
    top: -8px !important;
    left: -108px;
}
.customerSupportPopover .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
    border-width: 0.1rem 1.12rem 1rem !important;
    top: -8px !important;
    left: -110px;
    border-bottom-color: #E5E5E8 !important;
}

.sk_popup_wrap .sk_popup section {
    padding: 5px 0 5px 0;
}
.sk_popup_wrap .form_items {
    list-style: none;
    margin: 0;
    padding: 0px 0;
}
.sk_popup_wrap .form_items > li {
    margin: 0;
    padding: 6px 0;
    display: flex;
    width: 100%;
    align-items: center;
}

.sk_popup_wrap .form_items.compact > li label {
    width: 170px;
    color: #000000;
    font-weight: 400;
}
.notification_popup_wrap .sk_popup_wrap .form_items > li label {
    padding-right: 30px;
    width: 215px;
    text-align: right;
    font-size: 12px;
    margin: 0px;
    font-weight: 400;
    color: #000;
    padding-left:3px;
}

.ticket_wrapper .form_items li label {
    text-align: left !important;
    /* width: 100px !important; */
    width: 115px !important;
    color: #000000;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.sk_popup_wrap .form_items > li p {
    margin: 0;
    padding: 0;
    flex: 1;
    color: #000000;
    display: flex;
    align-items: center;
}

.sk_popup_wrap .form_items select, .sk_popup_wrap .form_items input, .sk_popup_wrap .form_items textarea {
    font-family: "Urbanist";
    padding: 7px 7px;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    outline: 0 none;
    border: 1px solid #e5e5e8;
    background: #fdfdfd;
    color: #59686b;
}
.sk_popup_wrap .form_items {
    list-style: none;
    margin: 0;
    padding: 10px 3px;
}

.sk_popup_wrap .form_items > li {
    margin: 0;
    padding: 6px 0;
    display: flex;
    width: 100%;
    align-items: center;
}

.notification_popup_wrap .sk_popup_wrap .form_items > li h2 {
    font-family: "Urbanist";
    color: #000000;
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
    padding: 13px 0 14px 0;
    letter-spacing: 0.01px;
    margin: 0px !important;
}
.sk_popup_wrap .form_items > li h2 {
    font-family: "Urbanist";
    color: #000000;
    font-size: 14px !important;
    font-weight: 500;
    margin: 0;
    padding: 0 0 5px 0;
    letter-spacing: 0.5px;
}
.ticket_wrapper .form_items li h2 {
    margin: 10px 0px !important;
}
.sk_popup_wrap .sk_popup h2 {
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Urbanist";
    color: #000000;
    margin: 0;
    padding: 0;
    line-height: 1em;
    letter-spacing: 0.5px;
}
.sort_iconForCS {
    cursor: pointer;
}

.sort_iconForCS.active {
    transform: rotate(180deg);
}
.sort_iconForCS {
    cursor: pointer;
}

.sk_popup_wrap .form_items .full-text.sml {
    height: 170px !important;
    background-color: #f8f8f8;
    margin-bottom: 10px;
}

.sk_popup_wrap .form_items .full-text {
    width: 100%;
    height: 180px;
    padding: 10px;
}
.sk_popup_wrap .form_items select, .sk_popup_wrap .form_items input, .sk_popup_wrap .form_items textarea {
    font-family: "Urbanist";
    padding: 7px 7px;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    outline: 0 none;
    border: 1px solid #e5e5e8;
    background: #fdfdfd;
    color: #59686b;
}

.notes.with_grey_bg {
    background-color: #f8f8f8;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: -20px;
}
.ticket-information ul {
    padding: 0px;
    margin: 0px;
}
.support_margin {
    margin-bottom: 40px !important;
}
.notes {
    border: 2px solid #efefef;
    list-style: none;
    margin: 0;
    padding: 0;
}

.notes .head {
    padding-bottom: 8px;
}
.notes > li {
    margin: 0;
    padding: 8px 20px;
    /* padding: 8px 12px 8px 20px !important; */
}

.notes .head h2 {
    line-height: 2em;
}

.sk_popup_wrap .sk_popup h2 {
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Urbanist";
    color: #000000;
    margin: 0;
    padding: 0;
    line-height: 1em;
    letter-spacing: 0.5px;
}

.notes.with_grey_bg .note_content {
    /* background: #fff; */
    margin: 0 15px 0 0px;
}
.notes .note_content {
    font-size: 12px;
    font-family: "Urbanist";
    font-weight: 300;
    height: 180px;
    outline: 0 none;
}
.notes > li {
    margin: 0;
    padding: 8px 20px;
}
.note_content_second {
    height: 110px !important;
}

.ticket-information ul {
    padding: 0px;
    margin: 0px;
}
.ticket_actions {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.rply-btn {
    justify-content: flex-end;
}

.ticket_actions.buttons li.reply {
    background-image: url(../assets/images/reply.svg);
}

/*  */
.ticket_actions.buttons li:last-child {
    margin-right: 0px;
}
.ticket_actions.buttons li {
    background: no-repeat 7px center;
    background-color: #ffb13d;
    color: #ffffff;
    padding: 5px 10px 5px 20px;
    margin: 0px 5px;
    font-size: 12px;
}
.ticket_actions li:last-child {
    border-right: 0 none;
    padding-right: 0px;
}
.ticket_actions li {
    padding: 0px 15px 10px 15px;
    cursor: pointer;
    border-right: 1px solid #e5e5e5;
}
.rply-btn li {
    padding: 5px 20px 5px 25px !important;
}

.ticket_actions li:before {
    content: "";
    background: no-repeat left top;
    display: block;
}

.notes .head h2 {
    line-height: 2em;
}
.sk_popup h2 {
    font-size: 15px !important;
    font-weight: 500;
    font-family: "Urbanist";
    color: #000000;
    margin: 0;
    padding: 0;
    line-height: 1em;
    letter-spacing: 0.5px;
}

.overflowClassSupport .create_date{
    font-size: 12px;
    font-family: "Urbanist", sans-serif;
    color: #000;
    font-weight:300;
}
.overflowClassSupport .defult_date{
font-size: 14px;
color: #000;
font-family: "Urbanist", sans-serif;
font-weight:400;
}

.send_response1{
    font-family: "Urbanist";
    color :#000000;
    padding-top: 15px;
    padding-left: 9px;
}

.textAreaClassForTickets{
    height: 107px !important;
    overflow: auto !important;
    margin-top: -5px;
    border: none;
    margin-bottom: 0px;
    width: 105%;
    color: #59686B;
    resize:none
}


.overflowClassSupport textarea::-webkit-input-placeholder{
    color: grey;
    font-size:12px;
    font-weight: 400;
  }

  .createdBy{
    font-family: Urbanist;
    font-style: normal;
    font-weight: 300;
    color: #000 !important;
    font-size: 12px;
  }
  .cS1{
      font-size: '9px' !important;
      color: #000000;
  }

  .myProf1 {
    width: 40px;
    height: 40px;
    border-radius: 25px !important;
}
.myProf12 {
    width: 46px;
    height: 46px;
    border-radius: 25px !important;
}

.overflowClassSupport .desc_box {
    background: #EFEFEF;
    padding: 15px 0px;
    margin: 0px 0px;
    border: 2px solid #e5e5e8;
}

.overflowClassSupport .mail_details{
    margin-left: -1px !important;
    padding-bottom: 3px;
}

.disabledTextArea p{
    font-family: "Urbanist";
    font-size: 12px !important;
    font-weight: 400 !important;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    outline: 0 none;
    /* background: #efefef; */
    color: #59686b;
   
}
.disabledTextArea{
    height:170px !important;
    line-height: inherit;
    padding: 7px 7px;
    height: 170px !important;
    background-color: #f8f8f8;
    margin-bottom: 10px;
    border: 1px solid #e5e5e8;
}

.freshP{
    font-family: "Urbanist" !important;
    font-size: 12px !important;
    font-weight: 400 !important; 
    padding:10px;
    margin:0px;
    /* white-space: break-spaces; */
}

.segmentmini-search{
    width:200px;
}

.noticeIssues span{
    color: red;
    font-size: 12px;
    position: relative;
    top: 0px;
    font-weight:400;
    left:5px
}

.restrictToOneLineSubject{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    /* max-width: 100% !important; */
  }

.status_cell_resolved{
	color: var(--primary-color);
	background-color: #238401;
	padding: 0px 10px ;
	font-size: 14px !important;
	border-radius: 10px;
	margin: 5px;
	text-align: center;
}

.status_cell_open{
	background-color: var(--sk-actions-create);
  color: var(--primary-color);
	padding: 0px 10px;
	font-size: 14px !important;
	border-radius: 10px;
	margin: 0px;
	text-align: center;

}

.status__dropdown .smart__react__select__control{
	border : none;
	padding-left: 0px;
	background: none !important;
}

.support_table_container .MuiDataGrid-columnHeader:first-child {
	border-left: 1.5px solid #2304681A !important;
	border-right: none !important;
}

.support_table_container .MuiDataGrid-cell:first-child {
	border-left: 1.5px solid #2304681A !important;
}

.support_table_container .MuiDataGrid-cell:last-child {
	border-right: 1.5px solid #2304681A !important;
}


.support_table_container .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell:first-child{
	padding-left: 0px !important;
}

.support_table_container .datagrid__table__container .MuiDataGrid-virtualScroller {
	overflow-y: auto !important;
	/* border-right: 1.5px solid #2304681A !important; */
}

.support_ticket_subject{
	cursor: pointer;
}

.smart__support__side__popup__form__container {
	height: 100%;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-direction: flex-start;
}

.smart__support__side__popup__form__container .smartCheckBox__label{
	margin-left: 0px;
}

.smart__support__side__popup__form__container .MuiBackdrop-root{
	z-index: 999999;

}


