.sk_tab_filter_container {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* background: var(--primary-color) 0% 0% no-repeat padding-box; */
	opacity: 1;
}

.sk_tab_filter_container_row {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.sk_tab_filter_container_row .plusIcon {
	width: 14%;
	margin-top: -16px;
	z-index: 9999;
	padding-bottom: 10px;
	scale: 0.8;
}

.sk_tab_filter_tab {
	text-align: left;
	font: normal normal normal 20px/30px;
	letter-spacing: 0px;
	color: #707070;
	letter-spacing: 0.2px;
	opacity: 1;
	white-space: nowrap;
	background-color: var(--sk-template-card-bg);
	border-left: 1px solid var(--account_360_graph_light_color);
	min-width: 100px;
	font-family: var(--sk-font-sans);
}
.sk_tab_filter_tab a{
	text-align: left;
	font: normal normal normal 20px/30px;
	letter-spacing: 0px;
	color: #707070;
	letter-spacing: 0.2px;
	opacity: 1;
	white-space: nowrap;
	min-width: 100px;
	font-family: var(--sk-font-sans);
}

.sk_tab_filter_tab:first-child {
	border-left: none;
}

.sk_tab_filter_sub_tab {
	text-align: center;
	/* font: normal normal normal 20px/30px; */
	align-items: center;
	letter-spacing: 0px;
	color: var(--sia-chatbox-textbox-background-colo);
	opacity: 1;
	white-space: nowrap;
	/* padding: 0px 12px; */
	cursor: pointer;
	display: flex;
	font-family: var(--sk-font-sans);
	margin-bottom: 3px;
}
.sk_tab_filter_sub_tab__container{
	/* padding: 0px 12px;  */
	margin-left: 12px;
}

.sk_tab_filter_sub_tab:first-child {
	/* padding-left: 15px; */
}
.sk_tab_filter_and_condition_box_left .sk_tab_filter_sub_tab__container:first-child{
	margin-left: 15px;
}
.sk_tab_filter_and_condition_box_left .sk_tab_filter_sub_tab__container:last-child{
	margin-right: 10px;
}

.sk_tab_filter_tab_title {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	/* text-align: center; */
	font: normal normal normal 7px/14px;
	letter-spacing: 0px;
	opacity: 1;
	white-space: nowrap;
	/* font-weight: 400px; */
	padding: 8px 22px 8px 22px;
	cursor: pointer;
	font-size: 14px;
	font-family: var(--sk-font-sans);
	font-weight: 500;
	letter-spacing: 0.42px;
	position: relative;
	/* opacity: 0.8;
  color: var(--smart-insights-and-konversations-intelligence-heading-color); */
}

.sk_tab_filter_tab.sk_tab_filter_tab_selected .sk_tab_filter_tab_title {
	font: normal 500 normal 7px/14px;
	font-size: 14px;
	font-family: var(--sk-font-sans);
	font-weight: 500;
	opacity: 1;
	color: var(--secondary-color);
}


.sk_tab_filter_tab_selected_line {
	/* top: 123px;
    left: 410px; */
	/* left: 35%; */
	position: absolute;
	width: 22%;
	height: 3px;
	background: var(--secondary-color) 0% 0% no-repeat padding-box;
	border-radius: 20px 20px 0px 0px;
	opacity: 1;
	bottom: 0px;
}

.sk_tab_filter_tab_selected {
	color: var(--secondary-color);
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.primaryTab-left {
	display: inline-flex;
	width: 75%;
	overflow-y: auto;
}

.primaryTab-right {
	width: 37%;
	/* max-width: 37%; */
	display: inline-flex;
	justify-content: flex-end;
	align-items: center;
}

.disabled__elements__global__filter {
	display: none;
}

.disabled__elements__global-date-filter {
	margin-right: 10px;
	font-family: var(--sk-font-sans);
	justify-content: flex-end;
	display: flex;
	align-items: center;
	margin-top: -20px;
	opacity: 0.95;
	pointer-events: none;
}

.sk_tab_filter_bottom_bar {
	border-bottom: 1px solid #cfcfcf;
}

.sk_tab_filter_sub_tab .filter-dot {
  height: 10.5px;
  width: 10.5px;
  background-color: #707070;
	/* background-color: 	#F3ECFC80; */
  border-radius: 50%;
  display: inline-block;
	/* margin-top: 14px; */
}
.sk_tab_filter_sub_tab:hover .filter-dot {
	background: var(--sk-dashboard-bg-img--colour-3) 0% 0% no-repeat padding-box;
}
.sk_tab_filter_sub_selected_tab.sk_tab_filter_sub_tab .filter-dot {
	background: var(--secondary-color) 0% 0% no-repeat padding-box;
}
.sk_tab_filter_sub_tab_selected {
	color: var(--secondary-color);
	font-size: 14px;
	font-weight: initial;
	font-weight: 500;
	/* opacity: 0.8; */
	padding: 10px 0px 10px 5px
}

.sk_tab_filter_sub_selected_tab {
	border-bottom: 3px solid var(--secondary-color);
	margin-bottom: 0px;
	/* border-bottom-left-radius: -20px;
    border-bottom-right-radius: -20px; */
	/* background: var(--secondary-color) 0% 0% no-repeat padding-box; */
	/* border-radius: 20px 20px 0px 0px; */
	/* opacity: 1; */
}

.primaryTab-right .share_icon {
	width: 30px;
	height: 30px;
	background-image: var(--iconUrl);
	background-position: -469px -567px;
	background-repeat: no-repeat;
	zoom: 0.8;
	margin-right: 10px;
	cursor: pointer;
	margin-top: -25px;
}

.primaryTab-right .plus_icon {
	width: 53px;
	height: 55px;
	background-image: var(--iconUrl);
	background-position: -11px -557px;
	zoom: 0.9;
	margin-right: 15px;
	cursor: pointer;
	margin-top: 5px;
}

.primaryTab-right .plus_icon_wrap {
	margin-top: -20px;
	/* z-index: 9999; */
}

.primaryTab-right .add_icon {
	width: 35px;
	text-align: center;
	height: 35px;
	background-color: orange;
	border-radius: 50%;
	margin-top: -14px;
	z-index: 9999;
}

.sk_tab_filter_sub_tab_unselected {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font: normal 300 normal 7px/7px;
	font-size: 14px;
	font-weight: 300;
	padding-bottom: 9px;
	font-family: var(--sk-font-sans);
	padding: 10px 0px 10px 5px;
	letter-spacing: 0.42px;
	opacity: 0.8;
}


.sk_tab_filter_icon {
	width: 40px;
	height: 40px;
	background-image: url(../assets/phoenix/old-icons.svg);
	background-position: -115px 145px;
}

.sk_tab_filter_and_condition_container {
	padding-right: 15px;
	display: flex;
	min-height: 3.125rem;
	display: block;
	width: 100%;
	background-color: var(--primary-color);
	position: relative;
}

.sk_tab_filter_and_condition_box_left {
	/* max-width: 70%; */
	float: left;
	height: 3.125rem;
	max-width: 51%;
}

.sk_tab_filter_and_condition_box_left_element {
  width: 100%;
  height: 115%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: center;
	/* margin-left: 40px; */
	/* position: relative; */
}

.sk_tab_filter_and_condition_box_left_element::-webkit-scrollbar {
	width: 0px;
	/* background: transparent; */
	height: 2px;
}

.sk_tab_filter_and_condition_box_left_element::-webkit-scrollbar-thumb {
	background: var(--sk-menu-bg-color);
	border-radius: 6px;
	border: 0px solid var(--thumbBG);
}

.sk_tab_filter_and_condition_box_left_element::-webkit-scrollbar-thumb:hover {
	background: var(--sk-menu-bg-color);
}

.sk_tab_filter_and_condition_box_left_element::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
	border-radius: 6px;
}


.sk_tab_filter_and_condition_box_right {
	float: right;
	height: 50px;
	/* max-width: 30%; */
	/* height: 3.125rem; */
	/* width: 40%; */
}

.sk_tab_filter_and_condition_box.float_right {
	float: right;
}

.sk_tab_filter_and_condition_box {
	height: 50px;
	display: inline-block;
	float: right;

	/* padding-top: 7px; */
}

.sk_tab_filter_and_condition {
	background-color: var(--primary-color);
	border-top: 1px solid var(--sk-border-color);
	min-height: 3.125rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.sk_tab_filter_and_condition_container hr {
	border-color: var(--sk-border-color);
	position: absolute;
	width: 100%;
}

.sk_tab_filter_and_attribute_condition_box {
	height: 45px;
	display: block;
	float: left;
	padding: 5px;
}

.right-filter-dropdowns-tab-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: flex-end;
	justify-content: flex-end;
	/* margin-top: 10px; */
}


.sk-filter-attribute-container {
	height: 26px;
	width: 97px;
	display: flex;
	align-items: center;
	background: var(--global-filter-Atributes-bg);
	justify-content: center;
	border-radius: 15px;
	padding-top: 5px;
	cursor: pointer;
}

.sk-filter-attribute-container-filter {
	width: 30px;
	height: 30px;
	background-image: var(--iconUrl);
	background-position: -629px -107px;
	zoom: 0.8;
}

.sk-filter-divider {
	width: 1px;
	background: lightgray;
	height: 50%;
	margin-left: 5px;
	margin-right: 5px;
}

.sk-filter-accounts-container {
	height: 26px;
	min-width: 7.813rem;
	display: flex;
	align-items: center;
	background: var(--global-filter-Atributes-bg);
	justify-content: center;
	border-radius: 15px;
	/* padding-top: 5px; */
	margin-left: 0.625rem;
	cursor: pointer;
}

.sk-filter-accounts-container-icon {
	width: 30px;
	height: 30px;
	background-image: var(--iconUrl);
	background-position: -674px -107px;
	zoom: 0.8;
}
.sk__date__filter__arrow__icon__container-cross-arrow{
	width: 20px;
	height: 20px;
	background-image: var(--iconUrl);
	background-position: -603px -108px;
	zoom: 0.8;
	cursor: pointer;
	position: absolute;
	top: -14px;
	right: 15px;
}
.sk__date__filter__arrows_container{
	position: relative;
	width: 40px;
}
.sk-filter-accounts-container-label {
	/* height: 30px; */
	/* display: flex; */
	text-align: left;
	font: normal normal normal 13px/22px;
	letter-spacing: 0.36px;
	color: var(--sk-heading-color);
	opacity: 1;
	/* margin-top: 5px; */
	font-family: var(--sk-font-sans);
	font-weight: 500;
}

.sk__filter__accounts__arrow__icon__container {
	position: relative;
	width: 20px;
	height: 20px;
	/* scale: 0.5; */
	margin-top: 1px !important;
	margin-left: -3px;
}

.sk__filter__accounts__arrow__icon__container-arrow {
	position: absolute;
	background-image: var(--iconUrl);
	background-position: -509px -569px;
	/* rotate: 90deg; */
	width: 20px;
	height: 20px;
	/* margin-top: -3px; */
	zoom: 0.8;
}

.sk-filter-products-container {
	height: 26px;
	width: 8rem;
	display: flex;
	align-items: center;
	background: var(--global-filter-Atributes-bg);
	justify-content: center;
	border-radius: 15px;
	/* padding-top: 5px; */
	margin-left: 0.625rem;
	margin-right: 0.625rem;
	cursor: pointer;
}

.sk-filter-products-blue-container {
	background: var(--sk-select-color) !important;
}

.sk-filter-accounts-blue-container {
	background: var(--sk-select-color) !important;
}

.sk-filter-products-container-icon {
	width: 30px;
	height: 30px;
	background-image: var(--iconUrl);
	background-position: -713px -108px;
	zoom: 0.8;
}

.sk-filter-service-container-icon {
	width: 30px;
	height: 30px;
	background-image: var(--iconUrl);
	background-position: -574px -1009px;
	zoom: 0.8;
}



.sk-filter-products-container-label {
	/* height: 30px; */
	display: flex;
	text-align: left;
	color: var(--sk-heading-color);
	opacity: 1;
	/* margin-top: 5px; */
	font-family: var(--sk-font-sans);
	font-weight: 500;
	letter-spacing: 0.36px;
}

/* Filter Container */
.filter-dropdown-container {
	width: 100%;
}

.filter-dropdown-header-container {
	margin: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	margin-top: 18px;
}

.share-filter-dropdown-container {
	margin: 15px;
}

.filter-dropdown-header-label {
	margin: 0px 0px 0px 4px;
	text-align: left;
	font: normal 500 normal 13px/20px;
	letter-spacing: 0.36px;
	color: var(--sk-heading-color);
	opacity: 1;
	flex-grow: 2;
	font-weight: 500;
	font-weight: 600;
	font-size: 14.5px;
	/* font-family:   var(--sk-font-sans); */
}

.share_filter_apply_btn_container {
	/* margin-bottom: 15px;
    margin-left: 15px; */
	margin-top: 15px;
}

.share_filter_apply_btn_container .skSecondaryBtn {
	margin-right: 10px;
}

.share-filter-dropdown-header-label {
	font-weight: 400;
	font-size: 15.183px;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
}

.filter-dropdown-header-apply-button {
	text-align: center;
	font: normal normal normal 12px;
	letter-spacing: 0px;
	color: #FFFFFF;
	text-transform: capitalize;
	background: var(--sk-orange-color-notification) 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
	padding: 2px 8px;
	cursor: pointer;
	/* font-family:   var(--sk-font-sans); */
}

.filter-dropdown-header-apply-button-edit-mode {
	display: flex;
	flex-direction: row-reverse;
	padding-right: 12px;
	border-bottom: solid 1px lightgray;
	height: 36px;
	margin-top: 0px;
	align-content: center;
	align-items: center;
}

.filter-dropdown-header-reset-button {
	margin: 0px 5px;
	border: 1px solid var(--sk-orange-color-notification);

	text-align: center;
	font: normal normal normal 12px;
	letter-spacing: 0px;
	color: var(--sk-orange-color-notification);
	text-transform: capitalize;
	border-radius: 2px;
	opacity: 1;
	padding: 2px 8px;
	cursor: pointer;
	/* font-family:   var(--sk-font-sans); */
}

.filter-dropdown-search-box-container {
	margin: 10px;
	display: flex;
	flex-direction: column;
	margin-top: 2px;
}

.filter-dropdown-search-box {
	/* height: 30px; */
	/* margin-left: 10px;
    margin-right: 10px; */
	/* width: 257px; */
	border-radius: 12px;
	opacity: 1;
	border: 1px solid var(--sk-border-color);
	overflow: hidden;
	font-size: 12px;
	padding: 8px 6px 5px 9px;
	white-space: nowrap;
	cursor: pointer;
	margin-bottom: 10px;
}

.filter-dropdown-search-box-options {
	/* max-height: 250px;
    overflow-y: auto; */
	overflow: hidden;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid var(--sk-border-color);
	border-radius: 12px;
	opacity: 1;
	font-family: var(--sk-font-sans);
}

.global_filter_dropdown .filter-dropdown-search-box-options-container {
	max-height: 250px !important;
	overflow-y: auto;
}

.filter-dropdown-search-box-input {
	width: 100%;
	border: none;
	cursor: pointer;
}

.filter-dropdown-search-box-option {
	margin: 10px;
}

.filter-attribute-container-label {
	/* white-space: nowrap;     */
	/* font: normal normal normal 12px/12px ; */
	letter-spacing: 0px;
	color: var(--sk-heading-color);
	cursor: pointer;
	/* font-size: 15px; */

}

.filter-attribute-container-dropdown .filter-attribute-container-label {
	/* font-size: 15px; */
	width: 100%;
	min-width: 70px;
}

.filter-attribute-container-label-edit {
	white-space: nowrap;
	font: normal normal normal 12px/12px;
	letter-spacing: 0px;
	color: var(--sk-heading-color);
	font-family: var(--sk-font-sans);
	/* width: 200px; */
}

.sk_tab_filter_and_condition_box .filter-attribute-container {
	/* min-width: 190px; */
	/* height: 26px; */
	margin: 5px;
	margin-top: 12px;
}

/* .sk_tab_filter_and_condition_box .filter-attribute-container{

} */

.filter-attribute-container-label-value {
	font: normal normal 500 13px/12px;
	font-family: var(--sk-font-sans);
}

.filter-attribute-container-remove-icon {
	width: 15px;
	height: 15px;
	background-image: url(../assets/phoenix/old-icons.svg);
	background-position: -590px 141px;
	overflow: hidden;
	margin-left: 2px;
	margin-top: -2px;
	cursor: pointer;
	zoom: 0.9;
}

.filter-attributes-container {
	display: flex;
	/* width: 200px; */
	flex-grow: 2;
	justify-content: flex-end;
}

.filter-attribute-container {
	display: flex;
	align-items: center;
	flex-direction: row;
	/* justify-content: flex-start; */
	justify-content: space-between;
	padding: 3px 8px;
	background: var(--sk-select-color) 0% 0% no-repeat padding-box;
	border-radius: 16px;
	opacity: 1;
	margin: 8px 10px;
	/* margin-top: 12px; */
	/* white-space: nowrap; */

	/* margin: 5px 8px 8px 8px; */
}

.filter-attribute-dropdown-container .filter-dropdown-search-box {
	border-radius: 4px;
}

.filter-attribute-dropdown-container .filter-dropdown-search-box-options {
	border-radius: 4px;
}

.filter-attribute-dropdown-container .filter-attribute-dropdown-option,
.filter-attribute-dropdown-container .filter-attribute-dropdown-option-selected {
	border-bottom: 1px solid var(--sk-border-color);
}

.filter-attribute-dropdown-container .filter-checkmark-tick {
	width: 4px;
	height: 7px;
	margin-left: 2px;
}

.attribute-filter-dropdown-header-label {
	font-weight: 600;
	font-size: 14.5px;
	margin-left: 2px;
}

.filter-attribute-dropdown-container .filter-checkmark-container-unselected {
	min-width: 12px;
	height: 12px;
}

.filter-attribute-dropdown-container .filter-checkmark-container-selected {
	min-width: 12px;
	height: 12px;
}

.filter-attribute-dropdown-container {
	width: 100%;
	overflow: hidden;
	/* cursor: pointer; */
	/* max-height: 250px;
    overflow-y: auto; */

}

.filter-attribute-dropdown-container-search {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid var(--sk-border-color);
	border-radius: 12px;
	opacity: 1;
	cursor: pointer;
	font-family: var(--sk-font-sans);
	margin: 10px;
	margin-top: 0px;
	width: auto;
}

.filter-attribute-search-container .filter-dropdown-search-box-container {
	margin-bottom: 0px;
}


.filter-attribute-dropdown-options {
	max-height: 250px;
	overflow: scroll;
	margin-right: -5px;
	margin-bottom: -4px;
	/* padding-bottom: 10px; */
}

.filter-attribute-dropdown-option {
	padding: 8px 6px 5px 9px;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 15px;
	/* padding: 8px 9px 5px 12px; */
	overflow: hidden;
	font-family: var(--sk-font-sans);
	cursor: pointer;
}

.filter-attribute-dropdown-option-selected {
	padding: 8px 6px 5px 9px;
	white-space: nowrap;
	text-overflow: ellipsis;
	background: var(--sk-dropdown-hover-bg-color) 0% 0% no-repeat padding-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 15px;
	overflow: hidden;
	cursor: pointer;
}
.multiSelectDropdown .filter-attribute-dropdown-option-selected{
	padding: 8px 0px 5px 9px;
}
.multiSelectDropdown .filter-attribute-dropdown-option{
	padding: 8px 0px 5px 9px;
}
.createte_template_MultiSeclectDropdown_ovelay .skCheckInput + label{
	width: 85%;
	cursor: pointer;
}
.createte_template_MultiSeclectDropdown_ovelay .filter-attribute-dropdown-option{
	cursor: auto;
}

.multiSelect-selected {
	width: 16px;
	height: 16px;
	background: var(--secondary-color) 0% 0% no-repeat padding-box;
	border: 1px solid var(--sk-border-color);
	border-radius: 6px;
	opacity: 1;
	margin-right: 10px;
}

.filter-checkmark-container-selected {
	min-width: 12px;
	height: 12px;
	margin-right: 6px;
	padding: 1px;
	background: var(--primary-color) 0% 0% no-repeat padding-box;
	border: 1px solid var(--secondary-color);
	border-radius: 2px;
	opacity: 1;
}

.filter-checkmark-container-unselected {
	min-width: 12px;
	height: 12px;
	margin-right: 6px;
	padding: 1px;
	background: var(--primary-color) 0% 0% no-repeat padding-box;
	border: 1px solid var(--sk-border-color);
	border-radius: 2px;
	opacity: 1;
}

.filter-checkmark-container-unselected .filter-checkmark-tick {
	display: none;
}

.filter-checkmark-tick {
	width: 4px;
	height: 7px;
	border: solid var(--secondary-color);
	border-width: 0px 1px 1px 0;
	transform: rotate(45deg);
	margin-left: 2px;
	margin-top: 0px;
}

.filter-attribute-dropdown-option:hover {
	background: var(--sk-dropdown-hover-bg-color) 0% 0% no-repeat padding-box;
}




.filter__dropdown__arrow__icon {
	background-image: url(../assets/phoenix/old-icons.svg);
	background-position: -453px -67px;
	width: 20px;
	height: 20px;
	zoom: 0.6;
	transition: all .5s ease;
	transition: all .5s ease;
}

.filter-attribute-dropdown-child-icon {
	/* background-image: var(--iconUrl); */
	/* background-position: -718px -1107px; */
	rotate: 90deg;
	width: 20px;
	height: 20px;
	zoom: 0.6;
	transition: all .5s ease;
	rotate: 360deg;
	transition: all .5s ease;
	margin-left: 5px;
	margin-right: 10px;
}

.pointer-events-none {
	pointer-events: none !important;
	opacity: 0.5;
}

.pointer-events-disable {
	pointer-events: none !important;
}

.pointer-events-none .sk-filter-accounts-container-label,
.pointer-events-none .sk-filter-products-container-label,
.pointer-events-none .smartDatepicker-input {
	color: var(--desable-color)
}
.pointer-events-none .smartDatepicker-input{
	padding-left: 3px;
}

.pointer-events-none.filter-attribute-container {
	color: var(--desable-color);
	background-color: var(--sk-border-color);
}

.pointer-events-none .filter-attribute-container-label {
	color: var(--desable-color);
}

.pointer-events-none .sk-filter-attribute-container-filter {
	background-position: -125px -111px;
	background-image: var(--iconUrl);
}

.pointer-events-none .sk-filter-accounts-container-icon {
	background-position: -171px -108px;
}

.pointer-events-none .sk-filter-products-container-icon {
	background-position: -209px -110px;
}

.pointer-events-none .sk-filter-service-container-icon {
	background-position: -668px -1009px;
}

.pointer-events-none .smartDatepicker-input-icon {
	background-position: -198px -444px;
	zoom: 0.6;
}

.date-filter-search-arrow-icon {
	background-image: url(../assets/phoenix/old-icons.svg);
	background-position: -453px -67px;
	rotate: 90deg;
	width: 20px;
	height: 20px;
	zoom: 0.6;
	margin-top: -25px;
	position: absolute;
	right: 50px;
	transition: all .5s ease;
	padding-left: 5px;
}

.date-filter-search-arrow-icon-rotate {
	background-image: url(../assets/phoenix/old-icons.svg);
	background-position: -453px -67px;
	width: 20px;
	height: 20px;
	zoom: 0.6;
	margin-top: -25px;
	position: absolute;
	right: 50px;
	rotate: 270deg;
	transition: all .5s ease;
}

.sk-smart-backgroung-light-purple-img {
	background: var(--sk-dashboard-bg-img-2) 0% 0% no-repeat padding-box;
	width: 100%;
	position: relative;
}

.sk-smart-backgroung-light-bg-height {
	height: 81vh;
	position: relative;
}


.sk-smart-dashboard-container-content::-webkit-scrollbar {
	width: 6px;
	background: transparent;
	height: 4px;
}

.sk-smart-dashboard-container-content::-webkit-scrollbar-thumb {
	background: var(--primary-color);
	border-radius: 6px;
	border: 3px solid var(--thumbBG);
	height: 30px;
}

.sk-smart-dashboard-container-content::-webkit-scrollbar-thumb:hover {
	background: var(--primary-color);
}

.sk-smart-dashboard-container-content::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
	border-radius: 6px;
}

/* .filter-dropdown-search-box-options::-webkit-scrollbar-track {
        border-radius: 6px;
    }
    .filter-dropdown-search-box-options::-webkit-scrollbar-thumb {
        background: var(--primary-color);
        border-radius: 6px;
        border: 3px solid var(--sk-border-color) ;
    } 
    .filter-dropdown-search-box-options::-webkit-scrollbar {
        width: 4px;
        background: transparent;
    } */

.sk-smart-backgroung-dark-purple-img-1 {
	background: var(--sk-dashboard-bg-img--colour-3) 0% 0% no-repeat padding-box;
	height: 48px;
	position: sticky;
	width: 100%;
	padding: 0px;
	top: 0px;
	left: 0px;
	/* z-index: 9999; */
	/* opacity: 0.6; */
}

.sk-smart-backgroung-dark-purple-img-2 {
	background: var(--sk-dashboard-bg-img--colour-3) 0% 0% no-repeat padding-box;
	height: 68px;
	position: absolute;
	width: 100%;
	padding: 0px;
	top: 48px;
	left: 0px;
}

.sk-smart-pc-backgroung-dark-purple-img {
	background: var(--sk-dashboard-bg-img-1) 0% 0% no-repeat padding-box;
	height: 115px;
	position: absolute;
	width: 100%;
	padding: 0px;
	top: 0px;
	left: 0px;
	opacity: 0.6;
}

.sk-smart-dashboard-container-content {
	position: relative;
	max-height: 70vh;
	overflow: auto;
	margin-right: 10px;
	padding: 0px 33px 0px 24px;
}

.grid_table_container {
	position: relative;
	overflow-x: hidden;
}
.sk_cutomer_account_listing--container .grid_table_container {
	overflow: hidden;
}

.filter-share-menu-items-wrap {
	padding: 0px 10px 10px 10px;
}

.filter__dropdown__arrow__icon_rotate {
	rotate: 90deg;
	transition: all .5s ease;
}

.sk-smart-backgroung_header_name {
	color: var(--primary-color);
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.42px;
	font-family: var(--sk-font-sans);
}

.sk-smart-pc-backgroung_header_name {
	position: absolute;
	top: 12px;
}

.sk-smart-backgroung_header_name .bold {
	font-weight: 600;
}
.sk-smart-backgroung_header_name .under-line {
text-decoration: underline;
margin-left: 5px;
}

.xia-dashboard-header-name {
	position: absolute;
	top: 15px;
	z-index: 9999;
	margin-left: 24px;
}

.kommunity__detail-header-name {
	padding: 12px 10px 10px 24px;
	justify-content: space-between;
}

.sk_smart__detail-header-name {
	padding: 10px 10px 10px 24px
}

.filter-attribute-dropdown-option-lable {
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-size: 15px;
}

.globalfilter_appy_btn_container {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.multiSelectDropdown .globalfilter_appy_btn_container  {
	margin-top: 0px !important;
}

.sk_feature_attribute_container {
	height: 26px;
	min-width: 75px;
	display: flex;
	align-items: center;
	background: var(--global-filter-Atributes-bg);
	justify-content: center;
	border-radius: 15px;
	/* padding-top: 5px; */
	cursor: pointer;
}

div::-webkit-scrollbar {
	width: 4px;
	background: transparent;
	height: 4px;
	/* border-radius: 0px 0px 8px 8px; */
	/* border: 1px solid green; */
}

div::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
}
div.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb{
	background: var(--sk-dashboard-bg-img--colour-3);
}
div::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

div::-webkit-scrollbar-track {
	background: #fff;
	/* margin-bottom: 10px; */
	border-radius: 6px;
	/* border: 1px solid black; */
}

div.multiSeclectDropdown_overlay::-webkit-scrollbar-track {
	background: #fff;
	margin-bottom: 10px;
	border-radius: 6px;
}

.hide1 {
	display: none !important;
}

.smart__grid__table__add__column__icon__container {
	width: 100%;
	/* margin-top: 0.5rem; */
	height: 100%;
	display: flex;
	align-items: center;
}

.smart__grid__table__add__column__icon {
	zoom: 0.8;
	/* position: absolute; */
	/* top: 11px;
  left: 18px; */
	background: var(--iconUrl);
	background-position: -380px -758px;
	aspect-ratio: 1;
	height: 25px;
	width: 40px;
	display: flex;
	cursor: pointer;
}

.add--column--icon {
	zoom: 0.8;
	position: absolute;
	top: 11px;
	left: 18px;
	background: var(--iconUrl);
	background-position: -382px -758px;
	aspect-ratio: 1;
	height: 25px;
	width: 40px;
	display: flex;
	cursor: pointer;
}

.add_column_container .smart__grid__table__add__column__icon {
  zoom: 0.8;
  /* position: absolute; */
  /* top: 11px;
  left: 18px; */
  background: var(--iconUrl);
  background-position: -395px -758px;
  aspect-ratio: 1;
  height: 25px;
  width: 40px;
  display: flex;
  cursor: pointer;
}


.multiSeclectDropdown_overlay .MuiPopover-paper {
	max-height: 300px;
	overflow: hidden;
	border-radius: 0px 0px 13px 13px;
}

.smart__konversations__account__select{
	max-height: none !important;
	overflow: hidden !important;
}

.multiSeclectDropdown_overlay {
	/* top: 5% !important; */
	z-index: 9999;
	/* max-height: 320px;
	overflow-y: auto; */
	overflow: hidden;
	background-color: #fff;
	font-size: 12px;
	text-decoration: none;
	font-family: var(--sk-font-urbanist-family) !important;
	margin: 0;
	padding-top: 0px;
	min-width: 200px;
	max-width: 200px;
	border-radius: 8px;
	box-shadow: 0px 3px 6px #00000029;
	text-align: left;
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 8px 8px;
}

.filter-dropdown-search-box_contaciner {
	background: #fff;
	position: sticky;
	top: 0px;
	padding: 10px;
	z-index: 9999;
	padding-bottom: 0px;
}

.multiSeclectDropdown_overlay .filter-dropdown-search-box-container {
	margin: 0px;
}

.multiSeclectDropdown_overlay .filter-dropdown-search-box-options {
	margin: 10px;
	/* margin-top: 0px; */
}

.multiSeclectDropdown_overlay .filter-dropdown-search-box {
	margin-bottom: 0px;
}

.custom__column__cell__date-picker .react-datepicker__month-container {
	float: initial;
}

.custom__column__cell__footer {
	height: 45.549px;
	background-color: var(--side-pop-up-footer-color);
	padding-right: 7px;
}

.custom__column__cell__date-wrapper .react-datepicker {
	/* position: absolute; */
}

.custom__column__cell__date-wrapper {
	/* position: relative; */
}

.custom__column__cell_datepicker_overlay .react-datepicker {
	/* position: absolute; */
	/* border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px; */
}

.custom__column__cell_datepicker_overlay .react-datepicker__month-container {
	float: initial;
}

.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker {
	margin-top: 0px;
	margin-left: -120px;
}

.task_calendar_form__date__picker.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--today {

	background-color: var(--sk-dropdown-hover-bg-color) !important;
	border-radius: 23px;
	color: var(--sk-datepicker-month-color) !important;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
	font-weight: bold;
}

.task_calendar_form__date__picker.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--today.react-datepicker__day--selected {
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
	border-radius: 23px;
}

.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--today {

	color: #000 !important;
	background: var(--sk-dropdown-hover-bg-color);
	border-radius: 23px;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
	font-weight: bold;
}


.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--selected {
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
	border-radius: 23px;
}

.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--keyboard-selected {
  color: #000 !important;
	background: none !important;
	display: inline-block;
	width: 1.7rem;
	line-height: 1.7rem;
	text-align: center;
	margin: 0.166rem;
	font-weight: 500;
}

.custom__column__cell__footer .sk-smart-uiBtn_wrap {
	height: 100%;
}

.custom__column__cell__footer .sk-smart-uiBtn_wrap .w-100 {
	height: 100%;
	align-items: center;
}

.custom__column__cell__footer .skSecondaryBtn {
	margin-right: 10px;
	background: transparent;
	padding-bottom: 3px;
	padding-top: 3px;
	min-width: 5rem;
}

.custom__column__cell__footer .skPrimaryBtn {
	padding-bottom: 3px;
	padding-top: 3px;
	min-width: 5rem;
}


/*Search css*/
.smart__sk__search__container .sk_smart_seach_icon {
	background-position: -82px -566px;
	position: relative;
	background-color: transparent;
	width: 32px;
	height: 32px;
	overflow: hidden;
	cursor: pointer;
	left: 8px;
	top: 0px;
	scale: 0.8;
	appearance: none;
	border: 0 none;
	text-indent: -200px;
	margin-right: 5px;
}

.smart__sk__search__container .sk_icon{
	background-image: var(--iconUrl) !important;
}

.smart__sk__search__container input[type=text] {
	color: var(--primary-color);
	border-radius: 4px;
	padding: 0 30px 0 12px;
	position: absolute;
	background-color: transparent;
	border: 0 none;
	cursor: pointer;
	font-size: 12px;
	font-family: var(--sk-font-urbanist-family);
	z-index: 1;
	right: 30px;
	padding-right: 47px;
	position: absolute;
	width: 0px;
}

/* .smart__sk__search__container input[type=text]:focus {
	width: 16.5% !important;
	border: 1px solid #E5E5E8;
	margin-right: 1.1rem;
} */

.smart__sk__search__container .onFocus {
	width: 16.5% !important;
	border: 1px solid #E5E5E8 !important;
	margin-right: 1.1rem;
}

.smart__sk__search__container .onBlur ::placeholder {
	color: white;
}


.smart__sk__search__container .onFocus ::placeholder {
   color: white;
}

.smart__sk__search__container input::-webkit-input-placeholder {
	/* Edge */
	text-indent: 0px !important;
	color: white !important;
	font-family: var(--sk-font-urbanist-family);
}

.smart__sk__search__container input[type=text]:focus::placeholder,
.smart__sk__search__container input[type=search]:focus::placeholder {
	text-indent: 0px !important;
	color: white !important;
	font-family: var(--sk-font-urbanist-family);
}

.smart__sk__search__container input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	text-indent: 0px !important;
	color: white !important;
	font-family: var(--sk-font-urbanist-family);
}

.smart__sk__search__container input::placeholder {
	text-indent: 0px !important;
	color: white !important;
	font-family: var(--sk-font-urbanist-family);
}

.carousel-button-group .leftArrow{
	background-image: url("../assets/phoenix/old-icons.svg");
	background-position: -412px -60px;
	width: 30px;
	height: 30px;
	display: block;
	zoom: 0.8;
	margin-top: 5px;
	rotate: -270deg;
}
.carousel-button-group .rigthArrow{
	background-image: url("../assets/phoenix/old-icons.svg");
	background-position: -412px -60px;
	width: 30px;
	height: 30px;
	display: block;
	zoom: 0.8;
	/* margin-top: 5px; */
	rotate: -90deg;
}
.carousel-button-group{
	/* height: 50px; */
}
.carousel-button-group .prevBtn {
	width: 0;
	height: 100%;
	left: 20px;
	top: 13%;
	position: absolute;

}
.carousel-button-group .nxtBtn {
	width: 100%;
	height: 100%;
	left: 51%;
	top: 23%;
	position: absolute;
}

.multiSeclectDropdown_overlay.gridColUpdate.add__info__tooltip{
	top: 0px !important;
	left: -4% !important;
	/* max-height: 275px; */
}
.filter_attribute_condition_name--container{
	font-weight: 600;
	margin-left: 5px;
}
/* .multiSeclectDropdown_overlay.gridColUpdate.add__info__tooltip[x-placement^=top]{
	height: 200px;
} */
.account-selection--container{
	margin-bottom: 10px;
}

.sk_tab_filter_tab_unselected .sk_tab_filter_tab_title {
	color: #707070 !important;
}