.smart_actions_create_Btn .create_text{
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 600;
}
.smart_actions_sub_Btn .sub_text{

    font-size: 12px;
    font-weight: 400;
    color: var(--smart-insights-and-konversations-intelligence-heading-color);
    letter-spacing: 0.36px;
}

.smart_actions_wrap{
    font-size: 12px;
}
.smart_actions_create_Btn{
    background-color: var(--sk-actions-create);
    color:var(--primary-color);
    padding:  10px;
    cursor: pointer;
}
.smart_actions_sub_Btn{
		padding: 7px;
    color: var(--sk-black-text-color);
    cursor: pointer;
}
.smart_actions_sub_Btn:hover{
	background-color: var(--action-btn-hover-color);
}
.smart_action_tp_icon{
    width: 25px;
    height: 25px;
    background-image:  var(--iconUrl);
    zoom: 0.75;
    background-position: -268px -961px;
}
.smart_actions_create_icon{
    width: 30px;
    height: 25px;
    background-image:  var(--iconUrl);
		background-position: -170px -960px;
    cursor: pointer;
    margin-top:-5px
}
.smart_actions_sub_Btn_wrap{
    /* padding:10px 10px 0px 10px */
}
.smart_action_alert_icon{
	background-position: -307px -961px;
    width: 25px;
    height: 25px;
    background-image:  var(--iconUrl);
    zoom: 0.75;
}
.smart_action_task_icon{
	background-position: -227px -961px;
    width: 25px;
    height: 25px;
    background-image:  var(--iconUrl);
    zoom: 0.75;
}

.smart__actions__popover .MuiPaper-root{
    top: 5.813rem !important;
}

.global-filter-plus_icon-disabled{
pointer-events: none;
opacity: 0.7;
}