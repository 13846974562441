.smartDatepicker {
    margin-right: 10px;
    font-family: var(--sk-font-sans);
    justify-content: flex-end;
    display: flex;
    align-items: center;
		margin-top: -20px;
}

.smartDatepicker-input-wrap {
    font-weight: 400;
    min-width: 220px;
    font-size: 12px;
    border: 1px solid #e5e5e8;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 5px;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    height: 30px;
}
.sk__date__filter__arrow__icon__container-arrow {
    position: absolute;
    background-image: var(--iconUrl);
		background-position: -515px -570px;
    /* rotate: 90deg; */
		width: 15px;
		height: 20px;
		right: 2px;
    zoom: 0.8;
		top: -10px;
}
.sk-smart-datepicker-containter{
    margin-top: 10px;
}
.sk-smart-date-fliter-container .filter-dropdown-header-container{
    margin: 0px;
    margin-top: 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--sk-border-color);
}
.sk-smart-date-fliter-container  .filter-dropdown-search-box-container{
    margin: 0px;
}
.sk-smart-date-fliter-container{
    margin: 15px;
}
.sk-smart-date-fliter-container .filter-attribute-dropdown-option{
    /* padding-left: 0px; */
    color:var(--smart-insights-and-konversations-intelligence-heading-color);
    font-size: 14.5px;
    letter-spacing: 0.3px;
}
.sk-smart-date-fliter-container .filter-dropdown-header-label{
    margin-left: 0px;
    letter-spacing: 0.36px;
    color: var(--sk-datepicker-month-color);
    font-size: 14.5px;
    text-transform: capitalize;

}
.smartDatepicker-input-icon {
    background-image: var(--iconUrl);
    background-position: -290px -690px;
		min-width: 30px;
    height: 30px;
    zoom:0.8;
    /* background-color: #fff; */
    margin-top: 2px;
}

.smartDatepicker-input {
    padding-left: 0rem;
    font-size: 14px;
    color: var(--sk-nav-color);
    font-weight: 400;
    white-space: nowrap;
    font-family:   var(--sk-font-sans);
    letter-spacing: 0.42px;
    padding-right: 0.938rem;
}

.react-datepicker {
    font-family: var(--sk-font-sans);
    background: var(--global-filter-Atributes-bg);
    border: 1px solid var(--sk-border-color);
    /* border-radius: 10px; */
		font-size: 11px;
}

.custom__column__cell_datepicker_overlay.gridTableDatepicker .react-datepicker .react-datepicker__day--keyboard-selected.react-datepicker__day--today{
	background: var(--sk-select-color) !important;
	color: var(--sk-black-text-color) !important;
}

.react-datepicker__current-month {
    color: var(--sk-datepicker-month-color);
    font-weight: 500;
    padding-bottom: 5px;
		font-size: 14px;
		padding-top: 3px;
}
.react-datepicker .react-datepicker__header{
    padding-top: 5px;
    border-bottom: none;
    background: transparent;
}
.react-datepicker .react-datepicker__year-read-view--down-arrow, 
.react-datepicker .react-datepicker__month-read-view--down-arrow, 
.react-datepicker .react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker .react-datepicker__navigation-icon::before{
    border-width: 2px 2px 0 0;
}

.react-datepicker__day-name {
    color: var(--sk-black-text-color);
}

.react-datepicker__navigation {
    margin-top: 0.313rem;
}
/* .react-datepicker__day--keyboard-selected{
    border-radius:23px
} */
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-range{
    background:  var(--sk-select-color);
    color:var(--sk-black-text-color);
}

.react-datepicker .react-datepicker__day--selected{
    /* background:  var(--sk-select-color) ; */
		background: var(--secondary-color) !important;
    color:var(--primary-color) !important;
    border-radius: 23px;
}
.react-datepicker.react-datepicker__day--in-selecting-range,
.react-datepicker .react-datepicker__day--in-selecting-range
{
    background:var(--sk-dropdown-hover-bg-color);
		color: #fff;
}
/* .react-datepicker div[class*="selected"] 
	{
    background: var(--secondary-color) !important;
    color:var(--primary-color) !important;
    border-radius: 23px;
} */

.react-datepicker__day--today{
	background: var(--sk-dropdown-hover-bg-color) !important;
	/* color:var(--primary-color) !important; */
	border-radius: 23px;
}

.react-datepicker .react-datepicker__day--disabled{
	color: #ccc !important;
}


.react-datepicker__day--keyboard-selected{
	/* background:transparent!important; */
}
.react-datepicker .react-datepicker__day--range-end,
.react-datepicker .react-datepicker__day--range-start{
    /* background:  var(--secondary-color) !important;  */
    /* background: var(--sk-dashboard-bg-img--colour-3) 0% 0% no-repeat padding-box !important; */
    color:var(--primary-color) !important;
}
.smart__form__calendar__popper .react-datepicker__year-option:first-of-type,
.react-datepicker__year-option:last-of-type{
    display: block !important;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__year-option:last-of-type{
    display: none;
}
.react-datepicker  .react-datepicker__day:hover, 
.react-datepicker  .react-datepicker__month-text:hover,
.react-datepicker  .react-datepicker__quarter-text:hover, 
.react-datepicker  .react-datepicker__year-text:hover{
    background-color:var(--sk-dropdown-hover-bg-color);
    border-radius:23px;
    color: var(--sk-datepicker-month-color);
}

.react-datepicker .react-datepicker__day--selected, .react-datepicker .react-datepicker__day--in-range{
    border-radius: 23px;
}
.react-datepicker__day-names{
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    margin-right: 2px;
    margin-left: 2px;
}
.filter-attribute-dropdown-option-selected-lable{
    color: var( --sk-smart-datepicker-border-color);
}

