.sk--walk-through-heading-text{
	 display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 4px;
    padding: 5px 10px;
		color: #fff;
		font-size: 16px;
}

.sk--walk-through-btn--wrap a{
	 padding: 3px 25px;
}
.sk--walk-through-btn--wrap a:hover{
	color: #fff;
}
.sk--walk-through-heading--wrap{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-bottom: 8px;
}

.sk--walk-through-btn--wrap{
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

.sk--walk-through--wrap{
	margin: -15px;
}

.sk--walk-through-content-text--wrap{
	font-size: 14px;
	font-weight: 500;
}