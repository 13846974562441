.skCheckInput {
    /* position: absolute; */
    opacity: 0;
  }
  .skCheckInput + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
		max-width: 80%;
		margin-bottom: 0px;
  }
	.multiSelectDropdown  .skCheckInput + label{
		width: 80%;
	}
.skCheckInput + label{
		width: 80%;
	}
  .survey-settings .skCheckInputDesable{
    cursor:not-allowed !important;
    opacity: 0.7;
  }
  .desableVerticalAlignment{
    cursor:not-allowed !important;
    opacity: 0.5;
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 100%;
    z-index: 999;
  }
  .skCheckInput + label:before {
    content: "";
    margin-right: 0.3125rem;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid var(--sk-border-color);
    margin-top: 3px;
		margin-left: -20px;
  }
  .skCheckInput:hover + label:before {
    background: white;
    border: 1px solid var(--sk-border-color);
  }
  /* .skCheckInput:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  } */
  .skCheckInput:checked + label:before {
    background: var(--primary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 2px;

  }

	.multiSelectDropdown  .skCheckInput + label{
		width: 80%;
	}
	.skCheckInputDesable:checked + label:before {
    border: none !important;

  }
  .skCheckInput:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  .skCheckInput:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  .skCheckInput:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    margin-right: 5px;
    left: 5px;
    width: 5px;
    height: 9px;
    border: solid var(--secondary-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    margin-bottom: -10px;
    border-width: 0 1.5px 1.5px 0 !important;
    left: 4.5px !important;
		margin-left: -20px;
  }

	.smartCheckBox{
		display: flex;
	}

	.skCheckInputDesable:checked + label:after{
     left: 3.5px !important;
		 top: 0px;
	}
  
  .sk-checkbox{
      margin-bottom: 5px;
  }
  .sk-checkbox label{
line-height: 13px;
display: flex!important;
      justify-content: center;
      margin-bottom: 0;
  }
  
  .unstyled {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

	.tp-index:before{
     margin-top: -25px !important;
	}

	.tp-index::after{
		margin-top: -28px !important;
		margin-left: -11px !important;
 }
  
 .drop_down_error_container {
	padding: 7px 7px 0px 12px;
  color: red;
	font-size: 11px;
	font-weight: 450;
 }