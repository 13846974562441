.multi__select__dropdown__icon__container {
	position: relative;
}
.template_Input_wrapper .multi__select__dropdown__icon__container {
	position: relative;
	width: 10%;
}
.template_Input_wrapper .multi__select__dropdown__icon__container-plus__icon {
	position: absolute;
	margin-right: 5px;
  margin-left: -5px;

}
.multi__select__dropdown__icon__container-plus__icon {
	/* position: absolute; */
	display: block;
	width: 20px;
	height: 20px;
	background: var(--iconUrl);
	background-position: -215px -285px;
	/* margin-left: -2.188rem;
	margin-top: -0.75rem;
	padding-right: 1.9rem; */
	zoom: 0.7;
	cursor: pointer;
}

.smartCheckBox__label .smart__react__select__parent__account__icon {
	left: 6px !important;
}

.multi__select__dropdown__icon__container-arrow__icon {
	/* position: absolute; */
	display: block;
	width: 20px;
	height: 20px;
	background: var(--iconUrl);
	background-position: -510px -570px;
	/* margin-left: -2.4rem;
	margin-top: -0.8rem; */
	/* zoom: 0.8; */
	cursor: pointer;
}
.smart__input__btn_conatiner .smart__input__btn{
	border: none;
	border-bottom-right-radius: 0px;
}
.smart__input__btn_conatiner{
	border: 1px solid var(--sk-border-color);
	display: flex;
	border-radius: 4px;
	background-color: var(--primary-color);
}

.smart__react__multi__select__wrapper {
	position: relative;
	display: flex;
	align-items: center;
}

/* .smart_react_required_select {
	border-bottom: 1px solid red;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
} */

/* react select Style Starts */

.smart__react__select__control {
	border-radius: 4px 4px 0px 0px;
	padding-left: 8px;
}

.smart_react_required_select .smart__react__select__control{
	border-bottom: 1px solid red;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.smart_react_required_select.smart_form_editor {
	border-bottom: 1px solid red;
}

.smart_react_required_select .smart__react__select__control:hover{
	border-bottom: 1px solid red;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.smart__react__select__menu {
	border-radius: 0px 0px 13px 13px !important;
	border: 1px solid var(--sk-smart-datepicker-border-color);
	transition: opacity 331ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.smart__react__select__option {
	cursor: pointer !important;
	font-family: var(--sk-font-urbanist-family);
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 400;
	padding: 0.5rem !important;
}

.smart__react__select__option--is-focused {
	background: var(--sk-dropdown-hover-bg-color) 0% 0% no-repeat padding-box;
	background-color: white;
	font-family: var(--sk-font-urbanist-family);
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	font-weight: 400;
	padding: 0.5rem !important;
}



.smart__react__select__menu {
	border-radius: 0px 0px 13px 13px !important;
	overflow-y: auto;
}

.smart__react__select__menu::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.smart__react__select__menu::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 13px;
	border: 4px solid var();
}

.smart__react__select__menu::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

.smart__react__select__menu:-webkit-scrollbar-track {
	background: #fff;
	border-radius: 13px;
}

.smart__selected__value__wrap {
	padding: 5px;
	width: 90%;
}

.smart__react__select__indicator {
	display: none !important;
}

.smart__react__select__multi-value {
	border-radius: 23px !important;
	perspective: 1px;
	overflow: hidden;
	padding-right: 10px;
	max-height: 30px;
	/* padding: 0px 10px; */
	background-color: var(--sk-smart-react-select-focus-color) !important;
	color: var(--smart-insights-and-konversations-intelligence-heading-color) !important;
	min-width: none;
	max-width: 380px;
	/* width: auto; */
}

.smart__react__select__multi-value__label {
	font-family: var(--sk-font-urbanist-family), Medium;
	font-size: 0.875rem !important;
	font-weight: 500;
	display: flex;
}

.smart__react__select__multi-value__remove {
	color: var(--smart-insights-and-konversations-intelligence-heading-color) !important;
	background-color: var(--sk-smart-react-select-focus-color) !important;
}

/* .smart__react__select__single-value {
	padding: 5px;
} */

.smart__react__select__input-container {
	margin: 4px !important;
	margin-left: 0px !important;
}

.acctount_listing_financial_model--container .smart__react__select__input-container {
	margin: 0px !important;
}
/* react select Style Ends */


/* No data style starts */
.smart___react__multi__select__no__data__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.smart___react__multi__select__no__data {
	font-family: var(--sk-font-urbanist-family);
	font-size: 0.875rem !important;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	padding: 1rem 0rem;
}

/* No data style ends */

/* Formatting label style starts */
.smart__react__select__label__wrap {
	display: flex;
	width: 100%;
	align-items: center;
}

.smart__react__select__profile__icon__wrap {
	/* width: 10%; */
	margin-top: 0px;
	margin-right: 0.375rem;
	margin-left: -4px;
	border-radius: 23px;
	display: flex;
	height: 27px;;
}

.smart__react__select__option--is-disabled {
	cursor: default !important;
	opacity: 0.5;
}

.smart__react__select__value-container {
	max-width: 90% !important;
	padding: 2px !important;
	/* min-height: 52px; */
	max-height: 150px;
	overflow-y: auto !important;
}
.smart__react__select__profile__icon__container {
	padding: 5px;
	border-radius: 23px;
	background-color: var(--smart-konversations-label-color);
	width: 27px;
	height: 27px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.smart__react__select__profile__icon {
	color: #fff;
	text-transform: uppercase;
	height: 27px;
	display: flex;
}

.smart__react__select__parent__account__container {
	position: relative;
	width: 6%;
	display: flex;
	margin-top: -1.063rem;
}

.smart__react__select__parent__account__icon {
	display: block;
	background: var(--iconUrl);
	background-position: -557px -1069px;
	width: 1.375rem;
	height: 1.375rem;
	zoom: 0.8;
	margin-left: -0.625rem;
}

.sk_account_listing--container .smart__react__select__parent__account__icon__second-attempt{
	margin-right: 0px;
}
.sk_account_listing--container .smart__react__select__child__account__icon__second-attempt{
	background-position: -508px -1068px;
}
.sk_account_listing--container .child_account_type .name{
	padding-left: 0px;
}
.viewAccountsDetailsPopupWrap .smart__react__select__child__account__icon__second-attempt{
 margin-right: 5px;
}
.child_account_type .name{
	padding-left: 5px;
}
.smart__react__select__parent__account__icon__second-attempt{
	background: var(--iconUrl);
	background-position: -557px -1069px;
	min-width: 1.5rem;
	height: 1.375rem;
	scale: 0.7;
	margin-right: 2px;
	/* rotate: 180deg; */
}
.smart__react__select__child__account__icon__second-attempt{
	background: var(--iconUrl);
	background-position: -512px -1068px;
	min-width: 1.5rem;
	height: 1.375rem;
	scale: 0.7;
	margin-right: 2px;
	/* rotate: 180deg; */
}

.smart__react__select__profile__icon-text {
	font-size: 0.875rem !important;
	font-family: var(--sk-font-urbanist-family);
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}





.smart__react__select__profile__icon-image {
	border-radius: 23px;
	width: 27px;
	height:27px;
}

.react-tel-input .selected-flag{
	width: 43px !important;
}

.smart__react__select__label {
	width: 100%;
	font-size: 13px !important;
	font-weight: 400;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
	display: flex;
	align-items: center;
	/* margin-top: 5px; */
	min-width: 5rem;
	/* margin-left: 10px; */
	z-index: 100;
}

.smart__react__select__label span{
	/* width: 100% !important; */
} 

/* Formatting label style ends */

.smart__react__select__indicators {
	margin-right: 18px;
}

.manage__users__tabel .smart__react__select__indicators {
	margin-right: 6px;
}

.task-details-popup-header-select .smart__react__select__indicators{
	margin-right: 5px;
}
.task-details-popup-header-select .smart__react__select__indicator{
	display: block !important;
	padding: 0px;
}

.custom_menu_option_info_wrap {
	background: var(--iconUrl) no-repeat -545px -627px;
	width: 22px;
	height: 22px;
	margin-left: 6px;
	display: inline-block;
	zoom: 0.7;
}

.smart__task__popup-form.summary.playbook__template__smart__form .smart__react__select__value-container .smart__react__select__single-value {
	font-size: 13px !important;
	font-weight: 400;
	color: var(--smart-insights-and-konversations-intelligence-heading-color);
}