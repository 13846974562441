
.smart-modal-popup{
	max-width: 600px !important;
	margin: 0 auto !important;
	height: 100%;
}
.exapnsionAnalserSummaryDetailsDialog.smart-modal-popup{
	max-width: 80% !important;
}
.productLineDetailsDialog.smart-modal-popup{
	max-width: 70% !important;
}
.successplanObjectiveDialog.smart-modal-popup{
	max-width: 70% !important;
}
.successplanObjectiveDialog.smart-modal{
	padding: 0px;
}
.smart-modal-popup .modal-content{
  width: auto;
	height: auto;
}
.viewMoreDetailsDialog.smart-modal-popup .modal-content{
	width: 100%;
}

.smart-modal .sk-pop-wrapper{
	padding:0.5rem 1.1rem .5rem 1.1rem;
	}
	


.modal-open .modal {
	overflow-x: hidden;
	overflow-y: hidden !important;
	/* z-index: 100000000000; */
	/* margin-left: 110px; */
}

.smart-modal-footer {
	background-color: var(--side-pop-up-footer-color);
}

.smart__modal-footer {
	display: flex;
	background-color: var(--side-pop-up-footer-color);
	width: 100%;
	justify-content: flex-end;

}

.smart-modal{
margin: 0;
padding: 0;
font-family: var(--sk-font-urbanist-family);
/* min-width: 35rem; */
}

.playbook__email__modal {
	max-width: 78%!important;
	padding: 0!important;
	overflow-x: hidden;
	margin: 0 auto!important;
}

.smart-modal .integration_messagesForAppIntegration {
	color: #000000;
	font-size: 15px !important;
	font-weight: 400;
	padding: 10px 0px 17px 15px;
	line-height: 21px;
}

.smart-modal .appIntPopup {
	border: 1px solid #FFB13D !important;
	height: 37px;
	margin-left: 5px;
	margin-right: auto;
	margin-bottom: 12px;
	font-size: 13px;
	background: #fff;
	text-align: center;
	border: 1px solid #FFB13D;
	color: #FFB13D;
	padding: 7px 0px;
	cursor: pointer;
	width: 125px !important;
	font-weight: 600;
}

.smart-modal .appIntPopup1 {
	height: 37px;
	margin-left: 5px;
	margin-bottom: 12px;
	font-size: 13px;
	background: #fff;
	text-align: center;
	border: 1px solid #FFB13D;
	color: #FFB13D;
	padding: 7px 0px;
	cursor: pointer;
	width: 125px !important;
	font-weight: 600;
}


.smart-modal-header.modal-header {
	font-size: 14px;
	align-items: center!important;
	background-color:var(--sk-modal-header-color);
	border-bottom: 1px solid #dfe1e3!important;
	width: 100%;
	padding: .8rem 1.5rem!important;
	font-size: 15px !important;
	font-weight: 500;
	font-family: var(--sk-font-urbanist-family);
  color: var(--smart-insights-and-konversations-intelligence-heading-color);
	margin: 0;
	height: 45px;
	border-radius: 0px;
	line-height: 1em;
	letter-spacing: 0.5px;
	align-items: center;
}
.a360_view_more_details--conatiner .skPrimaryBtn{
	padding: 3px 25px
}

.smart-modal-header .modal-title{
	line-height: 1.5rem;
	font-size:1rem;
}


.smart-modal-header .close_btn_pop{
			opacity: .5;
			margin: 0;
	}

 
	
	
.smart-modal-header .close_btn_pop:hover{
			opacity: 1;
	}

.modal[aria-labelledby="playbook__touchpoint_link_view--modal"]{
		z-index: 9999;
}
.playbook__touchpoint_link_view--modal.smart-modal{
	max-height: 50vh;
	overflow: auto;
}

.smart-modal .automated-action-form{
	padding: 5px 0px 0px;
	margin-bottom: -5px;
}

.smart-modal .automated-action-form li {
	display: flex;
	align-items: center;
	padding: 5px 0;
}

.smart-modal .form-settings-wrapper{

}


.smart-modal .notice{
	color: #d93131;
	padding: 5px 0px 0px;
	text-align: center;
	font-weight: 400;
	border: 1px solid #f27c7c;
	background: #dba0a0;
}
.smart-modal .modal-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.9375rem 0;
	margin: 0  1.5rem;
	border-top: 1px solid #dfe1e3;
}

.smart-modal .leftBtn{
background-color: #ffffff;
border: 1px solid #FFB13D;
color: #FFB13D;
text-align: center;
font-weight: 600;
padding: 8px 0px;
width: 125px !important;
margin: 2px 5px !important;
height: 34px !important;
font-size: 13px;
margin: 0!important;
}


.sk_Popover{
	transition: all ease-in-out 0.5s;
	position: fixed;
	top: 0;
	z-index: 1040;
	right: 0;
	opacity: 0;
	/* pointer-events: none; */
	box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
	opacity: 1;
	pointer-events: all;
	left: auto !important;
	width: 547px;
}
.sk_Popover .sk_Popover_wrap{
	height: 100vh;
	background: #fff;
}
.sk_Popover .sk_Popover_heading{
	display: flex;
	padding: 20px 20px 20px;
	padding-bottom: 0px;
	justify-content: space-between;
}
.sk_Popover .sk_Popover_heading h5{
	 font-weight:500;
	 font-size:16px;
}
.sk_Popover .css-1vmnjpn-skeletonStyles-Skeleton {
	line-height: inherit;
}

.knk-popover .sk_Popover{
	z-index: 999;
}

.smart-modal-footer {
	border-radius: unset;
}
.smart__modal__blockui {
	display: flex;
	height: 100%;
	flex-direction: column;
}



.smart-modal-backdrop {
	background-color: var(--sk-modal-backdrop-bg);
	opacity: 1.5;
}

.smart_modal_info_icon .phonix_info {
  height: 12px;
	margin-left: 1px;
}

.smart_modal_info_icon {
	margin-left: 3px;
}

.smart-modal-header-lable {
	display: flex;
}

.modal.fade .modal-dialog {
	justify-content: center !important;
	transition: all ease-in-out 0.3s;
}

.kaffeineKarrotdialog .modal-content {
	width: 500px;
	margin: auto;
	padding-bottom: 0;
}

.ck-editor__editable_inline {
	/* height: 150px; */
	height: auto !important;
	min-height: 150px;
	max-height: 220px;
}

.kaffeineKarrotdialog .smart__modal__blockui {
	padding: 10px;
}