.sk__checkbox__btn-container input{
  display: none;
}

.sk__checkbox__btn-container{
  border: 1px solid var(--sk-smart-primary-btn-bg);
  width: fit-content;
  height: 40px ;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #E37D72;
  border-radius: 0.25rem;
  padding: 10px 15px;
  cursor: pointer;
}

.sk__checkbox__btn-container .disabled {
	background-color: var(-internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3)));
	cursor:default !important;
}

.sk__checkbox__btn-container .sk__checkbox__btn-checkmark {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--sk-smart-primary-btn-bg);
  margin-right: 10px;
  /* padding: 3px; */
  /* background-clip: content-box; */
  background-color: #FFFFFF;
  scale: 0.8;
}
/* .sk__checkbox__btn-container .sk__checkbox__btn-checkmark-content{
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
} */

.sk__checkbox__btn-container .sk__checkbox__btn-label{
  margin: 0;
  font-size: 14.5px;
  font-weight: 600;
  font-family: var(--sk-font-urbanist-family);
  cursor : pointer ;
  user-select : none ;
}

.sk__checkbox__btn-container .sk__checkbox__btn-label .disabled{
  cursor : default ;
}

.sk__checkbox__btn-container .sk__checkbox__btn-checkmark-checked{
  background: var(--iconUrl);
  background-position: -474px -1010px;
}



.sk__checkbox_three__dot__menu{
	/* z-index: 999999 !important; */
	position: relative;
}

.sk__checkbox_three__dot__menu.skActions.left{
	left: -6% !important;
}

.sk__checkbox_three__dot__menu.spTaskActions {
	left: 0px !important;
	top: -5px !important;
	cursor: pointer;
}


.sk__checkbox_three__dot__menu .show_list_items_abs_click .show_list_items_abs_menus {
	height: 4.688rem;
	width: 12.313rem;
	/* min-width: 8rem !important; */
	overflow-y: hidden;
	border-radius: 0px 0px 6px 6px !important;
}

.sk__checkbox_three__dot__menu .show_list_items_abs_click .show_list_items_abs_menus ul li {
	padding: 0.438rem 0.563rem;
}

.sk__checkbox_three__dot__menu .three__dot__menu__name {
	margin-left: 1.875rem;
	font-weight: 400;
	font-size: 15.3px;
	letter-spacing: 0.42px;
	font-family: var(--sk-font-urbanist-family), Bold;
}


/* .sk__checkbox__btn-checkmark-content-checked{
  background-color: var(--sk-smart-primary-btn-bg);
} */


/* .sk__checkbox__btn-checked{
  background-color: var(--sk-smart-primary-btn-bg);
  color: #FFFFFF;
} */

/* .sk__checkbox__btn-checked .sk__checkbox__btn-checkmark{
  border-color: #FFFFFF;
} */


