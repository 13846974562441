.sk-smart-uiBtn_wrap .modal-footer {
    padding: 0px;
}
.skSecondaryBtn {
	background-color: var(--primary-color);
	margin-right: 1.1rem;
	/* height: 2.25rem; */
	min-width: 6rem;
	border-radius: 0.25rem;
	color: #E37D72;
	font-size: 14.5px;
	font-weight: 600;
	font-family: var(--sk-font-urbanist-family);
	border: 1px solid var(--sk-smart-primary-btn-bg);
	letter-spacing: 0.9px;
}
input.skSecondaryBtn:focus{
	background-color: #fff;
	border-color: var(--sk-smart-primary-btn-bg);
}
.skPrimaryBtn {
    font-family: var(--sk-font-urbanist-family);
    padding: 0px 7px;
    color: #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--sk-smart-primary-btn-bg);
		min-width: 6rem;
		/* min-height: 2.25rem; */
    letter-spacing: 0.3px;
		height: 100%;
		border-radius: 0.25rem;
    font-size: 14.5px;
    font-weight: 600;
    font-family: var(--sk-font-urbanist-family);
    border: 1px solid var(--sk-smart-primary-btn-bg);
    letter-spacing: 0.9px;
}