.sk-radioBtn .wrap{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 12px 0;
    font-size: 12px;
    position: relative;
}
.sk-radioBtn .skRadioBtnInput{
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}
.sk-radioBtn .design {
    width: 13px;
    height: 13px;
    border: 1px solid var(--radio-btn-unselect-bg-color);
    border-radius: 50%;
    margin-right: 7px;
    position: relative;
    color: var(--radio-btn-lable-color) ;
    font-weight: 400 ;
    font-size: 13px ;
    line-height: 20px;
    cursor: pointer;
    position: absolute;
}
.summaryTab_options_radiobtn.sk-radioBtn .design{
    width: 9px;
    height: 9px;
}
.summaryTab_options_radiobtn.sk-radioBtn input:checked+.design::before{
    transform: scale(.5);
}
.sk-radioBtn input:checked+.design::before {
    opacity: 1;
    transform: scale(.6);
    margin-top: 0px;
    margin-left: 0px;
}

.sk-radioBtn .design::before {
    content: "";
    display: block;
    width: 99%;
    height: 100%;
    border-radius: inherit;
    position: absolute;
    transform: scale(0);
    transform-origin: center center;
    background:  var(--secondary-color);
}
.sk-radioBtn .design::after {
    background: var(--secondary-color) ;
    opacity: .4;
    transition: .6s;
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    position: absolute;
    transform: scale(0);
    transform-origin: center center;
}
.sk-radioBtn label{
    font-size: 12px!important;
    color: var(--radio-btn-lable-color);
    font-weight: 400 !important;
    line-height: 20px;
    margin: 0px;
    margin-left: 18px;
}
.filter-share-menu-items-lists-dropdown-option{
    padding: 8px 6px 5px 9px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    overflow: hidden;
    font-family: var(--sk-font-family);
		cursor: pointer;
}
.filter-share-menu-items-lists-dropdown-option:hover{
    background: var(--sk-dropdown-hover-bg-color) 0% 0% no-repeat padding-box
}
.filter-share-menu-items-lists-dropdown-option-lable{
    color:var( --sk-share-and-konversations-label-color);
		font-family: var(--sk-font-urbanist-family);
}
.filter-share-menu-items-checkmark-container-selected-tick{
    width: 5px;
    height: 9px;
    border: solid var(--secondary-color);
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
    margin-left: 3px;
    margin-top: 0px;
    margin-right: 10px;
}
.filter-share-menu-items-checkmark-container-selected{
    background: var(--sk-dropdown-hover-bg-color) 0% 0% no-repeat padding-box
}