.sidenav {
	width: 240px;
	height: 100vh;
	background-color: var(--primary-color);
	opacity: 1;
	box-shadow: 8px 0px 8px #00000005;
}

.sidenavClosed {
	composes: sidenav;
	width: 58px;
}

.sidenavClosed .sideitem {
	width: 44px;
	padding: 0px 0px 0px 9px;
}

.sideitem {
	top: 252px;
	left: 33px;
	overflow: hidden;
	right: 33px;
	width: 230px;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 9px 0px 9px;
	/* cursor: pointer; */
	color: black;
	text-decoration: none;
	overflow: hidden;
	opacity: 1;
	margin-left: 7px;
}

.linkText {
	padding-left: 16px;
}

.sidenav--activeTab {
	top: 252px;
	left: 33px;
	background: var(--active-row-bg);
	border-radius: 20px;
	opacity: 1;
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
}

.sidenavClosed .sidenav--activeTab {
	background-color: var(--active-row-bg);
	border-radius: 20px;
	width: 44px;
}

.sidenavClosed .sidenav--activeTab i {
	filter: brightness(1) !important;
}

.sidenav--activeTab .sidenav__iconName {
	color: var(--secondary-color) !important;
	opacity: 1 !important;
}

.sideitem:hover {
	top: 252px;
	left: 33px;
	background: var(--active-row-bg);
	border-radius: 20px;
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
}

.menuBtn {
	align-self: center;
	align-self: flex-start;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding-left: 9px;
	margin-right: -20px;
	margin-top: 7px;
	position: relative;
}

.menuBtn_img {
	background: var(--iconUrl);
	background-position: -8px -911px;
	min-width: 30px;
	min-height: 30px;
	position: absolute;
	margin-left: 3px;
	margin-top: 8px;
}

.menuBtn_img_rotate {
	background: var(--iconUrl);
	background-position: -8px -911px;
	min-width: 30px;
	min-height: 30px;
	position: absolute;
	margin-left: -7px;
	margin-top: 2px;
	rotate: 180deg;
}

.logo {
	width: 13%;
}

.sidenav__sideItemWrap {
	overflow: hidden;
	padding-top: 0px;
	margin-top: -3px;
}

.sidenavClosed .logo {
	margin-right: 19px;
	color: var(--secondary-color);
	transition: width 0.3s ease-in-out;
	filter: brightness(0) invert(1);
}

.sidenav__sklogo {
	background-image: var(--logo-url);
	background-repeat: no-repeat;
	height: 30px;
	width: 90%;
	margin-top: 3px;
	zoom: 1;
}

.sidenav__sklogo_playbookStandalone {
	background-image: url("../assets/phoenix/logo_playbookStandalone.svg");
	background-repeat: no-repeat; 
	width: 100%;
	height: 34px;
	margin-left: 9px;
	margin-top: 4px;
}

.sidenav__closed_sklogo {
	background: var(--iconUrl);
	background-position: -433px -906px;
	width: 33px;
	height: 33px;
	margin-left: 9px;
	padding-bottom: 7px;
	display: flex;
	margin-top: -3px;
	zoom: 0.94;
}

.sidenav__closed_sklogo_playbookStandalone {
	background: var(--iconUrl);
	background-position: -652px -1105px;
	width: 32px;
	height: 32px;
	margin-left: 9px;
	padding-bottom: 7px;
	display: flex;
	margin-top: 4px;
}

.sidenav__sk_header {
	display: flex;
	border-right: 1px solid #565353;
	background-color: var(--sk-nav-color);
	margin-bottom: 1.2rem;
	height: 56px;
	align-items: center;
}

.sidenavClosed .menuBtn {
	margin-right: -6px;
}

.sidenav .sidenav__iconName {
	letter-spacing: 0.2px;
	font-weight: normal;
	font-family: DM Sans;
	font-size: 14px;
	color: #000;
	opacity: 0.6;
	font-weight: 400;
	line-height: 20px;
	font-style: normal;
	font-variant: normal;
	margin-top: 2px;
}

.sidenav i {
	background: var(--iconUrl);
	height: 100%;
	width: 29px;
	color: var(--secondary-color);
	opacity: 1;
	min-height: 30px;
	margin-top: 13px;
}

.headerLogo {
	padding-bottom: 0px;
	padding-left: 16px;
	display: flex;
	align-items: center;
	width: 90%;
}

.sidenavClosed i {
	background: var(--iconUrl);
	height: 30px;
	width: 21px;
	margin-top: 3px;
	color: var(--secondary-color);
	opacity: 1;
}

.king__icon {
	background: var(--iconUrl);
	height: 100%;
	width: 15px;
	color: var(--secondary-color);
	opacity: 1;
	min-height: 30px;
	margin-top: 24px;
	background-position: -404px -819px
}

.sidenav__upgrade {
	background: #e6d1fe 0% 0% no-repeat padding-box;
	border-radius: 20px;
	align-items: center;
	display: flex;
	height: 15px;
	width: 50px;
	justify-content: center;
	margin-top: 5px;
}

.sidenav__upgrade__text {
	color: #7327CC;
	font-size: 6.5px;
	padding: 0px 5px;
	align-items: center;
	display: flex;
	letter-spacing: 0.6px;
	font-weight: 700;
}

.sidenav__upgrade__wrap {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

