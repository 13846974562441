.platform_settings_wrapper {
    background-color: #fff;
	
}
.profile-edit {
    color: black;
}

.platform_settings_heading {
    font-size: 14px;
    font-weight: 500 !important;
    font-family: "Urbanist", sans-serif;
    color: #000000;
    padding: 12px 20px 5px 60px !important;
    /* border-bottom: 1px solid #efefef; */
    margin: 0;
    letter-spacing: 0.5px !important;
}


.first-section {
    padding: 15px 10px;
}

figure {
    margin: 0 0 1rem;
}

.upload-profile-section {
    position: relative;
    left: 20px;
    top:-10px;
    width: 80%;
    display:grid;
}
.show-profile {
    padding-top: 10px;
}
.ver_btn {
    position: relative;
    overflow: hidden;
    color: #000000;
    background-color: #fdfdfd;
    border: none;
    padding: 8px 15px 6px 15px;
    border-radius: 5px;
    font-size: 11px;
    display: inline-block;
    cursor: pointer;
    padding: 5px;
}
.email_ver_btn {
    position: relative;
    overflow: hidden;
    color: #000000;
    background-color: #fdfdfd;
    border: none;
    padding: 8px 15px 6px 15px;
    border-radius: 5px;
    font-size: 11px;
    display: inline-block;
    cursor: pointer;
    padding: 4px;
}

.profile-input {
    background-color: #80808005;
    width: 80%;
    height:auto !important;
    color: #000000 !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
    padding: 0.4375rem 0.75rem !important;
    font-size: 0.8125rem !important;
    line-height: 1.5 !important;
    border: 1px solid #e1e5eb !important;
}

.password-input {
    background-color: #80808005 !important;
    width: 50%;
    height:auto !important;
    color: #000000 !important;
    font-weight: 400 !important;
    border-radius: 5px !important;
    padding: 0.4375rem 0.75rem !important;
    font-size: 0.8125rem !important;
    line-height: 1.5 !important;
    border: 1px solid #e1e5eb !important;
}
.profile-edit .profile-form-wrapper label {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.form-group {
    margin-bottom: 1rem;
}

.change-password-link {
    cursor: pointer;
    font-size: 15px !important;
}

.cust-link {
    text-decoration: underline;
    letter-spacing: -0.05px;
    color: var(--secondary-color);
    font-size: 15px;
    font-weight: 500;
}

.aspectRatio{
    font-size:11px !important;
    font-weight: 500;
}
.profilePc{
    font-size:14px !important;
    font-weight: 500;
    letter-spacing: 0.5px !important;
}

.saveProfile_btn {
    position: relative;
    overflow: hidden;
    color: #000000;
    background-color: #fdfdfd;
    border: 1px solid #e5e5e8;
    padding: 8px 15px 6px 15px;
    border-radius: 5px;
    font-size: 11px;
    display: inline-block;
    cursor: pointer;
    padding: 6px 15px 8px 15px;
}


.readonlyActive {
   background-color: #EFEFEF !important;
}

.change-pass-title {
font-size: 15px !important;
/* padding-right: 15px; */
font-weight: 500 !important;
}

.form-line-active input:focus{
    border: 1px solid #ffb13d !important;
    box-shadow: 0px 3px 5px -3px #ddd !important;
    /* -moz-box-shadow:0px 13px 13px -13px #ffb13d !important;
    -webkit-box-shadow: 0px 13px 13px -13px #ffb13d !important; */
    }
    
    input:focus, textarea:focus, select:focus {
    outline-offset: 0px !important;
    outline: none !important;
    }

.edit-password-label-wrapper {
	margin-bottom: 3px;
	font-size: 14px; 
	color: black;
	font-weight: 500;
}

.edit-password-input-wrapper {
	width:50%;
	display: flex;
	align-items: center;
	padding: 0px !important;
}

.edit-password-input-input {
	display: flex;
	width: calc(100% - 20px);
}

.edit-password-input-input-field {
	flex-grow: 1;
	border: 0 !important;
	padding: 0.4375rem 0.75rem !important;
	border-radius: 5px;
}

.edit-password-show-hide-button {
	display: inline-block;
  background-repeat: no-repeat;
  background-image: var(--iconUrl);
	width: 20px;
	height: 16px;
	zoom: 0.8;
}

.makePasswordVisible {
	background-position: -687px -821px;
	cursor: pointer;
}

.makePasswordHidden {
	background-position: -687px -849px;
	cursor: pointer;
}

.form-line-active .edit-password-input-input input:focus {
	border: 0px !important; 
  box-shadow: none !important;
}

.sk_btn {
	position: relative;
	overflow: hidden;
	color: #000000;
	background-color: #fdfdfd;
	border: 1px solid #e5e5e8;
	padding: 8px 15px 6px 15px;
	border-radius: 5px;
	font-size: 11px;
	display: inline-block;
	cursor: pointer;
	padding: 6px 11px 8px 15px;
	margin-right: 3px;
}


.sk_btn i {
	zoom: 0.4;
	margin-right: 10px;
	position: relative;
	top: 3px;
	top: 6px;
}

.sk_icon_Icon-feather-save {
	width: 32px;
	height: 32px;
	background-position: -336px -192px;
	background-image: url(../assets/images/icons/icons.svg) !important;
}

.sk_icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-image: url(../assets/images/icons/icons.svg);
}

.sk_btn input[type="file"] {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.skmt10 {
	margin-top: 10px;
}

.sk_icon_Icon-ionic-ios-close-circle-outline {
	width: 32px;
	height: 32px;
	background-position: -240px -192px;
	background-image: url(../assets/images/icons/icons.svg) !important;
}