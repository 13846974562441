:root {
  --primary-color: #FFFFFF;
  --secondary-color: #7327CC;			/* clickable text */
  --sk-font-family: Urbanist;
  --sk-font-urbanist-family: Urbanist;
	--sk-font-sans: DM Sans;
  --sk-nav-font-size: 14px;
  --sk-select-color: #ECE1F9;		/* active filter bg, account-name-component-bg */
  --sk-heading-color: #2E2B78;			/*  additional-members-dropdown, tooltip-drop-down  */
  --sk-border-color: #E5E5E8;
  --sk-black-text-color: #000000;
  --sk-black-text-bg: #000000;
  --sk-table-font-size: 14px;
  --sk-nav-color: #000044;
  --sk-actions-create: #ff7e72;
  --sk-menu-bg-color: #F3ECFC;
  --sk-loyalty-user-churn-color-one: #AA8FCC;
  --sk-loyalty-user-churn-color-two: #EAD9FC;
  --sk-dropdown-hover-bg-color: #ece1f95e;
  --sk-dashboard-bg-img-1: #3A1366;
  --sk-dashboard-bg-img-2: #E2DDE8;					/* bg-body-color base, grid-table-scroller-hover */
  --sk-dashboard-bg-img--colour-3: #7d659a;		/* bg-body-color darker , grid-table-scroller */
  --sk-orange-color-notification: #FC7242;
  --radio-btn-unselect-bg-color: #11081f;
  --radio-btn-lable-color: #8C8893;
  --sk-share-and-konversations-label-color: #707070;
  --sk-datepicker-month-color: #2e2b78;
  --scrollbarBG: transparent;
  --thumbBG: #fdfdfd;
  --global-filter-Atributes-bg: #FAFAFA;		/* filters bg when inactive */
  --confirm-btn-color: #FD7E72;
	--action-btn-hover-color:#FF7E721A;
	--sk-modal-header-color : #ECE1FA;		/* modal header*/
	--sk-modal-backdrop-bg: rgba(0,0,68,0.46);    /* modal-backdrop-bg */
	--sk-loader-background: rgba(249, 250, 252, 0.5);
	--sk-template-card-bg: #EDE7F5;   /* template-card, inactive-tab, video-player-bg */	
	--sk-highchart-color: #B9A2D5;
	--sk-highchart-marker-color: #806BAA;
	--sk-no-data-text-color: #8A7AA7;
	--smart-pro-icon-color:#5f1fa6;
	--smart-konversations-label-wrap-color: #5f1fa6; 
	--setting-selected-menu: #6834C5;
	--setting-table-header-bg: #F6F0FF;
	--sk-highchart-color-var: #D6C7E8;
	--expand-tabele-border-color: #2304681A;

	--graph-shades-start-color: #170c53;
	--graph-shades-end-color: #F1ECF9;
	--playbook-overview-gray-color: #666666;

	/* 
		50 shades of purpleee -  #5f1fa6 (user-image placeholder bg) (text in account column for task-list),  #7327CC (clickable text), #7d659a (bg-body darker), #E2DDE8 (bg-body base), #ECE1F9 (filter active), #EDE7F5 (inactive tabs), #FAFAFA (filter inactive), #2304681A (data-grid-borders), #F3ECFC80 (grid-table-selected), 

		#2E2B78 (additional-members-dropdown) (tooltip-drop-down)
	*/ 


  /* smart konversations */
  --sk-placeholder-color: #9e9e9e;
  --smart-insights-icon-background-color: #7C629B; 
  --smart-insights-and-konversations-intelligence-heading-color: #000043;		/*  dark color text - in active toggle, gridTable - header, cell,   */
  --smart-konversations-label-color: #5f1fa6;			/*  user-image placeholder bg, text in account column for task-list,   */
  /* smart konversations */

  /* call intelligence */
  --call-intelligence-header-color: #F9F3FF;
  --call-intelligence-table-label-color: #070401;
  --call-intelligence-smart-assist-input-dark-color: #5f1fa6;
  --call-intelligence-light-color: #bca3d9;
  --call-intelligence-graph-dark-color: #aa8fcc; 
  --call-intelligence-graph-light-color: #d4c3e6;
  /* call intelligence */
  --pc-graph-sub-header-color: #59686B;
  --red-dot: #FF0000;
  --green-dot: #108110;
  --sk-date-filter-header-color: #511992;
  --sk-smart-modle-bg-color: #000044a3;
  --desable-color: #5f5f64;
  /* Sia */
  --sia-user-textbox-border-color: #ead9fc;
  --sia-chatbox-textbox-border-color: #DCCDEC;
  --sia-chatbox-textbox-background-color: #f6f1fc;
  --sia-header-color: #818181;
  --sia-input-box-color: #F6F6F7;
  /* Sia */
  --sk-smart-primary-btn-bg: #FF7E72;
  --sk-smart-datepicker-border-color: #5F1FA6;
  --sk-line-pc-graph-color: #9F8FB3;

  /*Eaxanpsion Analyser*/
  --sk-sub-tab-border-color: #69697629;
	--sk-sub-tab-text-color: #696976ab;
  --sk-feature-tab-text-color: #070707;
  --sk-expansion-analyser-container-border-color: #69697605;
  --sk-expansion-analyser-table-q1-color: #F7F2FD;

  /* placeholder colors */
  --sk-smart-placeholder-color: #564f4f;
  --sk-smart-react-select-focus-color : #EFE8F6;

	/* smart comments */
	--sk-comment-time-color: #999999;
	/* side pop up  */
	--side-pop-up-footer-color:#F2F2F2; 
	/*graph popper*/
	--sk-graph-popper:#B0B7C324;
	--sk-graph-popper-boder-color: #CFCFCF;
	--desable-color-input :#f2f2f2;

	--tabele-border-color:#2304681A;					/* grid-table-border*/
	--template-toggle-bg-color:#7C6399;
	--sk-placeholder-color:#757575;
	--playbook_progress_bar_bg:#e2dfdf;
	--active-row-bg:#F3ECFC80;				/*  grid-table-selected */
	--progress_bar_active_bg:#4FD17D;
	--progress_bar_delayed_bg:#F57967;
	--account_360_graph_light_color: #D4C3E6;
	--nps_yellow_color:#FFC86E;
	--health_score_red_color:#F24D34;
	--health_score_footer_date_color: #574F62;
	--health_score_undefined_color:#d8dbde;
	--signup-input-bg-color:#FBFBFB;

	/* Churn Dashboard colors */
	--churn_rate_color: #ED4A34;
	--non_churn_rate_color: #C5C6D2;

	--getting-started-donut-chart-light-color: #EBE3F6;
	--digital-playbooks_sign-in-color: #037bfc;
	--digital-playbooks-loader-backdrop-color: #edf5fc;

	height: 100vh;

	--logo-url: url('./assets/phoenix/logo.svg');
	--logo-url-copy: url('./assets/phoenix/logo.svg');

	--iconUrl:url('./assets/phoenix/new_icons.svg'); 
	--iconUrl-copy: url('./assets/phoenix/new_icons.svg');
}

body {
  margin: 0;
  padding:0;

}

/* ::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: var(--sk-dashboard-bg-img-2);
	border-radius: 6px;
	border: 4px solid var();
}

::-webkit-scrollbar-thumb:hover {
	background: var(--sk-dashboard-bg-img-2);
}

::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 6px;
}

iframe {
	overflow: hidden;
}

/* Enable scrolling within the iframe */
iframe.content-scrollable {
	overflow: auto;
} */



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

/* ::placeholder {
	color: var(--sk-placeholder-color);
} */

.playbook {
  top: 192px;
  height: 972px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 20px;
  margin-top: 0px;
}

.sk_smartFilterTab_wrap {
  margin: 1.5rem 1.2rem 0rem 2rem;
}

.custom-map-modal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.segment-nav-tab-item .nav-item {
  background-color: transparent !important;
  border-bottom: none;
}

.segment-nav-tab-item .nav-item .nav-link .active {
  background-color: transparent !important;
}

.segment-nav-tab-item .nav-tabs {
  border-bottom: none;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-map-modal .modal-content {
  height: -webkit-fill-available;
  border-radius: 0;
}

.error__border__bottom{
	border-bottom: 1px solid red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ck-powered-by-balloon {
  visibility: hidden !important;
}
.fr-second-toolbar {
	visibility: hidden !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.unsupported-screen-size{
	height: 100vh;
}

.unsupported-screen-size .betterScreenViewWidget {
  display: none;
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
  .unsupported-screen-size .betterScreenViewWidget .betterScreenView {
    flex-direction: row !important;
  }

  .unsupported-screen-size .betterScreenViewWidget .betterScreenView img {
    margin: 0 20px 0 0;
  }

  .unsupported-screen-size .betterScreenViewWidget .betterScreenView span.ErrorScreenTitle {
    margin: 0;
  }
}

@media only screen and (max-device-height: 480px) and (orientation: landscape) {
  .unsupported-screen-size .betterScreenViewWidget .betterScreenView {
    flex-direction: row !important;
  }

  .unsupported-screen-size .betterScreenViewWidget .betterScreenView img {
    margin: 0 20px 0 0;
  }

  .unsupported-screen-size .betterScreenViewWidget .betterScreenView span.ErrorScreenTitle {
    margin: 0;
  }
}

.unsupported-screen-size .new-error-screen {
  text-align: center;
  margin: 0 auto;
}

.unsupported-screen-size .new-error-screen h2 {
  margin: 0 25%;
  font-size: 32px;
  font-weight: 400;
  color: #0A234A !important;
  font-family: "Roboto Slab" !important;
  padding: 35px 0;
}

.unsupported-screen-size .new-error-screen .paragraph {
  margin-top: 0 !important;
  margin: 0 17% !important;
  margin-bottom: 0px !important;
  font-weight: 300 !important;
  font-size: 22px !important;
  color: #002257 !important;
  font-family: "Roboto Slab" !important;
}

.unsupported-screen-size .mail-link {
  font-size: 22px !important;
  color: #002257 !important;
  font-weight: 500 !important;
  text-decoration: underline;
}



@media screen and (max-width: 600px) {
  .unsupported-screen-size .new-error-screen h2 {
    margin: 0px 25%;
    font-size: 15px;
    font-weight: 400;
    color: #0A234A;
    font-family: "Roboto Slab" !important;
    padding: 35px 0;
  }



  .unsupported-screen-size .main-no-logo {
    width: 40%;
  }

  .unsupported-screen-size .middle-img {
    width: 65%;
  }

  .unsupported-screen-size .new-error-screen .paragraph {
    font-size: 13px;
  }

  .unsupported-screen-size .mail-link {
    font-size: 13px !important;
  }
}


.unsupported-screen-size .main-title {
  margin-bottom: 2px !important;
  font-size: 18px !important;
  color: #000 !important;
  font-weight: 600 !important;
}



@media only screen and (max-width: 1279px) {

  .unsupported-screen-size body {
    min-width: 100%;
  }

  .unsupported-screen-size .betterScreenViewWidget {
    display: block;
  }

  .unsupported-screen-size .middle-img {
    width: 22%;
  }

  .unsupported-screen-size .betterScreenViewWidget .betterScreenView {
    height: 100vh;
    overflow: hidden;
    transform: scale(0.8);
    padding: 0px !important;
  }

}

.icon_reporting i {
  background-image: url("./assets/images/icons/icons.svg") !important;
  display: inline-block !important;
  width: 40px !important;
  /* height: 40px !important; */
  background-position: -235px 0px !important;
  text-indent: 999px !important;
  overflow: hidden !important;
  transform: scale(0.65) !important;
  margin-top: 12px !important;
  height: 31px !important;
}

.icon_reporting {
  /* margin-top: 12px !important; */
  margin-top: -10px !important;
  padding-bottom: 0px !important;
}

.icon_analytics .icon_reporting:hover {
  background-position: -190px 0px !important;
  filter: brightness(0) invert(1);

}
.icon_analytics .icon_reporting.active i {
  background-position: -190px 0px !important;
}
.redirecting_cotainer{
	position: fixed;
	height: 100%;
	width: 100%;
	text-align: center;
}
.redirecting_cotainer--text{
	margin-top: 15%; 
}