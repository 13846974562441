/* emoji style starts======================= */

.smart__emoji__container {
	height: fit-content;
}

.smart__emoji__picker__wrap {
	display: flex;
	height: fit-content;
	position: relative;
	width: 100%;
	/* z-index: 99999; */
 
}

.emoji__dropDown{
	/* margin-left: -350px; */
	z-index: 9999;
	margin-left: -23%;
}

.smart__emoji__picker__wrap-inside {
	/* margin-top: 43px; */
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 13px 13px;
	height: -moz-fit-content;
	height: fit-content;
	position: absolute;
	margin-left: auto;
	width: fit-content;
	/* margin-left: 123px; */
	z-index: 99999999;

}

.smart__emoji__picker__wrap aside.EmojiPickerReact.epr-main {
	/* border: 0px; */
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 13px 13px;
}

.smart__emoji__picker__wrap .EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label {
	font-family: var(--sk-font-urbanist-family);
	color: var(--sk-heading-color);
	font-weight: 500;
}

.smart__emoji__picker__wrap .EmojiPickerReact * {
	font-family: var(--sk-font-urbanist-family);
}

.smart__emoji__picker__wrap .EmojiPickerReact .epr-preview .epr-preview-emoji-label {
	font-family: var(--sk-font-urbanist-family);
	color: var(--sk-heading-color);
	font-weight: 500;
}



/* emoji style ends======================= */

/* reaction style starts======================= */
.smart__comments__list__reaction__container {
	color: var(--sk-comment-time-color);
	font-family: var(--sk-font-urbanist-family), SemiBold;
	font-size: 0.723rem;
	display: flex;
	align-items: center;
}

.smart__comments__list__reaction-reaction {
	display: flex;
	align-items: center;
}

.smart__comments__list_reaction-dot {
	font-weight: 900;
	display: flex;
	align-items: center;
	justify-content: center;
}

.smart__comments__list__reaction-button-unselected-text {
	color: #7c7c7c;
	font-weight: 700;
	font-size: 0.813rem;
}

.smart__comments__list__reaction-button-unselected-text:hover{
	background-color: #ebedeb;
	border-radius: 3px;

}

.smart__comments__list_reaction-line {
	margin-right: 0.25rem;
	font-size: 1rem;
}

.smart__comments__list__reaction-button {
	border: none;
	background: white;
}

.smart__comments__list__reaction-button-text {
	font-size: 0.813rem;
	font-weight: 700;
	cursor: pointer;
}

.smart__comments__list__reaction-count {
	display: flex;
    align-items: center;
    justify-content: center;
	margin-left: 0.25rem;
	margin-right: 0.4rem;
	/* padding-top: 0.25rem; */
}

.smart__comments__list__reaction__container .avatar img {
	border-radius: 50%;
	position: relative;
	margin-left: -0.4rem;
	z-index: 1;
	height: 20px;
	width: 20px;
}

.smart__comments__list__reaction-image {
	display: flex;
}

.smart__comments__list__reaction__container .avatars {
	direction: rtl;
	/* This is to get the stack with left on top */
	text-align: left;
	/* Now need to explitly align left */
	padding-left: 25px;
	/* Same value as the negative margin */
}

.smart__comments__list__reaction__container-popup{
	z-index: 9999999 !important;
	background-color: #FFFFFF;
	color: #000;
	padding: 5px;
	margin-left: 13.5rem;
	border: 1px solid var(--sk-smart-datepicker-border-color);
	border-radius: 0px 0px 13px 13px;
	margin-top: 0.938rem;
	width: 18%;
	display: flex;
	margin-top: 10px;
}

.smart__like__reactions {
	display: inline-flex;
	width: 100%;
}

.smart__reaction {
	margin: 5px 10px 5px 0px;
}

.smart__reaction:hover {
	cursor: pointer;
}

.smart__reaction img {
	height: 30px;
	width: 30px;
}

/* reaction style ends======================= */

.EmojiPickerReact .epr-header .epr-header-overlay{
		padding: 5px;
}
aside.EmojiPickerReact.epr-main:has(input:placeholder-shown) .epr-category-nav{
	padding: 5px;
	height: 35px;
}

.EmojiPickerReact button.epr-btn{
	height: 27px;
	width: 27px;
}

.EmojiPickerReact .epr-preview{
	height: 40px;
}

img.__EmojiPicker__.epr-emoji-img{
scale: 0.8;
}
.EmojiPickerReact li.epr-emoji-category>.epr-emoji-category-label{
	height: auto;
}

.EmojiPickerReact .epr-search-container input.epr-search{
	height: 35px;
}