content>.main-content-container.container-fluid {
    min-height: calc(100vh - 7.5rem);
}

.px-5 {
    padding: 3rem !important;
}

.container-fluid {
    width: 100%;
    height: 100vh;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.smart-knvrsat-sectiom {
    padding: 50px 50px;
    background-color: #fff;
}
.mainView.container-fluid{
	overflow: hidden;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.smart-knvrsat-topleft .smart-knvrsat-head h2 {
    color: #0B132A;
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 0;
}

.smart-knvrsat-topleft .smart-knvrsat-head p {
    color: #4F5665;
    font-size: 18px !important;
    font-weight: 500;
}

body p {
    margin-bottom: 0px !important;
}

.smart-knvrsat-topleft .smart-knvrsat-desc {
    margin-top: 30px;
    margin-bottom: 30px;
}

.smart-knvrsat-topleft .smart-knvrsat-desc h4 {
    color: #4F5665;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0;
    margin-bottom: 5px;
}

.smart-knvrsat-topleft .smart-knvrsat-desc ul {
    padding-left: 20px;
}

.smart-knvrsat-topleft .smart-knvrsat-desc ul li {
    color: #4F5665;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
}

.smart-knvrsat-left-btm {
    padding: 20px 0px;
    display: flex;
}

.smart-knvrsat-left-btm a.anchor_btn {
    padding: 15px 20px;
    background-color: #F48938;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 1px 24px 78px 8px #f4893857;
}

.smart-knvrsat-topright.text-center {
    padding-top: 15px;
}

.smart-knvrsat-topright img {
    width: 500px;
}

.smart-knvrsat-middle-left img {
    width: 600px;
}

.smart-knvrsat-middle-right {
    margin-left: 20px;
}

.smart-knvrsat-middle-right .smart-knvrsat-head h2 {
    color: #0B132A;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;
    letter-spacing: 0;
}


.smart-knvrsat-middle-right .smart-knvrsat-head p {
    color: #4F5665;
    font-size: 16px;
    font-weight: 400;
}

.smart-knvrsat-middle-right .smart-knvrsat-desc {
    margin-top: 25px;
    margin-bottom: 30px;
}

.smart-knvrsat-middle-right .smart-knvrsat-desc ul {
    padding-left: 0px;
}

.smart-knvrsat-middle-right .smart-knvrsat-desc ul li {
    color: #4F5665;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    list-style: none;
    position: relative;
    margin-bottom: 15px;
    display: inline-flex;
}

.smart-knvrsat-bottom-sec {
    padding: 30px;
    box-shadow: 0px 0px 24px -1px #cccccc42;
    margin: 0px 15px;
}

.SmartKonCheckImage {
    height: 18px;
    width: 18px;
    margin: 6px;
}

.smart-knvrsat-bottom-left-text {
    width: 450px;
    padding: 0px 0px 0px 35px;
}

.smart-knvrsat-bottom-left-text h2 {
    color: #0B132A;
    font-size: 34px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0;
}

.smart-knvrsat-bottom-btn {
    padding-right: 100px;
}

.smart-knvrsat-bottom-btn a.anchor_btn {
    padding: 15px 50px;
    background-color: #F48938;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 1px 24px 78px 8px #f4893857;
}

.smart-knvrsat-middle-right .smart-knvrsat-desc ul li:before {
    content: "";
    left: -22px;
    top: 10px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    z-index: 9;
}

::before {
    box-sizing: border-box;
}

/* Phoenix changes start */
.smart__konversation__landing__page {
    overflow-y: auto;
    scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .smart__konversation__landing__page::-webkit-scrollbar {
    display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .smart__konversation__landing__page {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

.smart__konversation__landing__page::-webkit-scrollbar {
    width: 4px;
    background: transparent;
}

.smart__konversation__landing__page::-webkit-scrollbar-thumb {
    background: var(--sk-dashboard-bg-img-2);
    border-radius: 6px;
    border: 4px solid var();
}

.smart__konversation__landing__page::-webkit-scrollbar-thumb:hover {
    background: var(--sk-dashboard-bg-img-2);
}

.smart__konversation__landing__page::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 6px;
}