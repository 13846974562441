
/* Styles imported from headerNavbar.css */
.navbar-light .navbar-nav__header-icons{
	white-space: nowrap;
	text-overflow: ellipsis;
}

.navbar-light .navbar-nav__nav_items{
	padding: 20.7px 19.7px 20px 20px;
}

.navbar-light .navbar-nav__nav_items:hover {
	cursor: pointer;
}
.navbar-light .navbar-nav__nav_items:last-child{
	padding-right: 32px;
	padding-left: 60.46px;
}
.navbar {
margin-bottom: 0px;
}
.main-content .main_header .navbar {
background-color: var(--sk-nav-color);
height: 56px;
width: calc(100% - 42px);
position: fixed;
}
.openNav.main-content .main_header .navbar{
width: calc(100% - 225px);
}
.navbar__nav__item__notification__number__container {
	z-index: 999999;
	color: white;
	background: var( --sk-orange-color-notification);
	border: 1px solid var(--sk-border-color);
	border-radius: 50%;
	display: block;
	width: 15px;
	height: 15px;
	font-size: 8px;
	padding: 1px;
	text-align: center;
	margin-top: -12px;
	margin-left: 15px;
	position: absolute;
}
.navbar__nav__item__notification__number__container.Notification{
	margin-left: 14px; 
}
.navbar__nav__item__notification__number__container-icon {
	position: absolute;
	width: 20px;
	height: 20px;
	background: var(--iconUrl);
	background-position: -186px -572px;
}

.skmart__seach__items__icon:hover{
background-color: transparent !important;
}

.main-navbar .navbar .activeLink{
background-color: #E6DAF280 !important;
border-radius: 4px;
}

.main-navbar .navbar .navbar-nav__header-icons i:hover{
background-color: #F3ECFC40;
border-radius: 4px;
}

.main-navbar .navbar .navbar-nav__header-icons i {
	line-height: 2.5rem;
	background: var(--iconUrl) 0% 0% no-repeat padding-box;
	height: 35px;
	width: 35px;
	min-width: 33px;
	position: absolute;
	margin-top: -10px;
	cursor: pointer;
}

.main-navbar .navbar .navbar-nav__header-icons i.search__header__icon {
	margin-top: -6px !important;
	width: 30px !important;
	height: 30px !important;
	margin-left: 9px;
}


.main-navbar .MuiTooltip-popperInteractive{
	top:20px !important;
}

.navbar-light .nav-header .navMenu{
	font-weight: 300;
	font-size: 17px;
}

.nav__profile__dropdownIcon{
margin-left: 2px;
margin-top: 1px;
background: var(--iconUrl);
background-position: -312px -905px !important;
height: 20px;
width: 20px;
display: inline-flex;
}

.sk-smart-badge{
background-color: var(--sk-actions-create);
height: 12px;
width: 12px;
	border-radius: 50%;
	position: absolute;
	margin-left: 20px;
	margin-top: -9px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	/* font-size: 11px; */
	padding: 10px;
	color: var(--primary-color);
}

.main-content .main_header {
background-color: #F7F7F7;
height: 56px;
overflow: hidden;
/* margin-bottom: 2rem; */
}


/* Sidenavbar Icons start */
.main-sidebar .indicator{
	background-position: -6px -857px;
}
.main-sidebar .portfolio{
	background-position: -47px -859px;
}
.main-sidebar .workspace{
	background-position: -86px -857px;
}
.main-sidebar .product_analytics{
	background-position: -124px -858px;
}
.main-sidebar .smart_insight{
	background-position: -163px -858px;
}
.main-sidebar .kommunity{
	background-position: -203px -856px;
}
.main-sidebar .smart_operation{
	background-position: -243px -858px;
}
.main-sidebar .konversation{
	background-position: -283px -856px;
}
.main-sidebar .smart_assist{
	background-position: -322px -857px;
}
.academy_sidebar_icon{
	background-position: -220px -48px !important;
	scale: 1.3;
}
.main-sidebar .tools{
	background-position: -363px -857px;
}
.sidenav__icon__tooptip__tools .MuiTooltip-tooltip{
	margin-top: -150px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__smart_assist .MuiTooltip-tooltip{
	margin-top: -140px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__konversation .MuiTooltip-tooltip{
	margin-top: -120px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__smart_operation .MuiTooltip-tooltip{
	margin-top: -100px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__kommunity .MuiTooltip-tooltip{
	margin-top: -100px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__smart_insight .MuiTooltip-tooltip{
	margin-top: -80px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__product_analytics .MuiTooltip-tooltip{
	margin-top: -70px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__workspace .MuiTooltip-tooltip{
	margin-top: -50px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__portfolio .MuiTooltip-tooltip{
	margin-top: -30px !important;
	white-space: nowrap
}
.sidenav__icon__tooptip__indicator .MuiTooltip-tooltip{
	margin-top: -30px !important;
	white-space: nowrap
}
/* Sidenavbar Icons end */ 

/* Header Navbar icons start */
.main-navbar .navbar .search_header_icon{
	background-position: -82px -566px !important;
}
.main-navbar .kommunity_header_icon{
	background-position: -135px -906px !important;
}
.main-navbar .notification_header_icon{
	background-position: -95px -904.5px !important;
}

.main-navbar .search__header__icon{
background-position: -53px -908px !important;
zoom: 0.9;
}
.main-navbar .support_header_icon{
	background-position: -521px -905px !important;
}
.main-navbar .academy_header_icon{
	background-position: -472px -905px !important;
}
.main-navbar .settings_header_icon{
	background-position: -264px -905px !important;
}
/* Header Navbar icons end */

.sk_smart_Profile{
margin-left: -10px !important;
margin-top: -20px !important;
/* right: 0px !important; */
}



.sidenav {
	width: 240px;
	height: 100vh;
	background-color:var(--primary-color);
	opacity: 1;
	box-shadow: 8px 0px 8px #00000005;
}
.sidenavClosed {
	composes: sidenav;
	width: 58px;
}

.sidenavClosed .sideitem {
	width: 44px;
	padding: 0px 0px 0px 9px;
} 

.sideitem {
	top: 252px;
	left: 33px;
	overflow: hidden;
	right: 33px;
	width: 220px;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0px 0px 0px 9px;
	cursor: pointer;
	color: black;
	text-decoration: none;
	overflow: hidden;
	opacity: 1;
	margin-left: 7px;
}
.linkText {
	padding-left: 16px;
}

.sidenav--activeTab {
	top: 252px;
	left: 33px;
	background: var(--active-row-bg);
	border-radius: 20px;
	opacity: 1;
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
}

.sidenavClosed .sidenav--activeTab{
background-color: var(--active-row-bg);
border-radius: 20px;
width: 44px;
}

.sidenavClosed .sidenav--activeTab i{
	filter: brightness(1) !important; 
}

.sidenav--activeTab .sidenav__iconName{
color: var(--secondary-color) !important;
opacity: 1 !important;
}

.sideitem:hover {
	top: 252px;
	left: 33px;
	background: var(--active-row-bg);
	border-radius: 20px;
	backdrop-filter: blur(16px);
	-webkit-backdrop-filter: blur(16px);
}
.menuBtn {
	align-self: center;
	align-self: flex-start;
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding-left: 9px;
	margin-right: -20px;
	margin-top: 7px;
	position: relative;
}
.menuBtn_img{
	background: var(--iconUrl);
	background-position: -8px -911px;
	min-width: 30px;
	min-height: 30px;
	position: absolute;
	margin-left: 3px;
	margin-top: 8px;
}
.menuBtn_img_rotate{
	background: var(--iconUrl);
	background-position: -8px -911px;
	min-width: 30px;
	min-height: 30px;
	position: absolute;
	margin-left: -7px;
	margin-top: 2px;
	rotate: 180deg;
}

.logo {
	width: 13%;
}
.sidenav__sideItemWrap{
	overflow: hidden;
	padding-top: 0px;
	margin-top: -3px;
}

.sidenavClosed .logo {
	margin-right: 19px;
	color: var(--secondary-color);
	transition: width 0.3s ease-in-out;
	filter: brightness(0) invert(1);
}

.sidenav__sklogo {
	padding-left: 0px;
	height: 19px;
	width: 122px;
	margin-top: -6px;
}

.sidenav__closed_sklogo{
background: var(--iconUrl);
background-position: -433.5px -906px;
 width: 30px;
 height: 33px;
 margin-left: 9px;
 padding-bottom: 7px;
 display: flex;
 margin-top: -3px;
}
.sidenav__sk_header {
	display: flex;
	border-right: 1px solid #565353;
	background-color: var(--sk-nav-color);
	margin-bottom: 1.2rem;
	height: 56px;
	align-items: center;
}

.sidenavClosed .menuBtn{
	margin-right: -6px;
}

.sidenav .sidenav__iconName{
letter-spacing: 0.2px;
font-weight: normal;
font-family: DM Sans;
font-size: 14px;
color: #000;
opacity:0.6;
font-weight: 400;
line-height: 20px;
font-style: normal;
font-variant: normal;
margin-top: 2px;
}

.sidenav i{
	background: var(--iconUrl);
	height: 100%;
	width: 29px;
	color: var(--secondary-color);
	opacity: 1;
	min-height: 30px;
	margin-top: 13px;
}

.headerLogo{
	padding-bottom: 0px;
	padding-left: 16px;
	display: flex;
	align-items: center;
	width: 90%;
}

.sidenavClosed i{
	background: var(--iconUrl);
	height: 30px;
	width: 21px;
	margin-top: 3px;
	color: var(--secondary-color);
	opacity: 1;
}

.king__icon{
background: var(--iconUrl);
height: 100%;
width: 15px;
color: var(--secondary-color);
opacity: 1;
min-height: 30px;
margin-top: 24px;
background-position: -404px -819px
}

.sidenav__upgrade{
background: #e6d1fe 0% 0% no-repeat padding-box;
border-radius: 20px;
align-items: center;
display: flex;
height: 15px;
width: 50px;
justify-content: center;
margin-top: 5px;
}

.sidenav__upgrade__text{
color: var(--secondary-color);
font-size: 6.5px;
padding: 0px 5px;
align-items: center;
display: flex;
letter-spacing: 0.6px;
font-weight: 700;
}

.sidenav__upgrade__wrap{
display: flex;
width: 100%;
justify-content: space-between;
}

.MuiSnackbarContent-root {
	background-color: #fff !important;
	color : var(--sk-smart-primary-btn-bg) !important;
	border-left: 3px solid var(--sk-smart-primary-btn-bg);
	font-weight: 700 !important;
	min-width: 300px !important;
	/* max-width: 300px !important; */
	left: 6px !important;
	position: fixed;
}

.smart__snackbar__cross{
	background: var(--iconUrl) no-repeat -345px -442px;
	width: 24px;
	height: 24px;
	z-index: 999999999;
	cursor: pointer;
	rotate: 45deg;
}

.sidebar__notification {
	/* border: 1px solid var(--sk-comment-time-color); */
	border-radius: 8px;
	padding: 10px;
	margin: 10px;
	box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
	0px 1px 6px 1px rgba(0,0,0,0.24);
}

.sidebar__notification-header {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.sidebar__notification-text {
	font-size: 12px;
	color: #000;
}

.sidebar__notification-footer {
	margin-top: 10px;
}

.sidebar__notification-footer .smart__ui__btns .justify-content-end {
	justify-content: center !important;
}

.sidebar__notification-footer .smart__ui__btns .skPrimaryBtn {
	background-color: var(--template-toggle-bg-color);
	min-width: 4rem !important;
	border: 1px solid var(--template-toggle-bg-color) !important;
}

.sidebar__notification-text.second__paragraph {
	margin-top: 10px;
}


.custom__notification__with__undo__button__wrap{
  width: 100%;
}

.sidebar__snack .MuiPaper-root{
  background-color: #FFF0F0 !important;
}

.sidebar__snack .MuiSnackbarContent-action{
  padding-left: 0px !important;
  margin-left: 0px !important;
	width: 100%;
}

.custom__notification__with__undo__button__wrap .notification_row-cross_icon {
  padding-right: 16px;
}

.sidebar__snack .MuiSnackbarContent-root {
	min-width: 350px !important;
}

.sidebar_snackbar .MuiSnackbarContent-message {
	width: 87%;
}

.sidebar_snackbar .MuiSnackbarContent-action {
	width: 13%;
}